import { EPresetType, EPresetTypeLabel } from 'common/const/preset.enum';
import { EPropertyType } from 'common/const/property.enum';

export const propertyFormTypeOptions = [
  {
    label: 'ТЕКСТ',
    value: EPropertyType.List,
  },
  {
    label: 'ЧИСЛО',
    value: EPropertyType.Number,
  },
  {
    label: 'BOOLEAN',
    value: EPropertyType.Label,
  },
  {
    label: 'КАТЕГОРИЯ',
    value: 'category',
    disabled: true,
  },
];

export const propertyFormPresetTypeOptions = [
  {
    label: EPresetTypeLabel.NotApplicable,
    value: EPresetType.NotApplicable,
  },
  {
    label: EPresetTypeLabel.SingleValue,
    value: EPresetType.SingleValue,
  },
  {
    label: EPresetTypeLabel.Range,
    value: EPresetType.Range,
  },
  {
    label: EPresetTypeLabel.MultipleValue,
    value: EPresetType.MultipleValue,
  },
  {
    label: EPresetTypeLabel.Checkbox,
    value: EPresetType.Checkbox,
  },
];
