export enum EPlaceholder {
  SearchGoodsByNameOrVendorCode = 'Искать по названию или артикулу товара',
  AddVendorCode = 'Добавить артикул',
  SelectAddress = 'Выберите адрес',
  SelectLegal = 'Выберите юр.лицо',
  EnterFirstName = 'Введите имя',
  EnterLastName = 'Введите фамилию',
  EnterEmail = 'Введите email',
  EnterPhone = 'Введите номер телефона',
  EnterOldPassword = 'Введите старый пароль',
  EnterNewPassword = 'Введите новый пароль',
  RepeatNewPassword = 'Введите новый пароль ещё раз',
  EnterWorkspaceName = 'Введите название карты',
  SelectSubdivision = 'Выберите подразделение',
  SelectEmployee = 'Выберите сотрудника',
}
