/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { DEFAULT_PAGINATION_PAGE } from 'common/config';
import { EEditSupplyCardMode } from 'common/const/supply.enum';
import { ISupply } from 'entities/Supply/Supply.models';

export interface ISupplyContext {
  page: number;
  setPage: (value: number) => void;
  editSupplyCardMode: EEditSupplyCardMode;
  setEditSupplyCardMode: (value: any) => void;
  openSupplyCard: boolean;
  setOpenSupplyCard: (value: boolean) => void;
  fetchLoading: boolean;
  setFetchLoading: (value: boolean) => void;
  openEditSupplyCard: boolean;
  setOpenEditSupplyCard: (value: boolean) => void;
  openSupplyCorrectionRequestDrawer: boolean;
  setOpenSupplyCorrectionRequestDrawer: (value: boolean) => void;
  selectedSupplyList: ISupply[];
  setSelectedSupplyList: (value: ISupply[]) => void;
}

export const SupplyContext = createContext<ISupplyContext>({
  page: DEFAULT_PAGINATION_PAGE,
  setPage: () => {},
  editSupplyCardMode: EEditSupplyCardMode.Full,
  setEditSupplyCardMode: () => {},
  openSupplyCard: false,
  setOpenSupplyCard: () => {},
  fetchLoading: false,
  setFetchLoading: () => {},
  openEditSupplyCard: false,
  setOpenEditSupplyCard: () => {},
  openSupplyCorrectionRequestDrawer: false,
  setOpenSupplyCorrectionRequestDrawer: () => {},
  selectedSupplyList: [],
  setSelectedSupplyList: () => {},
});

export const useSupplyContext = () => useContext(SupplyContext);

export interface IComponentProps {
  children: React.ReactNode;
}

export const SupplyProvider: FC<IComponentProps> = ({ children }) => {
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [editSupplyCardMode, setEditSupplyCardMode] = useState<EEditSupplyCardMode>(EEditSupplyCardMode.Full);
  const [openSupplyCard, setOpenSupplyCard] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [openEditSupplyCard, setOpenEditSupplyCard] = useState<boolean>(false);
  const [openSupplyCorrectionRequestDrawer, setOpenSupplyCorrectionRequestDrawer] = useState<boolean>(false);
  const [selectedSupplyList, setSelectedSupplyList] = useState<ISupply[]>([]);

  return (
    <SupplyContext.Provider
      value={{
        page,
        setPage,
        editSupplyCardMode,
        setEditSupplyCardMode,
        openSupplyCard,
        setOpenSupplyCard,
        fetchLoading,
        setFetchLoading,
        openEditSupplyCard,
        setOpenEditSupplyCard,
        openSupplyCorrectionRequestDrawer,
        setOpenSupplyCorrectionRequestDrawer,
        selectedSupplyList,
        setSelectedSupplyList,
      }}
    >
      {children}
    </SupplyContext.Provider>
  );
};
