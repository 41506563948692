import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import { goodsTransport } from 'entities/Goods/Goods.transport';
import {
  IGoodsListState,
  IGoodsListPayload,
  IGoods,
  IGoodsState,
  IGoodsCreatePayload,
  IGoodsParams,
  IGoodsUpdatePayload,
} from 'entities/Goods/Goods.models';

export const goodsListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as IGoodsListState,
  reducers: {
    setGoodsList: (state, payload: IGoods[]) => ({ ...state, data: payload }),
    setGoodsListCount: (state, payload: number) => ({ ...state, count: payload }),
    setGoodsListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setGoodsListPart: (state, payload: IGoods[]) => ({ ...state, data: [...state.data, ...payload] }),
    updateGoodsList: (state, payload: IGoods) => {
      return {
        ...state,
        data: state.data.map((goods) => (goods.id === payload.id ? payload : goods)),
      };
    },
    updateGoodsListGoodsInBasket: (state, payload: { id: number; goodInBasket: boolean }) => ({
      ...state,
      data: state.data.map((goods) => {
        if (goods.id === payload.id) {
          return { ...goods, goodInBasket: payload.goodInBasket };
        }

        return goods;
      }),
    }),
    clearGoodsListState: (state) => ({ ...state, data: [], count: 0 }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getGoodsList(payload: IGoodsListPayload) {
      dispatch.goodsListState.setGoodsListLoading(true);

      try {
        const response = await goodsTransport.getGoodsList(payload);
        dispatch.goodsListState.setGoodsList(response.data);
        dispatch.goodsListState.setGoodsListCount(response.count);
        dispatch.goodsListState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.goodsListState.setGoodsListLoading(false);
      }
    },
    async getGoodsListPart(payload: IGoodsListPayload) {
      dispatch.goodsListState.setGoodsListLoading(true);

      try {
        const response = await goodsTransport.getGoodsList(payload);
        dispatch.goodsListState.setGoodsListPart(response.data);
        dispatch.goodsListState.setGoodsListCount(response.count);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.goodsListState.setGoodsListLoading(false);
      }
    },
    async searchGoodsByName(payload: IGoodsListPayload) {
      try {
        const response = await goodsTransport.getGoodsList(payload);
        return response.data;
      } catch (error) {
        axiosErrorHandler(error);
        return [];
      }
    },
  }),
});

export const goodsState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IGoodsState,
  reducers: {
    setGoods: (state, payload: IGoods | null) => ({ ...state, data: payload }),
    setGoodsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getGoods(params: IGoodsParams) {
      dispatch.goodsState.setGoodsLoading(true);

      try {
        const response = await goodsTransport.getGoods(params);
        dispatch.goodsState.setGoods(response);
        dispatch.goodsState.onSuccess(params);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.goodsState.setGoodsLoading(false);
      }
    },
    async createGoods(payload: IGoodsCreatePayload) {
      dispatch.goodsState.setGoodsLoading(true);

      try {
        const response = await goodsTransport.createGoods(payload);
        dispatch.goodsState.setGoods(response);
        dispatch.goodsState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.goodsState.setGoodsLoading(false);
      }
    },
    async updateGoods(payload: IGoodsUpdatePayload) {
      dispatch.goodsState.setGoodsLoading(true);

      try {
        const response = await goodsTransport.updateGoods(payload);
        dispatch.goodsListState.updateGoodsList(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.goodsState.setGoodsLoading(false);
      }
    },
  }),
});
