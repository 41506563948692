import React, { FC, useState } from 'react';
import { Button, Drawer, Spin } from 'antd';
import { connect } from 'react-redux';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { NameLabel } from 'common/components/NameLabel';
import { NeedStatus } from 'common/components/NeedStatus';
import { SupplyStatus } from 'common/components/SupplyStatus';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { Image } from 'common/components/Image';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { EEditSupplyCardMode, ESupplyStatus } from 'common/const/supply.enum';
import { EUserRole } from 'common/const/user.enum';
import { ENeedCardMenuItem, ENeedStatus } from 'common/const/need.enum';
import { useGoodsContext } from 'common/hooks/useGoodsContext';
import { countFormatter, dateFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { DRAWER_Z_INDEX_1, DRAWER_Z_INDEX_2 } from 'common/config';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';
import { RootDispatch, RootState } from 'app/store';
import { getUserInitials, getUserName } from 'entities/User/User.helper';
import { SupplyCardOtherActionsBtn } from 'entities/Supply/components/SupplyCardOtherActionsBtn';
import { ChangeSupplyStatusPopover } from 'entities/Supply/components/ChangeSupplyStatusPopover';
import { getSupplyStatus } from 'entities/Supply/Supply.helper';
import { GoodsCardMin } from 'entities/Goods/components/GoodsCardMin';

interface IComponentProps {
  nested?: boolean;
  asUser?: boolean;
  onChangeStatusSuccess?: (ids: number[], status?: ESupplyStatus) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SupplyCardComponent: FC<AllType> = (props) => {
  const {
    // props
    nested,
    asUser,
    onChangeStatusSuccess,
    // state
    supplyListLoading,
    currentUser,
    supply,
    supplyLoading,
    need,
    needLoading,
    needSupplyList,
    currentBasket,
    goodsLoading,
    // dispatch
    updateNeedList,
    changeSupplyStatus,
    setNeed,
  } = props;

  const [openSuccessDrawerForPendingSupply, setOpenSuccessDrawerForPendingSupply] = useState<boolean>(false);
  const [openSuccessDrawerForApprovedSupply, setOpenSuccessDrawerForApprovedSupply] = useState<boolean>(false);
  const [openSuccessDrawerForPlacedSupply, setOpenSuccessDrawerForPlacedSupply] = useState<boolean>(false);
  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const {
    setEditSupplyCardMode,
    openSupplyCard,
    setOpenSupplyCard,
    setOpenEditSupplyCard,
    setOpenSupplyCorrectionRequestDrawer,
  } = useSupplyContext();
  const { setNeedCardCurrentMenuTab, setOpenNeedCard } = useNeedContext();
  const { onOpenGoodsCard } = useGoodsContext();

  const isManager = currentUser?.roles.includes(EUserRole.Manager);
  const isSellerManager = currentUser?.roles.includes(EUserRole.SellerManager);
  const count = supply ? supply.count : 0;
  const amount = supply ? supply.price * count : 0;
  const amountWithTaxes = supply ? supply.priceWithTaxes * count : 0;
  const { supplyCreated, supplyPending, supplyApproved, supplyInProcess, supplyInDelivery } = getSupplyStatus(supply?.status);
  const canBeRejected = (isManager && supplyCreated) || (isSellerManager && supplyPending);
  const cancelSupplyItem = {
    key: 'cancel',
    label: 'Отменить поставку',
    className: 'red',
    onClick: () => {
      setOpenSupplyCard(false);
      setOpenWarningDrawer(true);
    },
  };

  const checkNeedIsApproved = (supplyId: number) => {
    return needSupplyList
      .map((supply) => (supply.id === supplyId ? { ...supply, status: ESupplyStatus.Approved } : supply))
      .every((supply) => supply.status !== ESupplyStatus.Created && supply.status !== ESupplyStatus.Pending);
  };

  const onClose = () => {
    setOpenSupplyCard(false);

    if (nested) {
      setOpenNeedCard(true);
    }
  };

  const onConfirmClick = () => {
    if (supply) {
      changeSupplyStatus({
        ids: [supply.id],
        status: ESupplyStatus.Pending,
        onSuccess: () => {
          setOpenSupplyCard(false);
          setOpenSuccessDrawerForPendingSupply(true);
          onChangeStatusSuccess?.([supply.id]);
        },
      });
    }
  };

  const onPlaceClick = () => {
    if (supply) {
      changeSupplyStatus({
        ids: [supply.id],
        status: ESupplyStatus.Approved,
        onSuccess: () => {
          setOpenSupplyCard(false);
          setOpenSuccessDrawerForApprovedSupply(true);

          if (nested) {
            const needIsApproved = checkNeedIsApproved(supply.id);

            if (need) {
              if (needIsApproved) {
                onChangeStatusSuccess?.([need.id]);
                setNeed({ ...need, status: ENeedStatus.Approved });
              } else {
                const newNeed = {
                  ...need,
                  pendingSuppliesCount: need.pendingSuppliesCount - supply.count,
                  approvedSuppliesCount: need.approvedSuppliesCount + supply.count,
                };

                updateNeedList([newNeed]);
                setNeed(newNeed);
              }
            }
          } else {
            onChangeStatusSuccess?.([supply.id]);
          }
        },
      });
    }
  };

  const onTransferClick = () => {
    if (supply) {
      changeSupplyStatus({
        ids: [supply.id],
        status: ESupplyStatus.Processing,
        onSuccess: () => {
          setOpenSupplyCard(false);
          setOpenSuccessDrawerForPlacedSupply(true);
          onChangeStatusSuccess?.([supply.id]);
        },
      });
    }
  };

  const onStatusChange = (status: ESupplyStatus) => {
    if (supply) {
      changeSupplyStatus({
        ids: [supply.id],
        status,
        onSuccess: () => {
          showSuccessMessage(`Статус поставки #${supply.id} изменен.`);

          if (nested) {
            if (status === ESupplyStatus.Delivered) {
              const newNeedSupplyList = needSupplyList.map((supplyItem) => {
                return supplyItem.id === supply.id ? { ...supplyItem, status: ESupplyStatus.Cancelled } : supply;
              });
              const allSuppliesCompleted = newNeedSupplyList.every((needSupply) => {
                return needSupply.status === ESupplyStatus.Delivered || needSupply.status === ESupplyStatus.Cancelled;
              });

              if (allSuppliesCompleted) {
                setOpenSupplyCard(false);

                if (need) {
                  onChangeStatusSuccess?.([need.id]);
                }
              }
            }
          } else {
            setOpenSupplyCard(false);
            onChangeStatusSuccess?.([supply.id], status);
          }
        },
      });
    }
  };

  const onRejectClick = () => {
    if (supply) {
      changeSupplyStatus({
        ids: [supply.id],
        status: ESupplyStatus.Cancelled,
        onSuccess: () => {
          setOpenWarningDrawer(false);
          showSuccessMessage(`Поставка #${supply.id} отменена.`);

          if (nested) {
            const newNeedSupplyList = needSupplyList.map((supplyItem) => {
              return supplyItem.id === supply.id ? { ...supplyItem, status: ESupplyStatus.Cancelled } : supply;
            });
            const allSuppliesCompleted = newNeedSupplyList.every((needSupply) => {
              return needSupply.status === ESupplyStatus.Delivered || needSupply.status === ESupplyStatus.Cancelled;
            });

            if (need && allSuppliesCompleted) {
              onChangeStatusSuccess?.([need.id]);
            } else {
              setOpenSupplyCard(true);

              if (need) {
                const newNeed = {
                  ...need,
                  pendingSuppliesCount: supplyPending ? need.pendingSuppliesCount - supply.count : need.pendingSuppliesCount,
                  approvedSuppliesCount:
                    supplyApproved || supplyInProcess || supplyInDelivery
                      ? need.approvedSuppliesCount - supply.count
                      : need.approvedSuppliesCount,
                };

                updateNeedList([newNeed]);
                setNeed(newNeed);
              }
            }
          } else {
            onChangeStatusSuccess?.([supply.id]);
          }
        },
      });
    }
  };

  const onSuccessDrawerClose = () => {
    setOpenSuccessDrawerForPendingSupply(false);
    setOpenSuccessDrawerForApprovedSupply(false);
    setOpenSuccessDrawerForPlacedSupply(false);

    if (nested) {
      setOpenSupplyCard(true);
    }
  };

  const onNeedClick = () => {
    setOpenSupplyCard(false);
    setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
    setOpenNeedCard(true);
  };

  const onGoodsClick = (id: number) => {
    if (currentBasket) {
      const goodsInBasket = currentBasket.goods.some((goods) => goods.goodId === id);

      onOpenGoodsCard(id, goodsInBasket);
    }
  };

  if (!supply) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName={`redesign drawer supply-card ${nested ? 'nested' : ''}`}
        open={openSupplyCard}
        onClose={supplyListLoading || supplyLoading ? undefined : onClose}
        width={800}
        closeIcon={nested ? <ArrowLeftLongIcon /> : <CloseIcon />}
        destroyOnClose
        zIndex={nested ? DRAWER_Z_INDEX_2 : DRAWER_Z_INDEX_1}
      >
        <div className="drawer__body">
          <div className="drawer__title">{`Поставка #${supply.id}`}</div>

          <div className="mb-52">
            <SupplyStatus status={supply.status} isCancelledBySeller={supply.isCancelledBySeller} />
          </div>

          <div className="supply-card__block">
            <span className="text-h4 supply-card__block-title">Информация о поставке</span>

            <GoodsCardMin
              goods={supply.good}
              canBeOpened={currentBasket?.userId === supply.userId}
              loading={goodsLoading}
              onClick={() => onGoodsClick(supply.good.id)}
            />

            <div className="supply-card__block-table">
              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Количество:</span>

                <span className="text-body">{countFormatter(count)}</span>
              </div>

              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Стоимость товаров:</span>

                <span className="text-body">{priceFormatter(amount)}</span>
              </div>

              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Сумма НДС:</span>

                <span className="text-body">{priceFormatter(amountWithTaxes - amount)}</span>
              </div>

              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Стоимость с НДС:</span>

                <span className="text-body">{priceFormatter(amountWithTaxes)}</span>
              </div>

              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Поставщик:</span>

                <NameLabel icon={<DeliveryIcon className="icon-delivery-bright-green" />} name={supply.sellerName} />
              </div>
            </div>
          </div>

          {supply.priceChanged && (
            <div className="mb-72 need-card-supplies__change-badge">
              <InfoYellowIcon className="icon-info-yellow" />

              <div>
                <span className="text-h4 color-white need-card-supplies__change-badge-title">
                  Произведена корректировка стоимости товара
                </span>

                <span className="text-tag color-white">
                  {`Старая цена поставщика: ${priceFormatter(supply.oldPrice)}; новая цена поставщика ${priceFormatter(
                    supply.price,
                  )}`}
                </span>
              </div>
            </div>
          )}

          <div className="supply-card__block">
            <span className="text-h4 supply-card__block-title">Детали доставки</span>

            <div className="supply-card__block-table">
              {supply.address && (
                <div className="supply-card__block-table-row">
                  <span className="text-body color-dark-grey">Адрес доставки:</span>

                  <span className="text-body">{supply.address.name}</span>
                </div>
              )}

              {supply.deliveryDate && (
                <div className="supply-card__block-table-row">
                  <span className="text-body color-dark-grey">Срок доставки:</span>

                  <span className="text-body">{dateFormatter.fullDateDotSeparator(supply.deliveryDate)}</span>
                </div>
              )}
            </div>
          </div>

          <Spin spinning={needLoading} indicator={<SpinIndicator />}>
            {need && (
              <div className="supply-card__need" onClick={onNeedClick}>
                <div className="supply-card__need-header">
                  <span>
                    <span className="text-lists color-dark-grey">Заявка</span>

                    <span className="text-lists">{' #'}</span>

                    <span className="text-accent">{need.id}</span>
                  </span>

                  <NeedStatus status={need.status} />
                </div>

                <div className="supply-card__need-divider" />

                <div className="supply-card__need-block">
                  <Image size={80} src={need.good.image} />

                  <div className="supply-card__need-block-table">
                    <div className="supply-card__need-block-table-row">
                      <span className="text-body color-dark-grey">Заявка от:</span>

                      <span className="text-body">{dateFormatter.fullDate(need.createdAt)}</span>
                    </div>

                    <div className="supply-card__need-block-table-row">
                      <span className="text-body color-dark-grey">Юридическое лицо:</span>

                      <span className="text-body">{need.legalName}</span>
                    </div>

                    <div className="supply-card__need-block-table-row">
                      <span className="text-body color-dark-grey">Заявитель:</span>

                      <span className="supply-card__need-user">
                        <span className="text-profile-icon color-dark-grey supply-card__need-user-initials">
                          {getUserInitials(need.user.firstName, need.user.lastName)}
                        </span>

                        <span className="text-h4-item-name color-dark-grey supply-card__need-user-name">
                          {getUserName(need.user.firstName, need.user.lastName)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </div>

        {!asUser && (
          <div className="drawer__footer">
            {isManager && supplyPending && (
              <>
                <Button className="button-l primary" onClick={onPlaceClick} loading={supplyListLoading || supplyLoading}>
                  Согласовать доставку
                </Button>

                <SupplyCardOtherActionsBtn
                  loading={supplyListLoading || supplyLoading}
                  items={[
                    {
                      key: 'request',
                      label: 'Запросить корректировку',
                      onClick: () => {
                        setOpenSupplyCard(false);
                        setOpenSupplyCorrectionRequestDrawer(true);
                      },
                    },
                    cancelSupplyItem,
                  ]}
                />
              </>
            )}

            {isSellerManager && supplyCreated && (
              <Button className="button-l primary" onClick={onTransferClick} loading={supplyListLoading || supplyLoading}>
                Передать в учётную систему
              </Button>
            )}

            {isSellerManager &&
              supplyInProcess &&
              (supply.address ? (
                <>
                  <Button
                    className="button-l primary"
                    onClick={onConfirmClick}
                    disabled={!supply.address}
                    loading={supplyListLoading || supplyLoading}
                  >
                    Подтвердить доставку
                  </Button>

                  <SupplyCardOtherActionsBtn
                    loading={supplyListLoading || supplyLoading}
                    disabled={!supply.address}
                    items={[
                      {
                        key: 'edit',
                        label: 'Корректировать поставку',
                        onClick: () => {
                          setOpenSupplyCard(false);
                          setEditSupplyCardMode(EEditSupplyCardMode.Full);
                          setOpenEditSupplyCard(true);
                        },
                      },
                      cancelSupplyItem,
                    ]}
                  />
                </>
              ) : (
                <Button
                  className="button-l danger"
                  onClick={() => {
                    setOpenSupplyCard(false);
                    setOpenWarningDrawer(true);
                  }}
                  loading={supplyListLoading}
                >
                  Отменить поставку
                </Button>
              ))}

            {isSellerManager && supplyPending && (
              <Button
                className="button-l secondary"
                onClick={() => {
                  setOpenSupplyCard(false);
                  setEditSupplyCardMode(EEditSupplyCardMode.Date);
                  setOpenEditSupplyCard(true);
                }}
                loading={supplyListLoading}
              >
                Изменить дату поставки
              </Button>
            )}

            {isSellerManager && (supplyApproved || supplyInDelivery) && (
              <>
                <ChangeSupplyStatusPopover onApply={onStatusChange}>
                  <Button className="button-l primary" loading={supplyListLoading || supplyLoading}>
                    Изменить статус доставки
                  </Button>
                </ChangeSupplyStatusPopover>

                <SupplyCardOtherActionsBtn
                  loading={supplyListLoading || supplyLoading}
                  disabled={!supply.address}
                  items={[
                    {
                      key: 'edit',
                      label: 'Изменить дату поставки',
                      onClick: () => {
                        setOpenSupplyCard(false);
                        setEditSupplyCardMode(EEditSupplyCardMode.Date);
                        setOpenEditSupplyCard(true);
                      },
                    },
                    cancelSupplyItem,
                  ]}
                />
              </>
            )}

            {canBeRejected && (
              <Button
                className="button-l danger"
                onClick={() => {
                  setOpenSupplyCard(false);
                  setOpenWarningDrawer(true);
                }}
                loading={supplyListLoading}
              >
                Отменить поставку
              </Button>
            )}
          </div>
        )}
      </Drawer>

      <SuccessDrawer
        open={openSuccessDrawerForPendingSupply}
        subtitle="Готово!"
        content={`Поставка #${supply.id} подтверждена и направлена клиенту на согласование`}
        btnTitle="Назад к заявке"
        onClose={onSuccessDrawerClose}
        onConfirm={onSuccessDrawerClose}
      />

      <SuccessDrawer
        open={openSuccessDrawerForApprovedSupply}
        subtitle="Готово!"
        content="Заказ размещен у поставщика. Статусы запланированных поставок можно отследить в разделе “Поставки”."
        btnTitle="Назад к заявке"
        onClose={onSuccessDrawerClose}
        onConfirm={onSuccessDrawerClose}
      />

      <SuccessDrawer
        open={openSuccessDrawerForPlacedSupply}
        subtitle="Готово!"
        content={`Поставка #${supply.id} размещена в вашей учётной системе`}
        btnTitle="Назад к заявке"
        onClose={onSuccessDrawerClose}
        onConfirm={onSuccessDrawerClose}
      />

      <WarningDrawer
        open={openWarningDrawer}
        content="Вы уверены, что хотите отменить поставку?"
        subtitle="Запланированная поставка будет прекращена по установленному регламенту."
        confirmBtnTitle="Отменить поставку"
        loading={supplyListLoading}
        onClose={() => {
          setOpenWarningDrawer(false);
          setOpenSupplyCard(true);
        }}
        onConfirm={onRejectClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  supplyListLoading: state.supplyListState.loading,
  currentUser: state.userState.currentUser,
  supply: state.supplyState.data,
  supplyLoading: state.supplyState.loading,
  need: state.needState.data,
  needLoading: state.needState.loading,
  needSupplyList: state.needState.supplyList,
  currentBasket: state.basketState.currentBasket,
  goodsLoading: state.goodsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateNeedList: dispatch.needListState.updateNeedList,
  changeSupplyStatus: dispatch.supplyState.changeSupplyStatus,
  setNeed: dispatch.needState.setNeed,
});

export const SupplyCard = connect(mapState, mapDispatch)(SupplyCardComponent);
