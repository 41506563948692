import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { EPresetTypeLabel } from 'common/const/preset.enum';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { PROPERTY_CASCADER_ITEM_PROPERTY_OFFSET } from 'common/config';
import {
  IPropertyCascaderItem,
  IPropertyCascaderItemDeletePropertyPayload,
  IPropertyCascaderItemSelectPropertyPayload,
  IProperty,
} from 'entities/Property/Property.models';
import { getCascaderItemAllProperties, getCascaderItemVisibleProperties } from 'entities/Property/Property.helper';

interface IComponentProps {
  cascaderItem: IPropertyCascaderItem;
  propertyList?: IProperty[];
  selectProperty: (payload: IPropertyCascaderItemSelectPropertyPayload) => void;
  deleteProperty: (payload: IPropertyCascaderItemDeletePropertyPayload) => Promise<void>;
}

export const PropertyCascaderItemAllProperties: FC<IComponentProps> = (props) => {
  const { cascaderItem, propertyList, selectProperty, deleteProperty } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [offset, setOffset] = useState<number>(PROPERTY_CASCADER_ITEM_PROPERTY_OFFSET);
  const containerRef = useRef<HTMLDivElement>(null);

  const cascaderItemAllProperties = useMemo(() => getCascaderItemAllProperties(propertyList), [propertyList]);
  const visibleProperties = useMemo(() => {
    return getCascaderItemVisibleProperties(cascaderItemAllProperties, searchValue, offset);
  }, [cascaderItemAllProperties, searchValue, offset]);

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setOffset(PROPERTY_CASCADER_ITEM_PROPERTY_OFFSET);
  };

  const handlePropertySelect = (id: number) => {
    selectProperty({ propertyId: id, cascaderItemId: cascaderItem.id });
  };

  const handleDeleteProperty = (id: number) => {
    deleteProperty({ propertyId: id, cascaderItem });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollHeight - containerRef.current.scrollTop === containerRef.current.clientHeight
      ) {
        setOffset((prev) => prev + PROPERTY_CASCADER_ITEM_PROPERTY_OFFSET);
      }
    };

    containerRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="property-cascader-item">
      <div className="property-cascader-item__title">{cascaderItem.value}</div>

      <Input value={searchValue} onChange={onSearchValueChange} placeholder="Введите текст" />

      <div ref={containerRef} className="property-cascader-item__container">
        {visibleProperties.map(({ id, name, unitOfMeasurement, isCategory, type }) => {
          const propertyUnitOfMeasurement = unitOfMeasurement ? `,${unitOfMeasurement}` : '';
          const propertyName = `${name}${propertyUnitOfMeasurement}`;
          const propertyType = EPresetTypeLabel[type];
          const containerClassNames = [
            'property-cascader-item__property',
            cascaderItem.selectedPropertyId === id ? 'selected' : '',
            isCategory ? 'is-category' : '',
          ].join(' ');

          return (
            <div key={id} className={containerClassNames} onClick={() => handlePropertySelect(id)}>
              <div title={`${propertyName} | ${propertyType}`} className="property-cascader-item__property-name">
                <span>{propertyName} | </span>

                <span className="property-cascader-item__property-type">{propertyType}</span>
              </div>

              <ThreeDotMenu
                items={[
                  {
                    key: 'delete',
                    label: (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteProperty(id);
                        }}
                      >
                        Удалить
                      </Button>
                    ),
                    className: 'delete',
                  },
                ]}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
