import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { EMessage } from 'common/const/message.enum';
import { ENotificationType } from 'common/const/notification.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { RootDispatch, RootState } from 'app/store';
import { CreateSubdivisionAddress } from 'entities/Subdivisions/components/CreateSubdivisionAddress';
import { renderSubdivisionAddressRecords } from 'entities/Subdivisions/Subdivisions.helper';
import { IAddress } from 'entities/Addresses/Addresses.models';
import { Notification } from 'entities/Modal/components/Notification';

interface IComponentProps {
  subdivisionId?: number;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SubdivisionAddressesComponent: React.FC<AllType> = (props) => {
  const {
    subdivisionId,
    addressListState,
    addressState,
    deleteSubdivisionAddressModalParams,
    getAddressList,
    updateAddress,
    deleteAddress,
    showModal,
    hideModal,
  } = props;
  const { data, loading: addressListLoading } = addressListState;
  const { loading: addressLoading } = addressState;
  const { open } = deleteSubdivisionAddressModalParams;

  const { removeSearchParam } = useSearchParamsHook();
  const [addressId, setAddressId] = useState<number | null>(null);

  const dataSource = toDataSourceMapper<IAddress>(data);
  const columns = renderSubdivisionAddressRecords(updateAddressAssignment, handleDeleteClick);

  const onBackClick = () => {
    removeSearchParam(ESearchParams.Frame);
  };

  function updateAddressAssignment(id: number) {
    updateAddress({ id, isMain: true });
  }

  function handleDeleteClick(id: number) {
    setAddressId(id);
    showModal(EModalCollectionKey.DeleteSubdivisionAddress);
  }

  const onDeleteAddressCancelClick = () => {
    setAddressId(null);
    hideModal(EModalCollectionKey.DeleteSubdivisionAddress);
  };

  const onDeleteAddressConfirmClick = () => {
    deleteAddress({
      id: addressId as number,
      onSuccess: () => {
        setAddressId(null);
        hideModal(EModalCollectionKey.DeleteSubdivisionAddress);
      },
    });
  };

  useEffect(() => {
    if (subdivisionId) {
      getAddressList({ subdivisionId });
    }
  }, [subdivisionId]);

  return (
    <div className="subdivision-addresses">
      <div>
        <Button className="btn btn-default" onClick={onBackClick}>
          Назад
        </Button>
      </div>

      <div>Адреса доставки</div>

      <CreateSubdivisionAddress subdivisionId={subdivisionId} />

      <Table
        size="small"
        className="subdivision-addresses__table"
        rowClassName="subdivision-addresses__table_item"
        dataSource={dataSource}
        columns={columns}
        loading={addressListLoading || addressLoading}
        showHeader={false}
        pagination={false}
        locale={{ emptyText: EMessage.AddressListIsEmpty }}
      />

      <Notification
        open={open}
        type={ENotificationType.Warning}
        description={EMessage.AddressWillBeRemoved}
        onConfirmClick={onDeleteAddressConfirmClick}
        onCancelClick={onDeleteAddressCancelClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  addressListState: state.addressListState,
  addressState: state.addressState,
  deleteSubdivisionAddressModalParams: state.modalCollection.deleteSubdivisionAddressModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAddressList: dispatch.addressListState.getAddressList,
  updateAddress: dispatch.addressState.updateAddress,
  deleteAddress: dispatch.addressState.deleteAddress,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const SubdivisionAddresses = connect(mapState, mapDispatch)(SubdivisionAddressesComponent);
