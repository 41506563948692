import React, { FC } from 'react';
import { Button } from 'antd';

interface IComponentProps {
  open: boolean;
  onClick?: () => void;
}

export const EmptyWorkspace: FC<IComponentProps> = ({ open, onClick }) => {
  if (!open) {
    return null;
  }

  return (
    <div className="empty-list">
      <div className="empty-list__wrapper">
        <div className="empty-list__container">
          <span className="text-h4 color-dark-grey empty-list__description">Список инструментов пуст.</span>

          <Button className="button-l secondary" onClick={onClick}>
            Добавить позицию
          </Button>
        </div>
      </div>
    </div>
  );
};
