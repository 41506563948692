import React, { FC } from 'react';
import { Dropdown } from 'antd';
import { NameLabel } from 'common/components/NameLabel';
import { Counter } from 'common/components/Counter';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as EditIcon } from 'app/assets/images/redesign/edit.svg';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { getUserName } from 'entities/User/User.helper';
import { INeed } from 'entities/Need/Need.models';
import { GoodsCardMin } from 'entities/Goods/components/GoodsCardMin';

interface IComponentProps {
  need: INeed;
  canEditCount: boolean;
  count: number;
  goodsCardCanBeOpened: boolean;
  goodsLoading: boolean;
  onCountChange: (value: number) => void;
  onGoodsClick: (id: number) => void;
}

export const NeedCardInfo: FC<IComponentProps> = (props) => {
  const { need, canEditCount, count, goodsCardCanBeOpened, goodsLoading, onCountChange, onGoodsClick } = props;

  const amount = need ? count * need.price : 0;
  const amountWithTaxes = need ? count * need.priceWithTaxes : 0;

  if (!need) {
    return null;
  }

  return (
    <div className="need-card-info">
      <GoodsCardMin
        goods={need.good}
        canBeOpened={goodsCardCanBeOpened}
        loading={goodsLoading}
        onClick={() => onGoodsClick(need.good.id)}
      />

      {canEditCount && (
        <div className="need-card-info__table-row-edit">
          <span className="text-body color-dark-grey need-card-info__table-row-edit-item-label">Количество:</span>

          <Dropdown
            overlayClassName="redesign"
            rootClassName="need-card-info__table-row-edit-item-dropdown"
            menu={{ items: [{ key: '' }] }}
            trigger={['click']}
            dropdownRender={() => {
              return (
                <div className="custom-dropdown" style={{ position: 'absolute' }}>
                  <span className="text-tag-accent color-bright-green custom-dropdown__title">Редактировать количество</span>

                  <Counter count={count} onChange={onCountChange} />
                </div>
              );
            }}
          >
            <div className="need-card-info__table-row-edit-item-container">
              <span className="text-body color-black">{countFormatter(count)}</span>

              <EditIcon className="icon-edit-dark-grey" />
            </div>
          </Dropdown>
        </div>
      )}

      <div className="need-card-info__table">
        {!canEditCount && (
          <div className="need-card-info__table-row">
            <span className="text-body color-dark-grey">Количество:</span>

            <span className="text-body color-black">{countFormatter(count)}</span>
          </div>
        )}

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Стоимость товаров:</span>

          <span className="text-body">{priceFormatter(amount)}</span>
        </div>

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Сумма НДС:</span>

          <span className="text-body">{priceFormatter(amountWithTaxes - amount)}</span>
        </div>

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Стоимость с НДС:</span>

          <span className="text-body">{priceFormatter(amountWithTaxes)}</span>
        </div>

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Подразделение:</span>

          <NameLabel icon={<AffiliateIcon className="icon-affiliate-bright-green" />} name={need.subdivisionName} />
        </div>

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Заявитель:</span>

          <NameLabel
            icon={<UserIcon className="icon-user-bright-green" />}
            name={getUserName(need.user.firstName, need.user.lastName)}
            iconClassName="user"
          />
        </div>
      </div>

      <div className="need-card-info__delivery">
        <span className="text-h4 need-card-info__delivery-title">Детали доставки</span>

        <div className="need-card-info__delivery-container">
          {need.address && (
            <div className="need-card-info__delivery-row">
              <span className="text-body color-dark-grey">Адрес:</span>

              <span className="text-body">{need.address.name}</span>
            </div>
          )}

          <div className="need-card-info__delivery-row">
            <span className="text-body color-dark-grey">Юридическое лицо:</span>

            <span className="text-body">{need.legalName}</span>
          </div>

          <div className="need-card-info__table-row">
            <span className="text-body color-dark-grey">Поставщик:</span>

            <NameLabel icon={<DeliveryIcon className="icon-delivery-bright-green" />} name={need.sellerName} />
          </div>
        </div>
      </div>
    </div>
  );
};
