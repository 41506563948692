import React, { ChangeEvent, useState } from 'react';
import { Button, Input } from 'antd';
import { connect } from 'react-redux';
import { EMessage } from 'common/const/message.enum';
import { EUserRole } from 'common/const/user.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootDispatch, RootState } from 'app/store';
import { SelectUserModal } from 'entities/Modal/components/SelectUserModal';
import { IUser } from 'entities/User/User.models';
import { ISubdivision, ISubdivisionUpdatePayload } from 'entities/Subdivisions/Subdivisions.models';
import { getUserName } from 'entities/User/User.helper';

interface IComponentProps {
  subdivision?: ISubdivision;
  loading: boolean;
  updateSubdivision: (payload: ISubdivisionUpdatePayload) => Promise<void>;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const SubdivisionManagerComponent: React.FC<AllType> = (props) => {
  const { subdivision, loading, userListState, selectManagerModalParams, updateSubdivision, getUserList, showModal, hideModal } =
    props;

  const { data: userList, loading: userListLoading } = userListState;
  const { open } = selectManagerModalParams;

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedManager, setSelectedManager] = useState<IUser | null>(null);

  const onSubdivisionManagerChangeClick = () => {
    showModal(EModalCollectionKey.SelectManager);
  };

  const onSubdivisionManagerSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedManager(null);
    setSearchValue(e.target.value);
  };

  const onSubdivisionManagerSelect = (value: IUser) => {
    setSelectedManager(value);
  };

  const onSubdivisionManagerCancelClick = () => {
    setSelectedManager(null);
    setSearchValue('');
    hideModal(EModalCollectionKey.SelectManager);
  };

  const onSubdivisionManagerSelectUserClick = () => {
    if (subdivision) {
      updateSubdivision({
        id: subdivision.id,
        managerId: selectedManager?.id as number,
        onSuccess: () => {
          onSubdivisionManagerCancelClick();
        },
      });
    }
  };

  useDebounce(() => {
    if (open) {
      getUserList({ search: searchValue, role: EUserRole.Manager, limit: 0 });
    }
  }, [open, searchValue]);

  return (
    <div className="subdivision-info__field">
      <div className="subdivision-info__label">Менеджер по закупкам</div>

      <div className="subdivision-info__field-container">
        <Input
          value={subdivision?.managerId ? getUserName(subdivision.managerFirstName, subdivision.managerLastName) : ''}
          placeholder="Выберите менеджера"
          disabled
        />

        <Button className="btn btn-primary" onClick={onSubdivisionManagerChangeClick}>
          Изменить
        </Button>
      </div>

      <SelectUserModal
        title="Выбрать пользователя"
        open={open}
        users={userList}
        updateLoading={loading}
        searchLoading={userListLoading}
        searchValue={searchValue}
        selectedUser={selectedManager}
        emptyText={EMessage.ManagerListIsEmpty}
        onSearch={onSubdivisionManagerSearch}
        onSelect={onSubdivisionManagerSelect}
        onCancelClick={onSubdivisionManagerCancelClick}
        onSelectUserClick={onSubdivisionManagerSelectUserClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  userListState: state.userListState,
  selectManagerModalParams: state.modalCollection.selectManagerModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const SubdivisionManager = connect(mapState, mapDispatch)(SubdivisionManagerComponent);
