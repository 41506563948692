import React, { FC } from 'react';
import { EPresetType } from 'common/const/preset.enum';
import { getPresetRangeValue } from 'common/helpers/preset.helper';
import { IWorkspacePositionProperty } from 'entities/Workspace/Workspace.models';

interface IComponentProps {
  item: IWorkspacePositionProperty;
}

export const Preset: FC<IComponentProps> = ({ item }) => {
  const { propertyId, propertyName, presetType, values, range, unitOfMeasurement } = item;

  const isSingleValue = presetType === EPresetType.SingleValue;
  const isMultipleValue = presetType === EPresetType.MultipleValue;
  const isRange = presetType === EPresetType.Range;
  const isCheckbox = presetType === EPresetType.Checkbox;
  const unit = unitOfMeasurement ? unitOfMeasurement : '';

  if (isSingleValue) {
    return (
      <div key={propertyId} className="redesign preset">
        <span className="text-tag">{`${propertyName}:`}</span>

        <span className="text-tag-accent">
          {values?.[0]} {unit}
        </span>
      </div>
    );
  }

  if (isMultipleValue || isCheckbox) {
    values?.map((value) => {
      return (
        <div key={propertyId} className="redesign preset">
          <span className="text-tag">{`${propertyName}:`}</span>

          <span className="text-tag-accent">
            {value} {unit}
          </span>
        </div>
      );
    });
  }

  if (isRange) {
    return (
      <div key={propertyId} className="redesign preset">
        <span className="text-tag">{`${propertyName}:`}</span>

        <span className="text-tag-accent">
          {getPresetRangeValue(range)} {unit}
        </span>
      </div>
    );
  }

  return null;
};
