export enum EModalCollectionKey {
  CreateAccount = 'createAccountModalParams',
  BlockAccount = 'blockAccountModalParams',
  DeleteAccount = 'deleteAccountModalParams',
  ChangePassword = 'changePasswordModalParams',
  CreateSubdivision = 'createSubdivisionModalParams',
  DeleteSubdivision = 'deleteSubdivisionModalParams',
  ChangeSubdivisionLimit = 'changeSubdivisionLimitModalParams',
  SelectUser = 'selectUserModalParams',
  InviteUser = 'inviteUserModalParams',
  ChangeSubdivisionUser = 'changeSubdivisionUserModalParams',
  ExcludeSubdivisionUser = 'excludeSubdivisionUserModalParams',
  CreateSubdivisionAddress = 'createSubdivisionAddressModalParams',
  DeleteSubdivisionAddress = 'deleteSubdivisionAddressModalParams',
  CreateSubdivisionWorkspace = 'createSubdivisionWorkspaceModalParams',
  DeleteSubdivisionWorkspace = 'deleteSubdivisionWorkspaceModalParams',
  SelectSubdivision = 'selectSubdivisionModalParams',
  SelectWorkspace = 'selectWorkspaceModalParams',
  DeleteUser = 'deleteUserModalParams',
  SelectManager = 'selectManagerModalParams',
  SelectTemplate = 'selectTemplateModalParams',
  PositionUnsaved = 'positionUnsavedModalParams',
  DeleteWorkspacePosition = 'deleteWorkspacePositionModalParams',
  RepeatRequest = 'repeatRequestModalParams',
  ChangeSubdivisionCode = 'changeSubdivisionCodeModalParams',
  AddContract = 'addContractModalParams',
  LegalEntity = 'legalEntityModalParams',
  CreateDiscount = 'createDiscountModalParams',
  StockForm = 'stockFormModalParams',
  AddShippingStock = 'addShippingStockModalParams',
}
