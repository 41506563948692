import React, { FC } from 'react';
import { Button } from 'antd';
import { EPresetType } from 'common/const/preset.enum';
import { generateUid } from 'common/helpers/common.helper';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { CatalogFilterChipsItem } from 'entities/Catalog/components/CatalogFilterChipsItem';
import { setCatalogGoodsListFilterItemsHidden, showCatalogFilterChipsClearAllBtn } from 'entities/Catalog/Catalog.helper';

export const CatalogFilterChips: FC = () => {
  const { propertiesForFilter, setFilterIsChanged, setPropertiesForFilter } = useCatalogContext();

  const onClear = (id: number, value: string) => {
    const newPropertiesForFilter = propertiesForFilter.map((property) => {
      if (property.id === id) {
        if (property.presetType === EPresetType.SingleValue || property.presetType === EPresetType.Range) {
          return { ...property, result: [] };
        }

        return { ...property, result: property.result.filter((item) => item !== value) };
      }

      return property;
    });

    const mappedNewPropertiesForFilter = setCatalogGoodsListFilterItemsHidden(newPropertiesForFilter);

    setPropertiesForFilter(mappedNewPropertiesForFilter);
    setFilterIsChanged(true);
  };

  const onClearAll = () => {
    const newPropertiesForFilter = propertiesForFilter.map((property) => ({ ...property, result: [] }));
    const mappedNewPropertiesForFilter = setCatalogGoodsListFilterItemsHidden(newPropertiesForFilter);

    setPropertiesForFilter(mappedNewPropertiesForFilter);
    setFilterIsChanged(true);
  };

  return (
    <>
      {propertiesForFilter.map(({ id, presetType, displayName, result, unitOfMeasurement }) => {
        if (!result.length) {
          return null;
        }

        if (presetType === EPresetType.SingleValue) {
          const value = result[0];

          return (
            <CatalogFilterChipsItem
              key={generateUid()}
              name={displayName}
              value={value}
              unitOfMeasurement={unitOfMeasurement}
              onClear={() => onClear(id, value)}
            />
          );
        }

        if (presetType === EPresetType.MultipleValue || presetType === EPresetType.Checkbox) {
          return result.map((value) => {
            return (
              <CatalogFilterChipsItem
                key={generateUid()}
                name={displayName}
                value={value}
                unitOfMeasurement={unitOfMeasurement}
                onClear={() => onClear(id, value)}
              />
            );
          });
        }

        if (presetType === EPresetType.Range) {
          const from = result[0];
          const to = result[1];
          let value = '';

          if (from) {
            value += `от ${from}`;
          }

          if (to) {
            value += ` до ${to}`;
          }

          return (
            <CatalogFilterChipsItem
              key={generateUid()}
              name={displayName}
              value={value}
              unitOfMeasurement={unitOfMeasurement}
              onClear={() => onClear(id, value)}
            />
          );
        }

        return null;
      })}

      {showCatalogFilterChipsClearAllBtn(propertiesForFilter) && (
        <div className="catalog-filter-chips__clear-all">
          <span className="text-controls color-black">Очистить всё</span>

          <Button className="button-icon catalog-filter-chips__clear-all-close" icon={<CloseIcon />} onClick={onClearAll} />
        </div>
      )}
    </>
  );
};
