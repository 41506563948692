import React, { FC, useState } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { IOption } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { getContractName } from 'entities/Contract/Contract.helper';
import { stockListToSelectOptions, toDeliveryData } from 'entities/Delivery/Delivery.helper';
import { DeliveryCity } from 'entities/Delivery/components/DeliveryCity';
import { StockCity } from 'entities/Delivery/components/StockCity';
import { AddShippingStockModal } from 'entities/Modal/components/AddShippingStockModal';
import { IStockCity } from 'entities/Delivery/Delivery.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const DeliveryForContractComponent: FC<AllType> = (props) => {
  const {
    deliveryCityListState,
    stockCityListState,
    stockListState,
    contractState,
    stockCityState,
    openAddShippingStockModal,
    getDeliveryCityList,
    getStockCityList,
    getStockList,
    getContractById,
    createStockCity,
    updateStockCity,
    deleteStockCity,
    showModal,
    hideModal,
  } = props;

  const { data: deliveryCityList } = deliveryCityListState;
  const { data: stockCityList, loading: stockCityListLoading } = stockCityListState;
  const { data: stockList } = stockListState;
  const { data: contract } = contractState;
  const { loading: stockCityLoading } = stockCityState;

  const [city, setCity] = useState<string | null>(null);
  const [stockOptions, setStockOptions] = useState<IOption[]>([]);
  const [stockId, setStockId] = useState<number | null>(null);
  const [deliveryInDays, setDeliveryInDays] = useState<number | null>(null);
  const { id, buyerId } = useParams();

  const contractName = getContractName(contract);
  const deliveryData = toDeliveryData(deliveryCityList, stockCityList);

  const onAddShippingStockClick = (city: string, stocks: IStockCity[]) => {
    const stockOptions = stockListToSelectOptions(stockList, stocks);

    setCity(city);
    setStockOptions(stockOptions);
    showModal(EModalCollectionKey.AddShippingStock);
  };

  const onAddShippingStockModalCancelClick = () => {
    setCity(null);
    setStockOptions([]);
    setStockId(null);
    setDeliveryInDays(null);
    hideModal(EModalCollectionKey.AddShippingStock);
  };

  const onAddShippingStockModalAddClick = () => {
    if (id && city && stockId && deliveryInDays) {
      createStockCity({ contractId: Number(id), city, stockId, deliveryInDays, onSuccess: onAddShippingStockModalCancelClick });
    }
  };

  const onDeliveryInDaysChange = (stockId: number, deliveryInDays: number) => {
    updateStockCity({ id: stockId, deliveryInDays });
  };

  const onDeleteStockCityClick = (stockId: number) => {
    deleteStockCity({ id: stockId });
  };

  const { contentLoading } = useContentLoader(async () => {
    await getContractById(Number(id));
    await getDeliveryCityList({ buyerId: Number(buyerId) });
    await getStockCityList({ contractId: Number(id), limit: 0 });
    await getStockList({ limit: 0 });
  });

  return (
    <div className="delivery-for-contract">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <div className="delivery-for-contract__title">
            <span className="delivery-for-contract__title-prefix">{`Контракты / ${contractName}`}</span>

            <span> / Сроки доставки</span>
          </div>

          <div className="delivery-for-contract__container">
            <Spin spinning={stockCityListLoading || stockCityLoading}>
              {deliveryData.map((deliveryCity) => {
                return (
                  <div key={deliveryCity.city}>
                    <DeliveryCity
                      deliveryCity={deliveryCity}
                      onAddShippingStockClick={(city) => onAddShippingStockClick(city, deliveryCity.stocks)}
                    />

                    {deliveryCity.stocks.map((stock) => {
                      const stockItem = stockList.find((item) => item.id === stock.stockId);

                      return (
                        <StockCity
                          key={stock.id}
                          stock={stock}
                          city={stockItem?.city}
                          onDeliveryInDaysChange={onDeliveryInDaysChange}
                          onDeleteStockCityClick={onDeleteStockCityClick}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </Spin>
          </div>
        </>
      )}

      <AddShippingStockModal
        open={openAddShippingStockModal}
        loading={stockCityLoading}
        stockOptions={stockOptions}
        stockId={stockId}
        deliveryInDays={deliveryInDays}
        onStockIdChange={setStockId}
        onDeliveryInDaysChange={setDeliveryInDays}
        onCancelClick={onAddShippingStockModalCancelClick}
        onAddClick={onAddShippingStockModalAddClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  deliveryCityListState: state.deliveryCityListState,
  stockCityListState: state.stockCityListState,
  stockListState: state.stockListState,
  contractState: state.contractState,
  stockCityState: state.stockCityState,
  openAddShippingStockModal: state.modalCollection.addShippingStockModalParams.open,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getDeliveryCityList: dispatch.deliveryCityListState.getDeliveryCityList,
  getStockCityList: dispatch.stockCityListState.getStockCityList,
  getStockList: dispatch.stockListState.getStockList,
  getContractById: dispatch.contractState.getContractById,
  createStockCity: dispatch.stockCityState.createStockCity,
  updateStockCity: dispatch.stockCityState.updateStockCity,
  deleteStockCity: dispatch.stockCityState.deleteStockCity,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const DeliveryForContract = connect(mapState, mapDispatch)(DeliveryForContractComponent);
