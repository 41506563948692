import React from 'react';
import { Col, Image } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Avatar } from 'common/components/Avatar';
import { EPresetType } from 'common/const/preset.enum';
import { getOptionsFromProperty } from 'common/helpers/properties.helper';
import { IFormValues } from 'common/models';
import { FormSelectWithCustomOptions } from 'common/components/Form/FormSelectWithCustomOptions';
import { FormCheckbox } from 'common/components/Form/FormCheckbox';
import { getFormItemLabel, rules } from 'common/helpers/form.helper';
import { ReactComponent as FileIcon } from 'app/assets/images/file.svg';
import { IUser } from 'entities/User/User.models';
import { getUserName } from 'entities/User/User.helper';
import { IWorkspace } from 'entities/Workspaces/Workspaces.models';
import { IGoodsCreatePayload, IGoods } from 'entities/Goods/Goods.models';
import { IProperty } from 'entities/Property/Property.models';

export const renderSelectUserModalRecords = () => {
  return [
    {
      key: 'avatar',
      render: (_, record) => <Avatar size={32} firstName={record.firstName} lastName={record.lastName} />,
      width: '44px',
    },
    {
      key: 'info',
      render: (_, record) => {
        return (
          <div>
            <div>{getUserName(record.firstName, record.lastName)}</div>

            <div className="select-user-modal__table_item-user-email">{record.email}</div>
          </div>
        );
      },
    },
  ] as ColumnsType<IUser>;
};

export const renderWorkspaceCollectionRecords = () => {
  return [
    {
      key: 'icon',
      render: () => (
        <div className="select-workspace-modal__table_item-icon">
          <FileIcon />
        </div>
      ),
      width: '32px',
    },
    {
      key: 'name',
      onCell: () => ({ className: 'select-workspace-modal__table_item-cell-name' }),
      render: (record) => (
        <>
          <div className="select-workspace-modal__table_item-name">{record.name}</div>
          <div className="select-workspace-modal__table_item-name-icon" />
        </>
      ),
      ellipsis: true,
    },
  ] as ColumnsType<IWorkspace>;
};

export const renderQuicklyAddGoodsModalRecords = () => {
  return [
    {
      dataIndex: 'image',
      render: (text) => {
        return <Image wrapperClassName="image image-w67" className={!text ? 'img-empty' : ''} src={text} preview={false} />;
      },
      width: '83px',
    },
    {
      key: 'name',
      render: (record) => (
        <div className="quickly-add-goods-modal__table-item-name">
          <div className="quickly-add-goods-modal__table-item-codes">
            {record.sellerCode && (
              <div className="quickly-add-goods-modal__table-item-sellerCode">{`Код: ${record.sellerCode}`}</div>
            )}

            <div className="quickly-add-goods-modal__table-item-vendorCode">
              <span>Артикул клиента: </span>

              {record.vendorCode ? <span>{record.vendorCode}</span> : <ExclamationCircleFilled />}
            </div>
          </div>

          <div className="quickly-add-goods-modal__table-name">{record.name}</div>
        </div>
      ),
    },
  ] as ColumnsType<IGoods>;
};

export const renderCreateGoodsFormField = (property: IProperty) => {
  if (property.presetType !== EPresetType.Checkbox) {
    return (
      <Col key={property.id} xs={24} sm={12} md={8} lg={6}>
        <FormSelectWithCustomOptions
          className="create-goods-form-modal__form-item-select"
          label={getFormItemLabel(property.displayName, property.unitOfMeasurement)}
          name={property.id}
          rules={[rules.required(property.isRequiredForGoods)]}
          options={getOptionsFromProperty(property)}
          mode={property.goodCanHaveMultipleValues ? 'multiple' : undefined}
          disabled={property.isCategory}
        />
      </Col>
    );
  } else {
    return (
      <Col key={property.id} xs={24} md={12}>
        <FormCheckbox
          formItemClassname="create-goods-form-modal__form-item-checkbox"
          name={property.id}
          // TODO
          options={[{ label: property.displayName, value: 'да' }]}
        />
      </Col>
    );
  }
};

export const createGoodsFormValuesToPayload = (values: IFormValues, propertyList: IProperty[]) => {
  // eslint-disable-next-line
  const { externalId, name, price, sellerCode, tnvd, image, ...rest } = values;
  const payload: IGoodsCreatePayload = {
    externalId,
    name,
    price,
    sellerCode,
    tnvd,
    properties: [],
  };

  Object.entries(rest).forEach(([key, fieldValue]) => {
    const value = fieldValue?.filter((val: string) => !!val);

    if (value && value.length) {
      const propertyId = Number(key);
      const property = propertyList.find((item) => item.id === propertyId);

      if (property) {
        payload.properties.push({
          propertyId,
          values: value,
        });
      }
    }
  });

  return payload;
};

export const qrfpPositionFormValuesToCreateGoodsFormValues = (values: IFormValues, properties?: IProperty[]) => {
  const formValues: IFormValues = {};

  Object.entries(values).forEach(([key, value]) => {
    const propertyId = Number(key);
    const property = properties?.find((item) => item.id === propertyId);

    // TODO
    switch (true) {
      case property && property.presetType === EPresetType.SingleValue: {
        formValues[propertyId] = property?.goodCanHaveMultipleValues ? [value] : value;
        break;
      }
      case property && property.presetType === EPresetType.MultipleValue: {
        formValues[propertyId] = property?.goodCanHaveMultipleValues ? value : value[0];
        break;
      }
      case property && property.presetType === EPresetType.Range: {
        const rangeValue = value[0];

        if (rangeValue.from) {
          formValues[propertyId] = property?.goodCanHaveMultipleValues ? [rangeValue.from] : rangeValue.from;
          break;
        }

        if (rangeValue.to) {
          formValues[propertyId] = property?.goodCanHaveMultipleValues ? [rangeValue.to] : rangeValue.to;
          break;
        }

        break;
      }
      case property && property.presetType === EPresetType.Checkbox: {
        formValues[propertyId] = property?.goodCanHaveMultipleValues ? [value] : value;
        break;
      }
      default: {
        break;
      }
    }
  });

  return formValues;
};
