import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import { EPresetTypeLabel } from 'common/const/preset.enum';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { CHARACTER_LIMIT_MIN } from 'common/config';
import {
  IPropertyCascaderItem,
  IPropertyCascaderItemCreatePropertyPayload,
  IPropertyCascaderItemSelectPropertyPayload,
  IPropertyCascaderItemSubscribePropertyPayload,
  IPropertyCascaderItemUnsubscribePropertyPayload,
  IProperty,
} from 'entities/Property/Property.models';
import {
  findCascaderItemConditionalProperties,
  findCascaderItemProperty,
  getCascaderItemPropertiesSearchOptions,
} from 'entities/Property/Property.helper';
import { PropertyCascaderItemSearch } from 'entities/Property/components/PropertyCascaderItemSearch';

interface IComponentProps {
  cascaderItem: IPropertyCascaderItem;
  propertyList?: IProperty[];
  scrollContainerLeft?: number;
  selectProperty: (payload: IPropertyCascaderItemSelectPropertyPayload) => void;
  subscribeProperty: (payload: IPropertyCascaderItemSubscribePropertyPayload) => Promise<void>;
  unsubscribeProperty: (payload: IPropertyCascaderItemUnsubscribePropertyPayload) => Promise<void>;
  createProperty: (payload: IPropertyCascaderItemCreatePropertyPayload) => Promise<void>;
  setError: (payload: { error: string | null; cascaderItemId: string }) => void;
}

export const PropertyCascaderItemProperties: FC<IComponentProps> = (props) => {
  const {
    cascaderItem,
    propertyList,
    scrollContainerLeft,
    selectProperty,
    subscribeProperty,
    unsubscribeProperty,
    createProperty,
    setError,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [openSearchDropdown, setOpenSearchDropdown] = useState<boolean>(false);

  const property = findCascaderItemProperty(cascaderItem, propertyList);
  const conditionalProperties = findCascaderItemConditionalProperties(cascaderItem, property, propertyList);
  const searchOptions = getCascaderItemPropertiesSearchOptions(conditionalProperties, property, propertyList);
  const error = cascaderItem.error;

  const onSearchValueChange = (value: string) => {
    if (error) {
      setError({ error: null, cascaderItemId: cascaderItem.id });
    }

    value.trim().length ? setOpenSearchDropdown(true) : setOpenSearchDropdown(false);

    setSearchValue(value);
  };

  const onSearchValueSelect = (value: string) => {
    subscribeProperty({
      propertyId: Number(value),
      parentId: cascaderItem.propertyId as number,
      parentValue: cascaderItem.value,
      cascaderItemId: cascaderItem.id,
      onSuccess: () => {
        setSearchValue('');
        setOpenSearchDropdown(false);
      },
    });
  };

  const handlePropertySelect = (id: number) => {
    selectProperty({ propertyId: id, cascaderItemId: cascaderItem.id });
  };

  const handleUnsubscribeProperty = (id: number) => {
    unsubscribeProperty({
      propertyId: id,
      parentId: cascaderItem.propertyId as number,
      parentValue: cascaderItem.value,
      cascaderItemId: cascaderItem.id,
      selectedPropertyId: cascaderItem.selectedPropertyId,
    });
  };

  const onPropertyCreate = () => {
    if (searchValue) {
      createProperty({
        name: searchValue,
        parentId: cascaderItem.propertyId as number,
        parentValue: cascaderItem.value,
        cascaderItemId: cascaderItem.id,
        onSuccess: () => {
          setSearchValue('');
          setOpenSearchDropdown(false);
        },
      });
    }
  };

  useEffect(() => {
    if (cascaderItem.error) {
      setOpenSearchDropdown(false);
    }
  }, [cascaderItem.error]);

  useEffect(() => {
    setOpenSearchDropdown(false);
  }, [scrollContainerLeft]);

  return (
    <div className="property-cascader-item">
      <div className="property-cascader-item__title">{cascaderItem.value}</div>

      <PropertyCascaderItemSearch
        open={openSearchDropdown}
        options={searchOptions}
        searchValue={searchValue}
        disabled={!!cascaderItem.rootPropertyId}
        error={error}
        limit={CHARACTER_LIMIT_MIN}
        popupClassName="property-cascader-item__search-property-dropdown"
        onSearch={onSearchValueChange}
        onSelect={onSearchValueSelect}
        onCreate={onPropertyCreate}
        onBlur={() => setOpenSearchDropdown(false)}
        onFocus={() => searchValue.length && setOpenSearchDropdown(true)}
      />

      <div className="property-cascader-item__container">
        {conditionalProperties.map(({ id, name, unitOfMeasurement, isCategory, type }) => {
          const propertyUnitOfMeasurement = unitOfMeasurement ? `,${unitOfMeasurement}` : '';
          const propertyName = `${name}${propertyUnitOfMeasurement}`;
          const propertyType = isCategory ? 'категория' : EPresetTypeLabel[type];
          const containerClassNames = [
            'property-cascader-item__property',
            cascaderItem.selectedPropertyId === id ? 'selected' : '',
            isCategory ? 'is-category' : '',
          ].join(' ');

          return (
            <div key={id} className={containerClassNames} onClick={() => handlePropertySelect(id)}>
              <div title={`${propertyName} | ${propertyType}`} className="property-cascader-item__property-name">
                <span>{propertyName} | </span>

                <span className="property-cascader-item__property-type">{propertyType}</span>
              </div>

              {!isCategory && (
                <ThreeDotMenu
                  items={[
                    {
                      key: 'unsubscribe',
                      label: (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUnsubscribeProperty(id);
                          }}
                        >
                          Отвязать
                        </Button>
                      ),
                      className: 'edit',
                    },
                  ]}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
