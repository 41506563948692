import { RematchDispatch } from '@rematch/core';
import { message } from 'antd';
import * as Sentry from '@sentry/react';
import { getStorageItem, removeStorageItem, saveStorageItem } from 'common/helpers/axios.helper';
import { EStorageKey } from 'common/const/axios.enum';
import { ECommonErrorMessage } from 'common/const/axios.enum';
import { IRootModel } from 'app/store';
import { IAuth, IToken, refreshTokenExpiration } from 'entities/Auth/Auth.models';

export const saveCreds = async (dispatch: RematchDispatch<IRootModel>, response: IAuth) => {
  const xUserId = getStorageItem<string>(EStorageKey.XUserId);
  const now = Math.round(Date.now() / 1000);

  const creds = {
    access: {
      id: response.id,
      token: response.accessToken,
      userId: response.id,
      issuedAt: now,
      expiredAt: response.expiresIn ? now + Number(response.expiresIn) : 0,
      accountId: response.accountId,
      accountName: response.accountName,
      firstName: response.firstName,
      lastName: response.lastName,
      roles: response.roles,
      isSeller: response.isSeller,
      subdivisionName: response.subdivisionName,
      basketId: response.basketId,
    },
    refresh: {
      id: response.id,
      token: response.refreshToken,
      userId: response.id,
      issuedAt: now,
      expiredAt: now + refreshTokenExpiration,
    },
  };

  if (xUserId) {
    // If xUserId is present, it means that the super administrator is currently in another user’s account.
    const userCredsFromStorage = getStorageItem<IToken>(EStorageKey.Creds);

    if (userCredsFromStorage) {
      /*
      Access/Refresh token, issuedAt, expiredAt - from super admin creds
      */
      const userCreds = {
        access: {
          ...creds.access,
          id: userCredsFromStorage.access.id,
          userId: userCredsFromStorage.access.userId,
          accountId: userCredsFromStorage.access.accountId,
          accountName: userCredsFromStorage.access.accountName,
          firstName: userCredsFromStorage.access.firstName,
          lastName: userCredsFromStorage.access.lastName,
          roles: userCredsFromStorage.access.roles,
          isSeller: userCredsFromStorage.access.isSeller,
          subdivisionName: userCredsFromStorage.access.subdivisionName,
          basketId: userCredsFromStorage.access.basketId,
        },
        refresh: {
          ...creds.refresh,
          id: userCredsFromStorage.refresh.id,
          userId: userCredsFromStorage.refresh.userId,
        },
      };

      dispatch.authState.setAuth(userCreds);
      dispatch.authState.setAuthSuperAdminAsUser(true);
      saveStorageItem(EStorageKey.Creds, userCreds);
      saveStorageItem(EStorageKey.SuperAdminCreds, creds);
    } else {
      message.error(ECommonErrorMessage.UnexpectedError);
      Sentry.captureMessage('User creds from storage is undefined');
    }
  } else {
    dispatch.authState.setAuth(creds);
    saveStorageItem(EStorageKey.Creds, creds);
  }
};

export const clearCreds = (dispatch: RematchDispatch<IRootModel>) => {
  removeStorageItem(EStorageKey.Creds);
  removeStorageItem(EStorageKey.XUserId);
  removeStorageItem(EStorageKey.XBasketId);
  removeStorageItem(EStorageKey.SuperAdminCreds);
  removeStorageItem(EStorageKey.UserCatalogId);

  dispatch.authState.setAuth(null);
  dispatch.authState.setAuthLoading(false);
  dispatch.authState.setAuthSuperAdminAsUser(false);
  dispatch.authState.setAuthUserCatalogId(null);
  dispatch.authState.setAuthXBasketId(null);
};
