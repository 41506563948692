import React, { FC, useState } from 'react';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { EAddressModalTabKey } from 'common/const/address.enum';
import { EMessage } from 'common/const/message.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { useDebounce } from 'common/hooks/useDebounce';
import { IFormValues } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { renderStockListRecords } from 'entities/Stock/Stock.helper';
import { StockFormModal } from 'entities/Modal/components/StockFormModal';
import { getAddressSuggestionCity } from 'entities/Addresses/Addresses.helper';
import { IStock } from 'entities/Stock/Stock.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const StockListComponent: FC<AllType> = (props) => {
  const {
    stockListState,
    addressSuggestionListState,
    stockState,
    openStockFormModal,
    getStockList,
    getAddressSuggestionList,
    createStock,
    updateStock,
    deleteStock,
    showModal,
    hideModal,
  } = props;
  const { data: stockList } = stockListState;
  const { data: addressSuggestionList, loading: addressSuggestionListLoading } = addressSuggestionListState;
  const { loading: stockLoading } = stockState;

  const [searchValue, setSearchValue] = useState<string>('');
  const [tab, setTab] = useState<string>(EAddressModalTabKey.AutomaticSearch);
  const [stockId, setStockId] = useState<number | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [form] = useForm();

  const dataSource = toDataSourceMapper(stockList);
  const columns = renderStockListRecords(onEditStockClick, onDeleteStockClick);

  const onCreateStockClick = () => {
    showModal(EModalCollectionKey.StockForm);
  };

  function onEditStockClick(stock: IStock) {
    form.setFieldsValue({
      name: stock.displayName,
      manualAddress: stock.address,
      manualCity: stock.city,
    });

    setStockId(stock.id);
    setIsEdit(true);
    setTab(EAddressModalTabKey.ManualInput);
    showModal(EModalCollectionKey.StockForm);
  }

  function onDeleteStockClick(id: number) {
    deleteStock({ id });
  }

  const onStockModalCancelClick = () => {
    form.resetFields();
    setStockId(null);
    setIsEdit(false);
    setTab(EAddressModalTabKey.AutomaticSearch);
    hideModal(EModalCollectionKey.StockForm);
  };

  const onCreateStockSubmit = (values: IFormValues) => {
    const { name, address, manualAddress, manualCity } = values;

    const city =
      tab === EAddressModalTabKey.AutomaticSearch ? getAddressSuggestionCity(address, addressSuggestionList) : manualCity;

    createStock({
      name,
      displayName: name,
      address: tab === EAddressModalTabKey.AutomaticSearch ? address : manualAddress,
      city,
      onSuccess: onStockModalCancelClick,
    });
  };

  const onUpdateStockSubmit = (values: IFormValues) => {
    const { name, address, manualAddress, manualCity } = values;

    const city =
      tab === EAddressModalTabKey.AutomaticSearch ? getAddressSuggestionCity(address, addressSuggestionList) : manualCity;

    if (stockId) {
      updateStock({
        id: stockId,
        name,
        displayName: name,
        address: tab === EAddressModalTabKey.AutomaticSearch ? address : manualAddress,
        city,
        onSuccess: onStockModalCancelClick,
      });
    }
  };

  useDebounce(() => {
    if (openStockFormModal) {
      getAddressSuggestionList({ name: searchValue });
    }
  }, [searchValue, openStockFormModal]);

  const { contentLoading } = useContentLoader(async () => {
    await getStockList({ limit: 0 });
  });

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <div className="stock-list">
      <div className="stock-list__title">Склады отгрузки</div>

      <Button className="btn btn-primary" onClick={onCreateStockClick}>
        <PlusOutlined />

        <span>Новый склад</span>
      </Button>

      <Table
        className="stock-list__table"
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        pagination={false}
        loading={stockLoading}
        locale={{ emptyText: EMessage.StockListIsEmpty }}
      />

      <StockFormModal
        open={openStockFormModal}
        form={form}
        tab={tab}
        loading={stockLoading}
        submitBtnTitle={isEdit ? 'Изменить' : 'Добавить'}
        searchOptions={addressSuggestionList}
        searchLoading={addressSuggestionListLoading}
        onTabChange={setTab}
        onSearchChange={setSearchValue}
        onCancelClick={onStockModalCancelClick}
        onSubmit={isEdit ? onUpdateStockSubmit : onCreateStockSubmit}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  stockListState: state.stockListState,
  addressSuggestionListState: state.addressSuggestionListState,
  stockState: state.stockState,
  openStockFormModal: state.modalCollection.stockFormModalParams.open,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getStockList: dispatch.stockListState.getStockList,
  getAddressSuggestionList: dispatch.addressSuggestionListState.getAddressSuggestionList,
  createStock: dispatch.stockState.createStock,
  updateStock: dispatch.stockState.updateStock,
  deleteStock: dispatch.stockState.deleteStock,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const StockList = connect(mapState, mapDispatch)(StockListComponent);
