import React, { useEffect } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { EFormFieldMessage } from 'common/const/form.enum';
import { ERoute } from 'common/const/route.enum';
import { FormInputPassword } from 'common/components/Form/FormInputPassword';
import { PASSWORD_PATTERN } from 'common/config';
import { rules } from 'common/helpers/form.helper';
import { ReactComponent as ProfileIcon } from 'app/assets/images/profile.svg';
import { RootDispatch, RootState } from 'app/store';
import { AuthCard } from 'entities/Auth/components/AuthCard';
import { IInvitationActivatePayload } from 'entities/Invitation/Invitation.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const AccountActivationComponent: React.FC<AllType> = (props) => {
  const { invitationState, getInvitation, activateInvitation, setInvitationError } = props;
  const { data, loading, error } = invitationState;
  const [form] = useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const hash = searchParams.get('hash');

  const onCancelClick = () => {
    setInvitationError(null);
    navigate(ERoute.Login, { replace: true });
  };

  const onSubmit = (values: IInvitationActivatePayload) => {
    const { email, firstName, lastName, password } = values;
    setInvitationError(null);

    activateInvitation({
      hash: hash as string,
      email,
      firstName,
      lastName,
      password,
      onSuccess: () => {
        navigate(ERoute.Profile, { replace: true });
      },
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.accountName,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      });
    }
  }, [data]);

  useEffect(() => {
    if (hash) {
      getInvitation(hash);
    }
  }, [hash]);

  if (!data && !loading) {
    return (
      <div className="spin-container">
        <Spin />
      </div>
    );
  }

  return (
    <div className="auth">
      <AuthCard
        form={form}
        loading={loading}
        body={
          <>
            <Form.Item name="name">
              <Input placeholder="Название компании" disabled />
            </Form.Item>

            <Form.Item name="email">
              <Input placeholder="Электронная почта" disabled />
            </Form.Item>

            <Form.Item label="Имя пользователя" name="firstName" rules={[rules.required()]}>
              <Input placeholder="Введите имя" prefix={<ProfileIcon />} />
            </Form.Item>

            <Form.Item name="lastName" rules={[rules.required()]}>
              <Input placeholder="Введите фамилию" prefix={<ProfileIcon />} />
            </Form.Item>

            <FormInputPassword
              label="Пароль"
              name="password"
              placeholder="Придумайте пароль"
              rules={[rules.required(), rules.pattern(PASSWORD_PATTERN, EFormFieldMessage.InvalidPasswordFormat)]}
            />

            <FormInputPassword
              name="passwordConfirm"
              placeholder="Введите пароль еще раз"
              dependencies={['password']}
              rules={[
                rules.required(),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(EFormFieldMessage.PasswordMismatch));
                  },
                }),
              ]}
            />

            {!!error && (
              <div className="auth-card__error-block">
                <span className="auth-card__error-block-item">{error}</span>
              </div>
            )}
          </>
        }
        footer={
          <>
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" htmlType="submit">
              Активировать аккаунт
            </Button>
          </>
        }
        onSubmit={onSubmit}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  invitationState: state.invitationState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getInvitation: dispatch.invitationState.getInvitation,
  activateInvitation: dispatch.invitationState.activateInvitation,
  setInvitationError: dispatch.invitationState.setInvitationError,
});

export const AccountActivation = connect(mapState, mapDispatch)(AccountActivationComponent);
