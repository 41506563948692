export enum EScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export enum EOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EOrderField {
  CreatedAt = 'createdAt',
  DeliveryDate = 'deliveryDate',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export enum EColor {
  Gray = 'gray',
  Green = 'green',
  Red = 'red',
  Blue = 'blue',
  Orange = 'orange',
}
