import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Select, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { ESupplyStatus } from 'common/const/supply.enum';
import { EDateFormat } from 'common/const/date.enum';
import { DEFAULT_EMPTY_VALUE, LIST_LIMIT_20, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { groupList, mapToMenuWithDivider } from 'common/helpers/common.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { getNeedFormattedDate } from 'entities/Need/Need.helper';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyListEmpty } from 'entities/Supply/components/SupplyListEmpty';
import { ISupplyListPayload } from 'entities/Supply/Supply.models';
import { renderSupplyListRecords } from 'entities/Supply/Supply.helper';
import { supplyListFilterStatusOptionsForUser } from 'entities/Supply/Supply.const';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SupplyListForUserComponent: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    supplyList,
    supplyListLoading,
    supplyListCount,
    currentUser,
    // dispatch
    setSupplyList,
    getSupplyList,
    getSupplyListPart,
    getSupplyById,
    getNeedById,
  } = props;

  const [payload, setPayload] = useState<ISupplyListPayload>({
    userId: currentUser?.id,
    statuses: Object.values(ESupplyStatus),
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const { page, setPage, setOpenSupplyCard } = useSupplyContext();

  const groupedSupplyList = groupList.byCreatedAt(supplyList);

  const onStatusChange = (value: number | string) => {
    const newPayload = {
      ...payload,
      statuses: value === DEFAULT_EMPTY_VALUE ? Object.values(ESupplyStatus) : [value as ESupplyStatus],
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setSupplyList([]);
    setPayload(newPayload);
    getSupplyList(newPayload);
  };

  const onSearchClick = () => {
    setSupplyList([]);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSupplyList([]);
    getSupplyList(payload);
  };

  useEffect(() => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setSupplyList([]);
    getSupplyList(payload);
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!supplyListLoading && supplyList.length < supplyListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getSupplyListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}

        {navigation}

        <div className="need-list__filters">
          <Select
            rootClassName="redesign"
            popupClassName="need-list__filters-select-popup"
            className="need-list__filters-select"
            options={mapToMenuWithDivider(supplyListFilterStatusOptionsForUser)}
            value={payload.statuses && payload.statuses.length === 1 ? payload.statuses[0] : DEFAULT_EMPTY_VALUE}
            onChange={onStatusChange}
            placeholder="Выберите статус"
            popupMatchSelectWidth={false}
            suffixIcon={<ArrowDownShortIcon />}
          />

          <Input
            style={{ flex: 1 }}
            placeholder="Поиск"
            value={payload.search}
            onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
          />

          <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />
        </div>

        <Spin wrapperClassName="need-list__spin" spinning={supplyListLoading} indicator={<SpinIndicator />}>
          {groupedSupplyList.map((group, index) => {
            return (
              <div key={index} className="need-list__group">
                <span className="need-list__group-date">{getNeedFormattedDate(EDateFormat.FullDate, group.createdAt)}</span>

                <Table
                  className="table-hover need-list__table"
                  dataSource={group.dataSource.map((supply) => ({ ...supply, key: supply.id }))}
                  columns={renderSupplyListRecords()}
                  onRow={({ id, supplyNeedId }) => ({
                    onClick: async () => {
                      await getSupplyById({ id, onSuccess: () => setOpenSupplyCard(true) });
                      await getNeedById({ id: supplyNeedId });
                    },
                  })}
                  showHeader={false}
                  pagination={false}
                />
              </div>
            );
          })}

          <SupplyListEmpty open={!supplyListLoading && !groupedSupplyList.length} forUser />
        </Spin>
      </InfiniteScrollContainer>

      <SupplyCard asUser />

      <NeedCard nested asUser />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  supplyList: state.supplyListState.data,
  supplyListLoading: state.supplyListState.loading,
  supplyListCount: state.supplyListState.count,
  currentUser: state.userState.currentUser,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setSupplyList: dispatch.supplyListState.setSupplyList,
  getSupplyList: dispatch.supplyListState.getSupplyList,
  getSupplyListPart: dispatch.supplyListState.getSupplyListPart,
  getSupplyById: dispatch.supplyState.getSupplyById,
  getNeedById: dispatch.needState.getNeedById,
});

export const SupplyListForUser = connect(mapState, mapDispatch)(SupplyListForUserComponent);
