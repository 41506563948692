import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ESearchParams } from 'common/const/searchParams.enum';
import { ERoute } from 'common/const/route.enum';
import { EStorageKey } from 'common/const/axios.enum';
import { LIST_LIMIT_0 } from 'common/config';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { CatalogProvider } from 'common/hooks/useCatalogContext';
import { GoodsProvider } from 'common/hooks/useGoodsContext';
import { getStorageItem } from 'common/helpers/axios.helper';
import { RootDispatch, RootState } from 'app/store';
import { CatalogSearch } from 'entities/Catalog/components/CatalogSearch';
import { CatalogGoodsListFilter } from 'entities/Catalog/components/CatalogGoodsListFilter';
import { CatalogSelector } from 'entities/Catalog/components/CatalogSelector';
import { getCurrentCatalog } from 'entities/Catalog/Catalog.helper';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';
import { CatalogMainPage } from 'entities/Catalog/components/CatalogMainPage';
import { CatalogCategoryPage } from 'entities/Catalog/components/CatalogCategoryPage';
import { CatalogSearchPage } from 'entities/Catalog/components/CatalogSearchPage';
import { CatalogCategoryListSkeleton } from 'entities/Catalog/components/CatalogCategoryListSkeleton';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CatalogComponent: FC<AllType> = ({ catalogList, catalogListLoading, getCatalogList }) => {
  const { catalogId, categoryId } = useParams();
  const navigate = useNavigate();
  const { getSearchParam } = useSearchParamsHook();

  const search = getSearchParam(ESearchParams.Search);
  const currentCatalog = getCurrentCatalog(catalogList, catalogId);

  const getPage = () => {
    if (currentCatalog) {
      if (search !== null) {
        return (
          <CatalogSearchPage
            catalogId={currentCatalog?.id}
            catalogName={currentCatalog?.name}
            catalogListLoading={catalogListLoading}
          />
        );
      }

      if (categoryId) {
        return (
          <CatalogCategoryPage
            catalogId={currentCatalog?.id}
            catalogName={currentCatalog?.name}
            categoryId={Number(categoryId)}
            catalogListLoading={catalogListLoading}
          />
        );
      }

      return (
        <CatalogMainPage
          catalogId={Number(catalogId)}
          catalogName={currentCatalog?.name}
          catalogListLoading={catalogListLoading}
        />
      );
    }

    return <CatalogCategoryListSkeleton />;
  };

  useEffect(() => {
    const fetch = async () => {
      const userCatalogId = getStorageItem<number>(EStorageKey.UserCatalogId);
      const response = await getCatalogList({ limit: LIST_LIMIT_0 });
      const defaultCatalogId = response?.[0]?.id;

      if (!catalogId) {
        if (userCatalogId) {
          navigate(`${ERoute.Catalog}/${userCatalogId}`, { replace: true });
        } else if (defaultCatalogId) {
          navigate(`${ERoute.Catalog}/${defaultCatalogId}`, { replace: true });
        }
      }
    };

    fetch();
  }, []);

  return (
    <CatalogProvider>
      <GoodsProvider>
        <div className="redesign catalog">
          <div className="catalog__header">
            <CatalogSelector catalogId={catalogId} catalogList={catalogList} loading={catalogListLoading} />

            <CatalogSearch catalogId={currentCatalog?.id} catalogName={currentCatalog?.name} />

            <CatalogGoodsListFilter />
          </div>

          {getPage()}

          <GoodsCard />
        </div>
      </GoodsProvider>
    </CatalogProvider>
  );
};

const mapState = (state: RootState) => ({
  catalogList: state.catalogListState.data,
  catalogListLoading: state.catalogListState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCatalogList: dispatch.catalogListState.getCatalogList,
});

export const Catalog = connect(mapState, mapDispatch)(CatalogComponent);
