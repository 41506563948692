export enum EDateFormat {
  FullDateSlashSeparator = 'DD/MM/YYYY',
  FullDateDotSeparator = 'DD.MM.YYYY',
  FullDateReverse = 'YYYY-MM-DD',
  FullDate = 'DD MMMM YYYY',
  ShortDate = 'DD MMMM',
  FullDateWithTime = 'DD.MM.YYYY HH:mm',
  FullDateShortMonth = 'DD MMM YYYY',
  FullDateWithShortDay = 'DD.MM.YYYY (dd)',
  Group = 'dddd, DD MMMM',
}
