/* eslint-disable sonarjs/no-duplicate-string */
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import { ESupplyCorrectionLabel, ESupplyCorrectionValue, ESupplyStatus, ESupplyStatusLabel } from 'common/const/supply.enum';

export const supplyCorrectionOptions = [
  {
    label: ESupplyCorrectionLabel.price,
    value: ESupplyCorrectionValue.Price,
  },
  {
    label: ESupplyCorrectionLabel.date,
    value: ESupplyCorrectionValue.Date,
  },
];

export const supplyListFilterStatusOptionsForUser = [
  {
    label: 'Все статусы',
    value: DEFAULT_EMPTY_VALUE,
  },
  {
    label: ESupplyStatusLabel.created,
    value: ESupplyStatus.Created,
  },
  {
    label: ESupplyStatusLabel.processing,
    value: ESupplyStatus.Processing,
  },
  {
    label: ESupplyStatusLabel.pending,
    value: ESupplyStatus.Pending,
  },
  {
    label: ESupplyStatusLabel.approved,
    value: ESupplyStatus.Approved,
  },
  {
    label: ESupplyStatusLabel.delivery,
    value: ESupplyStatus.Delivery,
  },
  {
    label: ESupplyStatusLabel.delivered,
    value: ESupplyStatus.Delivered,
  },
  {
    label: ESupplyStatusLabel.cancelled,
    value: ESupplyStatus.Cancelled,
  },
];
