import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { ERoute } from 'common/const/route.enum';

export const RestorePasswordConfirmation: React.FC = () => {
  const location = useLocation();
  const email = location.state?.email;

  if (!email) {
    return <Navigate to={ERoute.Login} />;
  }

  return (
    <div className="auth">
      <p>{`Вы получите письмо со ссылкой для восстановления пароля на ${email}`}</p>
    </div>
  );
};
