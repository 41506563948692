import { createModel } from '@rematch/core';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { IRootModel } from 'app/store';
import { IModalCollection } from 'entities/Modal/Modal.models';

const commonState = { open: false };

export const modalCollection = createModel<IRootModel>()({
  state: {
    [EModalCollectionKey.CreateAccount]: commonState,
    [EModalCollectionKey.BlockAccount]: commonState,
    [EModalCollectionKey.DeleteAccount]: commonState,
    [EModalCollectionKey.ChangePassword]: commonState,
    [EModalCollectionKey.CreateSubdivision]: commonState,
    [EModalCollectionKey.DeleteSubdivision]: commonState,
    [EModalCollectionKey.ChangeSubdivisionLimit]: commonState,
    [EModalCollectionKey.SelectUser]: commonState,
    [EModalCollectionKey.InviteUser]: commonState,
    [EModalCollectionKey.ChangeSubdivisionUser]: commonState,
    [EModalCollectionKey.ExcludeSubdivisionUser]: commonState,
    [EModalCollectionKey.CreateSubdivisionAddress]: commonState,
    [EModalCollectionKey.DeleteSubdivisionAddress]: commonState,
    [EModalCollectionKey.CreateSubdivisionWorkspace]: commonState,
    [EModalCollectionKey.DeleteSubdivisionWorkspace]: commonState,
    [EModalCollectionKey.SelectSubdivision]: commonState,
    [EModalCollectionKey.SelectWorkspace]: commonState,
    [EModalCollectionKey.DeleteUser]: commonState,
    [EModalCollectionKey.SelectManager]: commonState,
    [EModalCollectionKey.SelectTemplate]: commonState,
    [EModalCollectionKey.PositionUnsaved]: commonState,
    [EModalCollectionKey.DeleteWorkspacePosition]: commonState,
    [EModalCollectionKey.RepeatRequest]: commonState,
    [EModalCollectionKey.ChangeSubdivisionCode]: commonState,
    [EModalCollectionKey.AddContract]: commonState,
    [EModalCollectionKey.LegalEntity]: commonState,
    [EModalCollectionKey.CreateDiscount]: commonState,
    [EModalCollectionKey.StockForm]: commonState,
    [EModalCollectionKey.AddShippingStock]: commonState,
  } as IModalCollection,
  reducers: {
    showModal: (state, payload: EModalCollectionKey) => ({ ...state, [payload]: { open: true } }),
    hideModal: (state, payload: EModalCollectionKey) => ({ ...state, [payload]: { open: false } }),
  },
});
