import React, { FC, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { IFormValues } from 'common/models';
import { debounce } from 'common/helpers/common.helper';
import { showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as EditIcon } from 'app/assets/images/edit.svg';
import { IAccountUpdatePayload } from 'entities/Account/Account.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const IntegrationSettingComponent: FC<AllType> = ({ currentUser, accountState, getAccountById, updateAccount }) => {
  const { data: account } = accountState;

  const [form] = useForm();

  const accountId = currentUser?.accountId;

  const debouncedUpdateAccount = debounce((payload: IAccountUpdatePayload) => {
    showLoadingMessage();
    updateAccount(payload);
  });

  const onFormValueChange = (values: IFormValues) => {
    if (account) {
      debouncedUpdateAccount({ id: account.id, ...values, onSuccess: showSuccessMessage });
    }
  };

  useEffect(() => {
    form.setFieldsValue({ feedUrl: account?.feedUrl });
  }, [account]);

  const { contentLoading } = useContentLoader(async () => {
    if (accountId) {
      await getAccountById(accountId);
    }
  }, [accountId]);

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <div className="integration-settings">
      <div className="integration-settings__title">Настройки интеграции</div>

      <Form form={form} onValuesChange={onFormValueChange}>
        <Form.Item className="integration-settings__table-item" label="Фид" name="feedUrl" colon={false}>
          <Input placeholder="Вставьте ссылку на фид" suffix={<EditIcon />} />
        </Form.Item>
      </Form>
    </div>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  accountState: state.accountState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAccountById: dispatch.accountState.getAccountById,
  updateAccount: dispatch.accountState.updateAccount,
});

export const IntegrationSetting = connect(mapState, mapDispatch)(IntegrationSettingComponent);
