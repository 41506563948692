import React, { useEffect } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { RootDispatch, RootState } from 'app/store';
import { CreateWorkspaceModal } from 'entities/Modal/components/CreateWorkspaceModal';
import { IWorkspace, IWorkspaceCreatePayload } from 'entities/Workspaces/Workspaces.models';

interface IComponentProps {
  subdivisionId?: number;
  isCopy: boolean;
  workspace: IWorkspace | null;
  setIsCopy: (isCopy: boolean) => void;
  setWorkspace: (workspace: IWorkspace | null) => void;
  updateSubdivisionWorkspaceCollection: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CreateSubdivisionWorkspaceComponent: React.FC<AllType> = (props) => {
  const {
    subdivisionId,
    isCopy,
    workspace,
    workspaceState,
    createSubdivisionWorkspaceModalParams,
    setIsCopy,
    setWorkspace,
    updateSubdivisionWorkspaceCollection,
    createWorkspace,
    copyWorkspace,
    setError,
    showModal,
    hideModal,
  } = props;
  const { loading, error } = workspaceState;
  const { open } = createSubdivisionWorkspaceModalParams;

  const [form] = useForm();

  const onCancelClick = () => {
    setIsCopy(false);
    setWorkspace(null);
    setError(null);
    form.resetFields();
    hideModal(EModalCollectionKey.CreateSubdivisionWorkspace);
  };

  const onSubmit = (values: IWorkspaceCreatePayload) => {
    setError(null);

    if (isCopy && workspace) {
      copyWorkspace({
        name: values.name,
        subdivisionId: 0,
        userId: 0,
        workspaceId: workspace.id,
        onSuccess: () => {
          setIsCopy(false);
          setWorkspace(null);
          form.resetFields();
          hideModal(EModalCollectionKey.CreateSubdivisionWorkspace);
          updateSubdivisionWorkspaceCollection();
        },
      });
      return;
    }

    if (subdivisionId) {
      createWorkspace({
        name: values.name,
        subdivisionId,
        onSuccess: () => {
          form.resetFields();
          hideModal(EModalCollectionKey.CreateSubdivisionWorkspace);
          updateSubdivisionWorkspaceCollection();
        },
      });
    }
  };

  useEffect(() => {
    if (isCopy && workspace) {
      form.setFieldsValue({
        name: `${workspace.name} - копия`,
      });
    }
  }, [isCopy, workspace]);

  return (
    <>
      <div>
        <Button className="btn btn-primary" onClick={() => showModal(EModalCollectionKey.CreateSubdivisionWorkspace)}>
          Добавить карту рабочего места
        </Button>
      </div>

      <CreateWorkspaceModal
        open={open}
        form={form}
        loading={loading}
        error={error}
        onSubmit={onSubmit}
        onCancelClick={onCancelClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  workspaceState: state.workspaceState,
  createSubdivisionWorkspaceModalParams: state.modalCollection.createSubdivisionWorkspaceModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  createWorkspace: dispatch.workspaceState.createWorkspace,
  copyWorkspace: dispatch.workspaceState.copyWorkspace,
  setError: dispatch.workspaceState.setError,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const CreateSubdivisionWorkspace = connect(mapState, mapDispatch)(CreateSubdivisionWorkspaceComponent);
