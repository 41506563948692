import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useNavigate } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { ERequestsMenuTab } from 'common/const/request.enum';
import { ENeedCardMenuItem, ENeedStatus } from 'common/const/need.enum';
import { ERoute } from 'common/const/route.enum';
import { LIST_LIMIT_5, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_20 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { NeedListEmpty } from 'entities/Need/components/NeedListEmpty';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import { getSelectedNeedListTotalAmount, renderNeedListRecords } from 'entities/Need/Need.helper';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  renderFilters: (payload: INeedListPayload, setPayload: (value: INeedListPayload) => void) => ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CreatedNeedListForManagerComponent: React.FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    renderFilters,
    // state
    needList,
    needListCount,
    needListLoading,
    statisticsRequestsCount = 0,
    // dispatch
    setNeedList,
    filterNeedList,
    getNeedSubdivisionListForManager,
    getNeedUserList,
    getNeedList,
    getNeedListPart,
    changeNeedStatus,
    getNeedById,
    updateStatisticsRequestsCount,
  } = props;

  const [selectedNeedListCount, setSelectedNeedListCount] = useState<number>(0);
  const [selectedNeedListTotalAmount, setSelectedNeedListTotalAmount] = useState<number>(0);
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState<boolean>(false);
  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const [payload, setPayload] = useState<INeedListPayload>({
    statuses: [ENeedStatus.Created],
    asManager: true,
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const navigate = useNavigate();
  const {
    page,
    setPage,
    setNeedCardCurrentMenuTab,
    openNeedCard,
    setOpenNeedCard,
    checkAll,
    fetchLoading,
    setFetchLoading,
    indeterminate,
    selectedNeedListIds,
    selectedNeedList,
    setSelectedNeedList,
  } = useNeedContext();

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const newSelectedNeedListTotalAmount = getSelectedNeedListTotalAmount(needList);

      setSelectedNeedList(needList);
      setSelectedNeedListCount(needList.length);
      setSelectedNeedListTotalAmount(newSelectedNeedListTotalAmount);
    } else {
      setSelectedNeedList([]);
    }
  };

  const onNeedSelectionChange = (id: number, checked: boolean) => {
    const need = needList.find((item) => item.id === id) as INeed;
    const newSelectedNeedList = checked ? [...selectedNeedList, need] : selectedNeedList.filter((need) => need.id !== id);
    const newSelectedNeedListTotalAmount = getSelectedNeedListTotalAmount(newSelectedNeedList);

    setSelectedNeedList(newSelectedNeedList);
    setSelectedNeedListCount(newSelectedNeedList.length);
    setSelectedNeedListTotalAmount(newSelectedNeedListTotalAmount);
  };

  const onChangeStatusSuccess = (ids: number[]) => {
    const filteredNeedList = needList.filter((need) => !ids.includes(need.id));
    const filteredSelectedNeedList = selectedNeedList.filter((need) => !ids.includes(need.id));

    filterNeedList(ids);
    setSelectedNeedList(filteredSelectedNeedList);
    updateStatisticsRequestsCount(statisticsRequestsCount - ids.length);

    if (openNeedCard) {
      setSelectedNeedListCount(filteredSelectedNeedList.length);
      setSelectedNeedListTotalAmount(getSelectedNeedListTotalAmount(filteredSelectedNeedList));
    }

    if (filteredNeedList.length <= LIST_LIMIT_5 && needListCount > filteredNeedList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getNeedList(newPayload);
    }
  };

  const onAcceptToWorkClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Pending,
      onSuccess: () => {
        setOpenSuccessDrawer(true);
        onChangeStatusSuccess(selectedNeedListIds);
      },
    });
  };

  const onRejectClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Cancelled,
      onSuccess: () => {
        setOpenWarningDrawer(false);
        showSuccessMessage(`Заявки (${selectedNeedListIds.length}) отменены.`);
        onChangeStatusSuccess(selectedNeedListIds);
      },
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedNeedList([]);
      setNeedList([]);
      await getNeedSubdivisionListForManager({ statuses: payload.statuses });
      await getNeedUserList({ statuses: payload.statuses });
      await getNeedList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!needListLoading && needList.length < needListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getNeedListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}
        {renderFilters(payload, setPayload)}

        {!!needList.length && (
          <div className="need-list__actions">
            <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
              Выбрать заявки
            </Checkbox>

            {!!selectedNeedList.length && (
              <>
                <Button className="button-s primary" onClick={onAcceptToWorkClick}>
                  {`Взять в работу: ${priceFormatter(selectedNeedListTotalAmount)}`}
                </Button>

                <Button className="button-s danger" onClick={() => setOpenWarningDrawer(true)}>
                  Отклонить выбранные
                </Button>
              </>
            )}
          </div>
        )}

        <Table
          className={`
            table-hover need-list__table
            ${!!needList.length && needList.length === needListCount && 'need-list__table-end-of-list'}
            ${!needList.length && 'need-list__table-empty'}
          `}
          dataSource={needList.map((need) => ({ ...need, key: need.id }))}
          columns={renderNeedListRecords(ERequestsMenuTab.Created)}
          rowSelection={{
            selectedRowKeys: selectedNeedListIds,
            onSelect: ({ id }, checked) => onNeedSelectionChange(id, checked),
            columnWidth: 40,
          }}
          onRow={({ id }) => ({
            onClick: () => {
              getNeedById({
                id,
                onSuccess: () => {
                  setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                  setOpenNeedCard(true);
                },
              });
            },
          })}
          showHeader={false}
          pagination={false}
          locale={{ emptyText: <NeedListEmpty open={!(needListLoading || fetchLoading) && !needList.length} /> }}
          loading={{ spinning: needListLoading || fetchLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>

      <NeedCard onChangeStatusSuccess={onChangeStatusSuccess} />

      <GoodsCard />

      <SuccessDrawer
        open={openSuccessDrawer}
        subtitle="Готово!"
        content={`Вы взяли в работу ${selectedNeedListCount} новых заявок на общую сумму ${priceFormatter(
          selectedNeedListTotalAmount,
        )}`}
        btnTitle="К заявкам в работе"
        onClose={() => setOpenSuccessDrawer(false)}
        onConfirm={() => navigate(`${ERoute.RequestsForManager}?tab=${ERequestsMenuTab.Pending}`)}
      />

      <WarningDrawer
        open={openWarningDrawer}
        content={`Вы уверены, что хотите завершить выбранные заявки (${selectedNeedList.length})?`}
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Завершить все"
        loading={needListLoading}
        onClose={() => setOpenWarningDrawer(false)}
        onConfirm={onRejectClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  needList: state.needListState.data,
  needListCount: state.needListState.count,
  needListLoading: state.needListState.loading,
  statisticsRequestsCount: state.statisticsState.data.requestsCount,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setNeedList: dispatch.needListState.setNeedList,
  filterNeedList: dispatch.needListState.filterNeedList,
  getNeedSubdivisionListForManager: dispatch.needListState.getNeedSubdivisionListForManager,
  getNeedUserList: dispatch.needListState.getNeedUserList,
  getNeedList: dispatch.needListState.getNeedList,
  getNeedListPart: dispatch.needListState.getNeedListPart,
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  getNeedById: dispatch.needState.getNeedById,
  updateStatisticsRequestsCount: dispatch.statisticsState.updateStatisticsRequestsCount,
});

export const CreatedNeedListForManager = connect(mapState, mapDispatch)(CreatedNeedListForManagerComponent);
