export enum ENeedStatus {
  Created = 'created',
  Pending = 'pending',
  Approved = 'approved',
  Cancelled = 'cancelled',
}

export enum ENeedStatusLabel {
  created = 'Заявка отправлена',
  pending = 'В работе',
  approved = 'Обработана',
  cancelled = 'Отменена',
}

export enum ENeedHistoryItemType {
  CreateSupply = 'createSupply',
  CreateSupplyNeed = 'createSupplyNeed',
  ChangeSupplyStatus = 'changeSupplyStatus',
  ChangeSupplyNeedStatus = 'changeSupplyNeedStatus',
  CreateSupplyCorrection = 'createSupplyCorrection',
  ChangeSupplyPrice = 'changeSupplyPrice',
}

export enum ENeedCardMenuItem {
  Info = 'info',
  Supplies = 'supplies',
  History = 'history',
}

export enum ENeedCardMenuItemLabel {
  info = 'Общая информация',
  supplies = 'График поставок',
  updates = 'Обновления',
}
