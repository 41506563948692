import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Navigate } from 'react-router-dom';
import { EFormFieldMessage } from 'common/const/form.enum';
import { ERoute } from 'common/const/route.enum';
import { EUserRole } from 'common/const/user.enum';
import { FormInputPassword } from 'common/components/Form/FormInputPassword';
import { rules } from 'common/helpers/form.helper';
import { ReactComponent as ProfileIcon } from 'app/assets/images/profile.svg';
import { RootDispatch, RootState } from 'app/store';
import { AuthCard } from 'entities/Auth/components/AuthCard';
import { IAuthLoginPayload } from 'entities/Auth/Auth.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const LoginComponent: React.FC<AllType> = ({ authState, addAuth, setAuthError }) => {
  const { data, loading, error } = authState;
  const [form] = useForm();
  const roles = data?.access.roles;

  const onSubmit = (values: IAuthLoginPayload) => {
    setAuthError(null);

    addAuth(values);
  };

  if (data) {
    return roles?.includes(EUserRole.SuperAdmin) ? (
      <Navigate to={ERoute.AccountList} replace />
    ) : (
      <Navigate to={ERoute.Profile} replace />
    );
  }

  return (
    <div className="auth">
      <AuthCard
        form={form}
        loading={loading}
        body={
          <>
            <Form.Item
              label="Логин"
              name="email"
              rules={[rules.required(), rules.type('email', EFormFieldMessage.InvalidEmailFormat)]}
            >
              <Input placeholder="Введите адрес электронной почты" prefix={<ProfileIcon />} />
            </Form.Item>

            <FormInputPassword label="Пароль" name="password" placeholder="Введите пароль" rules={[rules.required()]} />

            <div>
              Забыли пароль? Восстановите по <a href={ERoute.SendRestorePasswordEmail}>ссылке.</a>
            </div>

            {!!error && (
              <div className="auth-card__error-block">
                <span className="auth-card__error-block-item">{error}</span>
              </div>
            )}
          </>
        }
        footer={
          <Button className="btn btn-primary" htmlType="submit">
            Войти
          </Button>
        }
        onSubmit={onSubmit}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  addAuth: dispatch.authState.addAuth,
  setAuthError: dispatch.authState.setAuthError,
});

export const Login = connect(mapState, mapDispatch)(LoginComponent);
