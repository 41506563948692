import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { uploadExcelFile } from 'common/helpers/file';
import { IRootModel } from 'app/store';
import { needTransport } from 'entities/Need/Need.transport';
import {
  INeed,
  INeedChangeStatusPayload,
  INeedHistoryItem,
  INeedHistoryListPayload,
  INeedHistoryListState,
  INeedListPayload,
  INeedListState,
  INeedPayload,
  INeedState,
  INeedSubdivisionListParams,
  INeedUpdatePayload,
  INeedUserListParams,
} from 'entities/Need/Need.models';
import { updateNeedListState } from 'entities/Need/Need.helper';
import { ISupply, ISupplyListPayload } from 'entities/Supply/Supply.models';
import { supplyTransport } from 'entities/Supply/Supply.transport';

export const needListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
    userList: [],
    userListLoading: true,
    subdivisionList: [],
    subdivisionListLoading: true,
  } as INeedListState,
  reducers: {
    setNeedList: updateNeedListState.setNeedList,
    setNeedListPart: updateNeedListState.setNeedListPart,
    setNeedListCount: updateNeedListState.setNeedListCount,
    setNeedListLoading: updateNeedListState.setNeedListLoading,
    setNeedUserList: updateNeedListState.setNeedUserList,
    setNeedUserListLoading: updateNeedListState.setNeedUserListLoading,
    setNeedSubdivisionList: updateNeedListState.setNeedSubdivisionList,
    setNeedSubdivisionListLoading: updateNeedListState.setNeedSubdivisionListLoading,
    filterNeedList: updateNeedListState.filterNeedList,
    updateNeedList: updateNeedListState.updateNeedList,
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getNeedList(payload: INeedListPayload) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.getNeedList(payload);
        dispatch.needListState.setNeedList(response.data);
        dispatch.needListState.setNeedListCount(response.count);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async getNeedListPart(payload: INeedListPayload) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.getNeedList(payload);
        dispatch.needListState.setNeedListPart(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async getNeedUserList(params: INeedUserListParams) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.getNeedUserList(params);
        dispatch.needListState.setNeedUserList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async changeNeedStatus(payload: INeedChangeStatusPayload) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.changeNeedStatus(payload);
        dispatch.needListState.updateNeedList(response.data);
        dispatch.needListState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async uploadNeedExcel(payload: INeedListPayload) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.uploadNeedExcel(payload);
        uploadExcelFile(response, `supply_need_list_${Date.now()}`);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async getNeedSubdivisionListForManager(params: INeedSubdivisionListParams) {
      dispatch.needListState.setNeedListLoading(true);

      try {
        const response = await needTransport.getNeedSubdivisionListForManager(params);
        dispatch.needListState.setNeedSubdivisionList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedListLoading(false);
      }
    },
    async getNeedSubdivisionListForSeller(buyerId?: number) {
      dispatch.needListState.setNeedSubdivisionListLoading(true);

      try {
        const response = await needTransport.getNeedSubdivisionListForSeller(buyerId);
        dispatch.needListState.setNeedSubdivisionList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needListState.setNeedSubdivisionListLoading(false);
      }
    },
  }),
});

export const needState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    supplyList: [],
    historyList: [],
  } as INeedState,
  reducers: {
    setNeed: (state, payload: INeed) => ({ ...state, data: payload }),
    setNeedLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setNeedSupplyList: (state, payload: ISupply[]) => ({ ...state, supplyList: payload }),
    setNeedHistoryList: (state, payload: INeedHistoryItem[]) => ({ ...state, historyList: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getNeedById(payload: INeedPayload) {
      dispatch.needState.setNeedLoading(true);

      try {
        const response = await needTransport.getNeedById(payload.id);
        dispatch.needState.setNeed(response);
        dispatch.needState.onSuccess(payload);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.needState.setNeedLoading(false);
      }
    },
    async updateNeed(payload: INeedUpdatePayload) {
      dispatch.needState.setNeedLoading(true);

      try {
        const response = await needTransport.updateNeed(payload);
        dispatch.needState.setNeed(response.data[0]);
        dispatch.needListState.updateNeedList(response.data);
        dispatch.needListState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needState.setNeedLoading(false);
      }
    },
    async getNeedSupplyList(payload: ISupplyListPayload) {
      dispatch.needState.setNeedLoading(true);

      try {
        const response = await supplyTransport.getSupplyList(payload);
        dispatch.needState.setNeedSupplyList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needState.setNeedLoading(false);
      }
    },
    async getNeedHistoryList(payload: INeedHistoryListPayload) {
      dispatch.needState.setNeedLoading(true);

      try {
        const response = await needTransport.getNeedHistoryList(payload);
        dispatch.needState.setNeedHistoryList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needState.setNeedLoading(false);
      }
    },
  }),
});

// TODO Remove
export const needHistoryListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as INeedHistoryListState,
  reducers: {
    setNeedHistoryList: (state, payload: INeedHistoryItem[]) => ({ ...state, data: payload }),
    setNeedHistoryListCount: (state, payload: number) => ({ ...state, count: payload }),
    setNeedHistoryListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getNeedHistoryList(payload: INeedHistoryListPayload) {
      dispatch.needHistoryListState.setNeedHistoryListLoading(true);

      try {
        const response = await needTransport.getNeedHistoryList(payload);
        dispatch.needHistoryListState.setNeedHistoryList(response.data);
        dispatch.needHistoryListState.setNeedHistoryListCount(response.count);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.needHistoryListState.setNeedHistoryListLoading(false);
      }
    },
  }),
});
