import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { NameLabel } from 'common/components/NameLabel';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { ERoute } from 'common/const/route.enum';
import { ECreateWorkspaceDrawerMode } from 'common/const/workspace.enum';
import { stringToNumber } from 'common/helpers/common.helper';
import { showMessage } from 'common/helpers/message.helper';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { ReactComponent as InfoRedIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { getUserName } from 'entities/User/User.helper';
import { WorkspacePositionGroup } from 'entities/Workspace/components/WorkspacePositionGroup';
import { WorkspaceTools } from 'entities/Workspace/components/WorkspaceTools';
import { CreateWorkspaceDrawer } from 'entities/Workspace/components/CreateWorkspaceDrawer';
import { EmptyWorkspace } from 'entities/Workspace/components/EmptyWorkspace';
import { IWorkspaceCatalogCategory } from 'entities/Workspace/Workspace.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    subdivisionList,
    userList,
    auth,
    groupedWorkspace,
    groupedWorkspaceLoading,
    workspaceLoading,
    // dispatch
    getSubdivisionList,
    getUserList,
    getGroupedWorkspaceById,
    deleteWorkspace,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [openCreateWorkspaceDrawer, setOpenCreateWorkspaceDrawer] = useState<boolean>(false);
  const [openDeleteWorkspaceDrawer, setOpenDeleteWorkspaceDrawer] = useState<boolean>(false);
  const [createWorkspaceDrawerMode, setCreateWorkspaceDrawerMode] = useState<ECreateWorkspaceDrawerMode>(
    ECreateWorkspaceDrawerMode.Create,
  );
  const [selectedCategory, setSelectedCategory] = useState<IWorkspaceCatalogCategory | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const accountId = auth?.access.accountId;
  const workspaceId = id ? stringToNumber(id) : null;
  const filteredGroupedWorkspaceCategories = groupedWorkspace?.categories
    .map((category) => {
      return {
        ...category,
        positions: category.positions.filter((position) => {
          return searchValue.length ? position.categoryName.toLowerCase().includes(searchValue.toLowerCase()) : true;
        }),
      };
    })
    .filter((category) => !!category.positions.length)
    .filter((category) => {
      return selectedCategory ? category.categoryId === selectedCategory.categoryId : true;
    });

  const onCopyWorkspaceClick = () => {
    setCreateWorkspaceDrawerMode(ECreateWorkspaceDrawerMode.Copy);
    setOpenCreateWorkspaceDrawer(true);
  };

  const onEditWorkspaceClick = () => {
    setCreateWorkspaceDrawerMode(ECreateWorkspaceDrawerMode.Edit);
    setOpenCreateWorkspaceDrawer(true);
  };

  const onDeleteWorkspaceClick = () => {
    setOpenDeleteWorkspaceDrawer(true);
  };

  const onDeleteWorkspaceConfirm = () => {
    if (groupedWorkspace?.workspace) {
      deleteWorkspace({
        id: groupedWorkspace.workspace.id,
        onSuccess: () => {
          setOpenDeleteWorkspaceDrawer(false);
          showMessage({ content: 'Карта оснащения удалена из подразделения.', icon: <BinIcon className="icon-bin-dark-grey" /> });
          navigate(ERoute.WorkspaceList, { replace: true });
        },
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (workspaceId) {
        await getGroupedWorkspaceById(workspaceId);
        await getSubdivisionList({ limit: LIST_LIMIT_0 });
        await getUserList({ accountId, limit: LIST_LIMIT_0 });
      }

      setFetchLoading(false);
    };

    fetch();
  }, [workspaceId]);

  return (
    <Spin wrapperClassName="workspace__spin" spinning={fetchLoading} indicator={<SpinIndicator />}>
      <div className="redesign workspace">
        <div className="workspace__content">
          <div className="workspace__search">
            <Button
              className="button-circle secondary"
              icon={<ArrowLeftLongIcon className="arrow-left-long" />}
              onClick={() => navigate(ERoute.WorkspaceList)}
            />

            <Input
              placeholder="Поиск по карте оснащения"
              allowClear={{ clearIcon: <CloseIcon className="icon-close-dark-grey" /> }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <Button className="button-circle primary" icon={<SearchIcon />} onClick={() => setSearchValue(search)} />
          </div>

          <InfiniteScrollContainer>
            <Spin wrapperClassName="workspace__spin" spinning={groupedWorkspaceLoading} indicator={<SpinIndicator />}>
              <div className="workspace__content-container">
                <div className="text-h1 workspace__title">{groupedWorkspace?.workspace.name}</div>

                <div className="workspace__subtitle">
                  {groupedWorkspace?.workspace.user ? (
                    <NameLabel
                      name={getUserName(groupedWorkspace?.workspace.user.firstName, groupedWorkspace?.workspace.user.lastName)}
                      icon={<UserIcon className="icon-user-bright-green" />}
                      iconClassName="user"
                    />
                  ) : (
                    <div className="workspace__subtitle-no-user">
                      <div className="workspace__subtitle-no-user-icon">
                        <UserIcon className="icon-user-dark-grey" />
                      </div>

                      <span className="text-h4-item-name color-dark-grey">Сотрудник не назначен</span>

                      <InfoRedIcon className="icon-info-red" />
                    </div>
                  )}

                  <NameLabel
                    name={groupedWorkspace?.workspace.subdivision?.name || ''}
                    icon={<AffiliateIcon className="icon-affiliate-bright-green" />}
                  />
                </div>

                {filteredGroupedWorkspaceCategories?.map((group) => {
                  return <WorkspacePositionGroup key={group.categoryId} group={group} />;
                })}

                <EmptyWorkspace
                  open={!groupedWorkspaceLoading && !filteredGroupedWorkspaceCategories?.length}
                  // eslint-disable-next-line
                  onClick={() => {}}
                />
              </div>
            </Spin>
          </InfiniteScrollContainer>
        </div>

        <WorkspaceTools
          catalog={groupedWorkspace?.catalog}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          onCopyWorkspaceClick={onCopyWorkspaceClick}
          onEditWorkspaceClick={onEditWorkspaceClick}
          onDeleteWorkspaceClick={onDeleteWorkspaceClick}
        />
      </div>

      <CreateWorkspaceDrawer
        open={openCreateWorkspaceDrawer}
        mode={createWorkspaceDrawerMode}
        loading={workspaceLoading}
        workspace={groupedWorkspace?.workspace}
        subdivisionList={subdivisionList}
        userList={userList}
        onOpenChange={setOpenCreateWorkspaceDrawer}
        onModeChange={setCreateWorkspaceDrawerMode}
      />

      <WarningDrawer
        open={openDeleteWorkspaceDrawer}
        content="Вы уверены, что хотите удалить карту оснащения?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={workspaceLoading}
        onClose={() => setOpenDeleteWorkspaceDrawer(false)}
        onConfirm={onDeleteWorkspaceConfirm}
      />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.subdivisionListState.data,
  userList: state.userListState.data,
  auth: state.authState.data,
  groupedWorkspace: state.groupedWorkspaceState.data,
  groupedWorkspaceLoading: state.groupedWorkspaceState.loading,
  workspaceLoading: state.workspaceState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  getUserList: dispatch.userListState.getUserList,
  getGroupedWorkspaceById: dispatch.groupedWorkspaceState.getGroupedWorkspaceById,
  deleteWorkspace: dispatch.workspaceState.deleteWorkspace,
});

export const Workspace = connect(mapState, mapDispatch)(Component);
