import React, { FC, useState } from 'react';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { EMessage } from 'common/const/message.enum';
import { ERoute } from 'common/const/route.enum';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { RootDispatch, RootState } from 'app/store';
import { renderCatalogListSettingsRecords } from 'entities/Settings/Settings.helper';
import { CatalogModal } from 'entities/Modal/components/CatalogModal';
import { ICatalog } from 'entities/Catalog/Catalog.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CatalogListSettingsComponent: FC<AllType> = (props) => {
  const { catalogListState, catalogState, getCatalogList, createCatalog, updateCatalog, deleteCatalog } = props;

  const { data: catalogList } = catalogListState;
  const { loading: catalogLoading } = catalogState;

  const [openCatalogModal, setOpenCatalogModal] = useState<boolean>(false);
  const [catalogName, setCatalogName] = useState<string>('');
  const [catalogId, setCatalogId] = useState<number | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();

  const dataSource = toDataSourceMapper(catalogList);
  const columns = renderCatalogListSettingsRecords(handleUpdateCatalogClick, handleDeleteCatalogClick);

  const toggleOpenCatalogModal = () => setOpenCatalogModal((prev) => !prev);

  const onCatalogModalCancelClick = () => {
    setIsEdit(false);
    setCatalogName('');
    setCatalogId(null);
    toggleOpenCatalogModal();
  };

  const onCatalogModalAddClick = () => {
    createCatalog({ name: catalogName, onSuccess: onCatalogModalCancelClick });
  };

  function handleUpdateCatalogClick(catalog: ICatalog) {
    setIsEdit(true);
    setCatalogName(catalog.name);
    setCatalogId(catalog.id);
    toggleOpenCatalogModal();
  }

  const onCatalogModalSaveClick = () => {
    if (catalogId) {
      updateCatalog({ id: catalogId, name: catalogName, onSuccess: onCatalogModalCancelClick });
    }
  };

  function handleDeleteCatalogClick(catalogId: number) {
    deleteCatalog({ id: catalogId });
  }

  const { contentLoading } = useContentLoader(async () => {
    await getCatalogList({ limit: 0 });
  }, []);

  return (
    <div className="catalog-list-settings">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <div className="catalog-list-settings__header">
            <div className="catalog-list-settings__title">Каталоги</div>

            <Button className="btn btn-primary" icon={<PlusOutlined />} onClick={toggleOpenCatalogModal}>
              Добавить новый каталог
            </Button>
          </div>

          <Table
            className="catalog-list-settings__table"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            loading={catalogLoading}
            locale={{ emptyText: EMessage.CatalogListIsEmpty }}
            onRow={(record) => ({ onClick: () => navigate(`${ERoute.CatalogList}/${record.id}`) })}
          />
        </>
      )}

      <CatalogModal
        open={openCatalogModal}
        loading={catalogLoading}
        name={catalogName}
        confirmBtnTitle={isEdit ? 'Сохранить' : 'Добавить'}
        changeName={setCatalogName}
        onCancelClick={onCatalogModalCancelClick}
        onConfirmClick={isEdit ? onCatalogModalSaveClick : onCatalogModalAddClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  catalogListState: state.catalogListState,
  catalogState: state.catalogState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCatalogList: dispatch.catalogListState.getCatalogList,
  createCatalog: dispatch.catalogState.createCatalog,
  updateCatalog: dispatch.catalogState.updateCatalog,
  deleteCatalog: dispatch.catalogState.deleteCatalog,
});

export const CatalogListSettings = connect(mapState, mapDispatch)(CatalogListSettingsComponent);
