import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Tree } from 'antd';
import type { DirectoryTreeProps } from 'antd/es/tree';
import { ESearchParams } from 'common/const/searchParams.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { dataToTree } from 'common/helpers/tree.helper';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { SubdivisionInfo } from 'entities/Subdivisions/components/SubdivisionInfo';
import { ISubdivision } from 'entities/Subdivisions/Subdivisions.models';
import { UserCard } from 'entities/User/components/UserCard';

const { DirectoryTree } = Tree;

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SubdivisionsSettingsComponent: React.FC<AllType> = (props) => {
  const { subdivisionListState, getSubdivisionList, getLegalList } = props;

  const { data, loading } = subdivisionListState;

  const { searchParams, setSearchParam, removeSearchParam } = useSearchParamsHook();
  const treeData = dataToTree<ISubdivision>(data);
  const subdivisionId = searchParams.get(ESearchParams.SubdivisionId);
  const userId = searchParams.get(ESearchParams.UserId);
  const subdivision = data.find((item) => item.id === Number(subdivisionId));

  const onSelect: DirectoryTreeProps['onSelect'] = (keys) => {
    const subdivision = data.find((item) => item.id === keys[0]);

    if (subdivision) {
      removeSearchParam(ESearchParams.UserId);
      setSearchParam(ESearchParams.SubdivisionId, subdivision.id);
    }
  };

  useEffect(() => {
    if (data && !subdivisionId) {
      const rootSubdivision = data.find((item) => !item.parentId);

      if (rootSubdivision) {
        setSearchParam(ESearchParams.SubdivisionId, rootSubdivision.id);
      }
    }
  }, [data, subdivisionId]);

  const { contentLoading } = useContentLoader(async () => {
    await getSubdivisionList({ limit: 0, orderField: 'name', orderDirection: 'ASC' });
    await getLegalList({ limit: 0 });
  });

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <Spin spinning={loading}>
      <div className="subdivisions-settings">
        <div>
          <div className="subdivisions-settings__title">Список подразделений</div>

          <DirectoryTree
            className="tree tree__bold-title"
            treeData={treeData}
            onSelect={onSelect}
            defaultExpandAll
            switcherIcon={<ChevronIcon />}
            selectedKeys={[Number(subdivisionId)]}
          />
        </div>

        {!userId ? (
          <SubdivisionInfo subdivision={subdivision} />
        ) : (
          <UserCard
            userId={userId}
            onCloseClick={() => {
              removeSearchParam(ESearchParams.UserId);
            }}
          />
        )}
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  subdivisionListState: state.subdivisionListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  getLegalList: dispatch.legalListState.getLegalList,
});

export const SubdivisionsSettings = connect(mapState, mapDispatch)(SubdivisionsSettingsComponent);
