import React, { FC } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Image } from 'common/components/Image';
import { ERoute } from 'common/const/route.enum';
import emptyPlaceholder from 'app/assets/images/redesign/empty-state.svg';

interface IComponentProps {
  open: boolean;
}

export const NeedListEmpty: FC<IComponentProps> = ({ open }) => {
  const navigate = useNavigate();

  if (!open) {
    return null;
  }

  return (
    <div className="need-list-empty">
      <Image size={160} src={emptyPlaceholder} />

      <div className="need-list-empty-container">
        <span className="text-h2 color-black need-list-empty-title">В этом разделе нет заявок.</span>

        <span className="text-h4 color-dark-grey need-list-empty-description">
          Воспользуйтесь каталогом, чтобы выбрать и заказать нужные вам товары.
        </span>

        <Button className="button-l secondary" onClick={() => navigate(ERoute.Catalog)}>
          Перейти в каталог
        </Button>
      </div>
    </div>
  );
};
