import React, { FC, ReactNode, useMemo } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { ESupplyListMenuTab } from 'common/const/supply.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { DEFAULT_EMPTY_VALUE, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { PendingSupplyListForManager } from 'entities/Supply/components/PendingSupplyListForManager';
import { ProcessingSupplyListForManager } from 'entities/Supply/components/ProcessingSupplyListForManager';
import { CompletedSupplyListForManager } from 'entities/Supply/components/CompletedSupplyListForManager';
import { IGroupedSupplyListPayload } from 'entities/Supply/Supply.models';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    subdivisionList,
    userList,
    // dispatch
    getSupplyUserList,
    setGroupedSupplyList,
    getGroupedSupplyList,
  } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();
  const { setPage, setSelectedSupplyList } = useSupplyContext();

  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);
  const isProcessingSupplyListPage = tab === ESupplyListMenuTab.Processing;
  const isCompletedSupplyListPage = tab === ESupplyListMenuTab.Completed;
  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          { key: ESupplyListMenuTab.Pending, label: 'Требующие подтверждения' },
          { key: ESupplyListMenuTab.Processing, label: 'Ожидаемые поставки' },
          { key: ESupplyListMenuTab.Completed, label: 'Завершённые поставки' },
        ]}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Pending : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab]);

  const renderFilters = (payload: IGroupedSupplyListPayload, setPayload: (value: IGroupedSupplyListPayload) => void) => {
    return (
      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={subdivisionOptions}
          value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={async (value) => {
            const newPayload = {
              ...payload,
              subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value],
              userId: undefined,
            };

            setPage(DEFAULT_PAGINATION_PAGE);
            setSelectedSupplyList([]);
            setGroupedSupplyList([]);
            setPayload(newPayload);
            await getSupplyUserList({ statuses: newPayload.statuses, subdivisionId: newPayload.subdivisionIds?.[0] });
            await getGroupedSupplyList(newPayload);
          }}
          placeholder="Выберите подразделение"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={userOptions}
          value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={(value) => {
            const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

            setPage(DEFAULT_PAGINATION_PAGE);
            setSelectedSupplyList([]);
            setGroupedSupplyList([]);
            setPayload(newPayload);
            getGroupedSupplyList(newPayload);
          }}
          placeholder="Выберите пользователя"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload.search}
          onChange={(e) => setPayload({ ...payload, search: e.target.value })}
        />

        <Button
          className="button-circle primary"
          icon={<SearchIcon />}
          onClick={() => {
            setPage(DEFAULT_PAGINATION_PAGE);
            setSelectedSupplyList([]);
            setGroupedSupplyList([]);
            getGroupedSupplyList(payload);
          }}
        />
      </div>
    );
  };

  return isProcessingSupplyListPage ? (
    <ProcessingSupplyListForManager header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  ) : isCompletedSupplyListPage ? (
    <CompletedSupplyListForManager header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  ) : (
    <PendingSupplyListForManager header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.supplyListState.subdivisionList,
  userList: state.supplyListState.userList,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplyUserList: dispatch.supplyListState.getSupplyUserList,
  setGroupedSupplyList: dispatch.groupedSupplyListState.setGroupedSupplyList,
  getGroupedSupplyList: dispatch.groupedSupplyListState.getGroupedSupplyList,
});

export const SupplyListForManager = connect(mapState, mapDispatch)(Component);
