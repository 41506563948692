import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { EMessage } from 'common/const/message.enum';
import { ENotificationType } from 'common/const/notification.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { RootDispatch, RootState } from 'app/store';
import { CreateSubdivisionWorkspace } from 'entities/Subdivisions/components/CreateSubdivisionWorkspace';
import { renderSubdivisionWorkspacesRecords } from 'entities/Subdivisions/Subdivisions.helper';
import { Notification } from 'entities/Modal/components/Notification';
import { IWorkspace } from 'entities/Workspaces/Workspaces.models';

interface IComponentProps {
  subdivisionId?: number;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SubdivisionWorkspacesComponent: React.FC<AllType> = (props) => {
  const {
    subdivisionId,
    workspaceListState,
    workspaceState,
    deleteSubdivisionWorkspaceModalParams,
    getWorkspaceList,
    deleteWorkspace,
    showModal,
    hideModal,
  } = props;
  const { data: workspaceList, loading: workspaceListLoading } = workspaceListState;
  const { loading: workspaceLoading } = workspaceState;
  const { open } = deleteSubdivisionWorkspaceModalParams;

  const { removeSearchParam } = useSearchParamsHook();
  const [workspace, setWorkspace] = useState<IWorkspace | null>(null);
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const navigate = useNavigate();

  const dataSource = toDataSourceMapper<IWorkspace>(workspaceList);
  const columns = renderSubdivisionWorkspacesRecords(
    handleEditWorkspaceClick,
    handleCopyWorkspaceClick,
    handleDeleteWorkspaceClick,
  );

  const onBackClick = () => {
    removeSearchParam(ESearchParams.Frame);
  };

  function handleEditWorkspaceClick(workspaceId: number) {
    navigate(`/workspace/${workspaceId}`);
  }

  function handleCopyWorkspaceClick(workspace: IWorkspace) {
    setIsCopy(true);
    setWorkspace(workspace);
    showModal(EModalCollectionKey.CreateSubdivisionWorkspace);
  }

  function handleDeleteWorkspaceClick(workspace: IWorkspace) {
    setWorkspace(workspace);
    showModal(EModalCollectionKey.DeleteSubdivisionWorkspace);
  }

  const onDeleteWorkspaceModalCancelClick = () => {
    setWorkspace(null);
    hideModal(EModalCollectionKey.DeleteSubdivisionWorkspace);
  };

  const onDeleteWorkspaceModalConfirmClick = () => {
    if (workspace) {
      deleteWorkspace({
        id: workspace.id,
        onSuccess: () => {
          setWorkspace(null);
          hideModal(EModalCollectionKey.DeleteSubdivisionWorkspace);
          getWorkspaceList({ subdivisionId, limit: 0 });
        },
      });
    }
  };

  useEffect(() => {
    if (subdivisionId) {
      getWorkspaceList({ subdivisionId, limit: 0 });
    }
  }, [subdivisionId]);

  return (
    <div className="subdivision-workspaces">
      <div>
        <Button className="btn btn-default" onClick={onBackClick}>
          Назад
        </Button>
      </div>

      <div>Карты рабочих мест</div>

      <CreateSubdivisionWorkspace
        subdivisionId={subdivisionId}
        isCopy={isCopy}
        workspace={workspace}
        setIsCopy={setIsCopy}
        setWorkspace={setWorkspace}
        updateSubdivisionWorkspaceCollection={() => {
          if (subdivisionId) {
            getWorkspaceList({ subdivisionId, limit: 0 });
          }
        }}
      />

      <Table
        size="small"
        className="subdivision-workspaces__table"
        rowClassName="subdivision-workspaces__table_item"
        dataSource={dataSource}
        columns={columns}
        loading={workspaceListLoading}
        showHeader={false}
        pagination={false}
        locale={{ emptyText: EMessage.WorkspaceListIsEmpty }}
      />

      <Notification
        open={open}
        type={ENotificationType.Warning}
        description={EMessage.WorkspaceWillBeRemovedFromAccount}
        loading={workspaceLoading}
        onConfirmClick={onDeleteWorkspaceModalConfirmClick}
        onCancelClick={onDeleteWorkspaceModalCancelClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  workspaceListState: state.workspaceListState,
  workspaceState: state.workspaceState,
  deleteSubdivisionWorkspaceModalParams: state.modalCollection.deleteSubdivisionWorkspaceModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getWorkspaceList: dispatch.workspaceListState.getWorkspaceList,
  deleteWorkspace: dispatch.workspaceState.deleteWorkspace,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const SubdivisionWorkspaces = connect(mapState, mapDispatch)(SubdivisionWorkspacesComponent);
