import React, { ReactNode, useMemo } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { ERequestsMenuTab } from 'common/const/request.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { DEFAULT_EMPTY_VALUE, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ExportIcon } from 'app/assets/images/redesign/export.svg';
import { RootDispatch, RootState } from 'app/store';
import { CreatedNeedListForManager } from 'entities/Need/components/CreatedNeedListForManager';
import { PendingNeedListForManager } from 'entities/Need/components/PendingNeedListForManager';
import { INeedListPayload } from 'entities/Need/Need.models';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: React.FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    subdivisionList,
    userList,
    // dispatch
    setNeedList,
    getNeedUserList,
    getNeedList,
    uploadNeedExcel,
  } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();
  const { setPage, setSelectedNeedList } = useNeedContext();

  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);
  const isPendingNeedListPage = tab === ERequestsMenuTab.Pending;
  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          { label: 'Новые заявки', key: ERequestsMenuTab.Created },
          { label: 'Заявки в работе', key: ERequestsMenuTab.Pending },
        ]}
        selectedKeys={[tab === null ? ERequestsMenuTab.Created : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab]);

  const renderFilters = (payload: INeedListPayload, setPayload: (value: INeedListPayload) => void) => {
    return (
      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={subdivisionOptions}
          value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={async (value) => {
            const newPayload = {
              ...payload,
              subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value],
              userId: undefined,
            };

            setPage(DEFAULT_PAGINATION_PAGE);
            setSelectedNeedList([]);
            setNeedList([]);
            setPayload(newPayload);
            await getNeedUserList({ statuses: newPayload.statuses, subdivisionId: newPayload.subdivisionIds?.[0] });
            await getNeedList(newPayload);
          }}
          placeholder="Выберите подразделение"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={userOptions}
          value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={(value: number) => {
            const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

            setPage(DEFAULT_PAGINATION_PAGE);
            setSelectedNeedList([]);
            setNeedList([]);
            setPayload(newPayload);
            getNeedList(newPayload);
          }}
          placeholder="Выберите пользователя"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload.search}
          onChange={(e) => setPayload({ ...payload, search: e.target.value })}
        />

        <Button
          className="button-circle primary"
          icon={<SearchIcon />}
          onClick={() => {
            setPage(DEFAULT_PAGINATION_PAGE);
            setSelectedNeedList([]);
            setNeedList([]);
            getNeedList(payload);
          }}
        />

        <Button className="button-circle secondary" icon={<ExportIcon />} onClick={() => uploadNeedExcel(payload)} />
      </div>
    );
  };

  return isPendingNeedListPage ? (
    <PendingNeedListForManager header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  ) : (
    <CreatedNeedListForManager header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.needListState.subdivisionList,
  userList: state.needListState.userList,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setNeedList: dispatch.needListState.setNeedList,
  getNeedUserList: dispatch.needListState.getNeedUserList,
  getNeedList: dispatch.needListState.getNeedList,
  uploadNeedExcel: dispatch.needListState.uploadNeedExcel,
});

export const IncomingRequestsForManager = connect(mapState, mapDispatch)(Component);
