export enum EFormFieldMessage {
  RequiredField = 'Обязательное поле при сохранении.',
  InvalidEmailFormat = 'Неверный формат электронной почты',
  InvalidPasswordFormat = 'Пароль должен иметь длину не менее 8 символов и содержать хотя бы одну заглавную букву, одну строчную букву и один специальный символ',
  InvalidPhoneFormat = 'Неверный формат телефона',
  PasswordMismatch = 'Пароли не совпадают',
  PasswordsMatch = 'Текущий и новый пароли совпадают',
  CharacterLimitMin = 'Не менее 3 символов',
  InvalidRange = 'Некорректный диапазон',
  LegalInnLength = 'ИНН должен быть равен 10 символам',
  LegalKppLength = 'КПП должен быть равен 9 символам',
  PropertyCascaderCharacterLimitMin = 'Не менее 1 символа',
}
