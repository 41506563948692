import React from 'react';
import { EPresetType } from 'common/const/preset.enum';
import { EPositionFormMode } from 'common/const/position.enum';
import { IFormValues, IPositionTag } from 'common/models';
import { getOptionsFromProperty, sortProperties } from 'common/helpers/properties.helper';
import { FormSelectWithCustomOptions } from 'common/components/Form/FormSelectWithCustomOptions';
import { getFormItemLabel, rules } from 'common/helpers/form.helper';
import { searchParamsToFormValues } from 'common/helpers/searchParams.helper';
import { FormSelect } from 'common/components/Form/FormSelect';
import { FormInputRange } from 'common/components/Form/FormInputRange';
import { FormCheckbox } from 'common/components/Form/FormCheckbox';
import { ICategory } from 'entities/Categories/Categories.models';
import { IWorkspacePosition } from 'entities/Workspaces/Workspaces.models';
import { IProperty } from 'entities/Property/Property.models';

export const getPositionFormPropertyList = (
  searchParams: URLSearchParams,
  propertyList: IProperty[],
  category?: ICategory | null,
  position?: IWorkspacePosition,
) => {
  const sortedPropertyList = sortProperties.byIsCategory(
    sortProperties.byRequired(
      propertyList.filter((property) => {
        return property.presetType !== EPresetType.NotApplicable;
      }),
    ),
  );

  const { formValues } = searchParamsToFormValues(searchParams);

  return sortedPropertyList.map((property) => {
    const workspacePositionProperty = position?.properties.find(({ propertyId }) => propertyId === property.id);
    const categoryPositionProperty = category?.positions.find(({ propertyId }) => propertyId === property.id);
    const valueFromSearchParams = formValues[property.id];

    if (workspacePositionProperty) {
      return {
        ...property,
        value: workspacePositionProperty.values,
        range: workspacePositionProperty.range,
      };
    }

    if (categoryPositionProperty) {
      return {
        ...property,
        value: categoryPositionProperty.values,
        range: categoryPositionProperty.range,
      };
    }

    if (valueFromSearchParams) {
      return {
        ...property,
        value: property.presetType !== EPresetType.Range ? valueFromSearchParams : undefined,
        range: property.presetType === EPresetType.Range ? valueFromSearchParams[0] : undefined,
      };
    }

    return property;
  });
};

export const getPositionFormPropertyListForView = (propertyList: IProperty[]) => {
  const propertyListForView: IProperty[] = [];

  propertyList.forEach((property, _, array) => {
    if (!property.showCondition) {
      propertyListForView.push(property);
    } else {
      property.showCondition.forEach(({ propertyId, values }) => {
        const parent = array.find(({ id }) => id === propertyId);

        if (values.some((value) => parent?.value?.includes(value))) {
          propertyListForView.push(property);
        }
      });
    }
  });

  return propertyListForView;
};

export const getPositionFormValues = (propertyList: IProperty[]) => {
  return Object.fromEntries(
    propertyList.map(({ id, presetType, value, range }) => {
      return [id, presetType === EPresetType.Range ? [range] : value];
    }),
  );
};

function updatePositionFormProperty(id: number, newValue: string[], propertyList: IProperty[]): IProperty[] {
  const property = propertyList.find((propertyItem) => propertyItem.id === id);

  if (!property) {
    return propertyList;
  }

  property.value = newValue;

  propertyList.forEach((propertyItem) => {
    const noMatchesWithParentValues = propertyItem.showCondition?.some((condition) => {
      return condition.propertyId === id && !newValue.some((value) => condition.values.includes(value));
    });

    if (noMatchesWithParentValues) {
      propertyItem.value = undefined;
      propertyItem.range = undefined;
      updatePositionFormProperty(propertyItem.id, [], propertyList);
    }
  });

  return propertyList;
}

export const setNewPositionFormPropertyValue = (propertyList: IProperty[], formValue: IFormValues) => {
  const key = Number(Object.keys(formValue)[0]);
  const value = Object.values(formValue)[0];

  if (typeof value === 'string') {
    // Value of single_value property
    return updatePositionFormProperty(key, [value], propertyList);
  } else if (typeof value[0] === 'object') {
    // Value of range property
    return propertyList.map((property) => {
      if (key === property.id) {
        return {
          ...property,
          range: {
            ...property.range,
            ...value[0],
          },
        };
      }

      return property;
    });
  } else {
    // Value of multiple_value, checkbox property
    return updatePositionFormProperty(key, value, propertyList);
  }
};

export const positionFormPropertyListToTagList = (propertyList: IProperty[]) => {
  const tagList: IPositionTag[] = [];

  propertyList.forEach(({ id, displayName, presetType, isCategory, range, value, unitOfMeasurement }) => {
    if (presetType === EPresetType.SingleValue && value && value[0]?.length) {
      tagList.push({ id, title: `${displayName}: ${value[0]}`, unitOfMeasurement, disabled: isCategory });
    }

    if (presetType === EPresetType.MultipleValue && value && value.length) {
      tagList.push({ id, title: `${displayName}: ${(value as string[]).join(',')}`, unitOfMeasurement, disabled: isCategory });
    }

    if (presetType === EPresetType.Checkbox && value && value.length) {
      tagList.push({ id, title: displayName, disabled: isCategory });
    }

    if (presetType === EPresetType.Range && range) {
      const { from, to } = range;

      if (from && to) {
        tagList.push({ id, title: `${displayName}: ${from}-${to}`, unitOfMeasurement, disabled: isCategory });
      } else if (from) {
        tagList.push({ id, title: `${displayName}: от${from}`, unitOfMeasurement, disabled: isCategory });
      } else if (to) {
        tagList.push({ id, title: `${displayName}: до${to}`, unitOfMeasurement, disabled: isCategory });
      }
    }
  });

  return tagList;
};

export const clearAllPositionFormConditionalProperties = (propertyList: IProperty[]) => {
  return propertyList.map((property) => {
    return property.isCategory || !property.showCondition ? property : { ...property, range: undefined, value: undefined };
  });
};

export const renderPositionFormField = (property: IProperty, readOnly?: boolean) => {
  const disabled = property.isCategory || readOnly;

  switch (property.presetType) {
    case EPresetType.SingleValue: {
      return property.canAddOwnValueToPreset ? (
        <FormSelectWithCustomOptions
          className="position-form__item"
          label={getFormItemLabel(property.displayName, property.unitOfMeasurement)}
          name={property.id}
          rules={[rules.required(property.isRequiredForPresets)]}
          options={getOptionsFromProperty(property)}
          disabled={disabled}
        />
      ) : (
        <FormSelect
          key={property.id}
          className="position-form__item"
          label={getFormItemLabel(property.displayName, property.unitOfMeasurement)}
          name={property.id}
          rules={[rules.required(property.isRequiredForPresets)]}
          options={getOptionsFromProperty(property)}
          disabled={disabled}
        />
      );
    }
    case EPresetType.MultipleValue: {
      return property.canAddOwnValueToPreset ? (
        <FormSelectWithCustomOptions
          className="position-form__item"
          label={getFormItemLabel(property.displayName, property.unitOfMeasurement)}
          name={property.id}
          rules={[rules.required(property.isRequiredForPresets)]}
          options={getOptionsFromProperty(property)}
          mode="multiple"
          disabled={disabled}
        />
      ) : (
        <FormSelect
          key={property.id}
          className="position-form__item"
          label={getFormItemLabel(property.displayName, property.unitOfMeasurement)}
          name={property.id}
          rules={[rules.required(property.isRequiredForPresets)]}
          options={getOptionsFromProperty(property)}
          disabled={disabled}
          mode="multiple"
        />
      );
    }
    case EPresetType.Range: {
      return (
        <FormInputRange
          key={property.id}
          label={getFormItemLabel(property.displayName, property.unitOfMeasurement)}
          name={property.id}
          disabled={disabled}
          rules={[rules.required(property.isRequiredForPresets)]}
        />
      );
    }
    case EPresetType.Checkbox: {
      return (
        <FormCheckbox
          key={property.id}
          formItemClassname="position-form__item-checkbox"
          name={property.id}
          options={[{ label: property.displayName, value: 'да' }]}
          disabled={disabled}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export const getSimilarPositionList = (
  categoryId: number,
  positionId: number,
  mode?: string,
  positionList?: IWorkspacePosition[],
) => {
  if (!positionList) {
    return [];
  }

  const allPositions = positionList.filter((position) => position.categoryId === categoryId);

  return mode === EPositionFormMode.Edit ? allPositions.filter((position) => position.positionId !== positionId) : allPositions;
};

export const findDefaultPositionCascaderPath = (categoryList: ICategory[], id: number, path: number[] = []): number[] => {
  const category = categoryList.find((categoryItem) => categoryItem.id === id);

  if (category) {
    path.push(category.id);

    if (category.parentId) {
      return findDefaultPositionCascaderPath(categoryList, category.parentId, path);
    }
  }

  return path.reverse();
};
