import React, { FC } from 'react';
import { Button } from 'antd';
import { Image } from 'common/components/Image';
import { ReactComponent as ArrowRightLongIcon } from 'app/assets/images/redesign/arrow-right-long.svg';
import { ICategory } from 'entities/Categories/Categories.models';

interface IComponentProps {
  category: ICategory;
  onCategoryClick: (id: number) => void;
}

export const CatalogCategoryCard: FC<IComponentProps> = ({ category: { id, image, name }, onCategoryClick }) => {
  return (
    <Button className="catalog-category-card" onClick={() => onCategoryClick(id)}>
      <Image size={80} src={image} />

      <span className="text-h4" style={{ flex: 1 }}>
        {name}
      </span>

      <div className="catalog-category-card__hover">
        <span className="text-h4 color-white" style={{ flex: 1 }}>
          {name}
        </span>

        <div className="catalog-category-card__hover-arrow">
          <ArrowRightLongIcon className="icon-arrow-right-long-white" />
        </div>
      </div>
    </Button>
  );
};
