export enum EPropertyType {
  Label = 'label',
  Number = 'number',
  List = 'list',
}

export enum EPropertyFormType {
  AddPositionToWorkplaceForm = 'addPositionToWorkplaceForm',
  ListingFilterForm = 'listingFilterForm',
  AddGoodsForm = 'addGoodForm',
}

export enum EPropertyCascaderItemType {
  Properties = 'properties',
  Options = 'options',
  All = 'all',
}
