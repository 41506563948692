import React from 'react';
import { getUserInitials } from 'entities/User/User.helper';

interface IComponentProps {
  size: number;
  firstName?: string;
  lastName?: string;
}

export const Avatar: React.FC<IComponentProps> = ({ size, firstName, lastName }) => (
  <div style={{ width: `${size}px`, height: `${size}px` }} className="avatar">
    {getUserInitials(firstName, lastName)}
  </div>
);
