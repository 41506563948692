export enum ESupplyStatus {
  Created = 'created',
  Processing = 'processing',
  Pending = 'pending',
  Approved = 'approved',
  Delivery = 'delivery',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
}

export enum ESupplyStatusLabel {
  created = 'Уточняется',
  processing = 'Обрабатывается',
  pending = 'Подтверждено',
  approved = 'Заказ размещен',
  delivery = 'В пути',
  delivered = 'Доставлен',
  cancelled = 'Отменено',
}

export enum ESupplyCorrectionLabel {
  price = 'Не устраивает цена',
  date = 'Не устраивает дата поставки',
  count = 'Не устраивает количество товара',
}

export enum ESupplyCorrectionValue {
  Price = 'price',
  Date = 'date',
  Count = 'count',
}

export enum ESupplyListMenuTab {
  Created = 'created',
  Processing = 'processing',
  Pending = 'pending',
  Approved = 'approved',
  Active = 'active',
  Completed = 'completed',
}

export enum EEditSupplyCardMode {
  Full = 'full',
  Date = 'date',
}

export enum EGroupedSupplyListKey {
  ByDeliveryDate = 'by-delivery-date',
  ByUpdatedAt = 'by-updated-at',
  ByUserId = 'by-user-id',
}
