import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Spin, FormInstance, Select, Table } from 'antd';
import { IFormValues, IOption } from 'common/models';
import { rules } from 'common/helpers/form.helper';
import { LEGAL_INN_LENGTH, LEGAL_KPP_LENGTH } from 'common/config';
import { EMessage } from 'common/const/message.enum';
import { EFormFieldMessage } from 'common/const/form.enum';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ILegalCustomer } from 'entities/LegalEntity/LegalEntity.models';
import { renderLegalCustomerListRecords } from 'entities/LegalEntity/LegalEntity.helper';

interface IComponentProps {
  open: boolean;
  form: FormInstance;
  loading: boolean;
  error: string | null;
  sellerOptions: IOption[];
  customerList: ILegalCustomer[];
  confirmBtnLabel: string;
  addCustomer: (customerId: string, sellerId: number) => void;
  deleteCustomer: (id: number) => void;
  onCancelClick: () => void;
  onSubmit: (values: IFormValues) => void;
}

export const LegalEntityModal: FC<IComponentProps> = (props) => {
  const {
    open,
    form,
    loading,
    error,
    sellerOptions,
    customerList,
    confirmBtnLabel,
    addCustomer,
    deleteCustomer,
    onCancelClick,
    onSubmit,
  } = props;

  const [customerId, setCustomerId] = useState<string>('');
  const [sellerId, setSellerId] = useState<number | null>(null);

  const dataSource = toDataSourceMapper(customerList);
  const columns = renderLegalCustomerListRecords(deleteCustomer);

  const onAddCustomerClick = () => {
    addCustomer(customerId, sellerId as number);
    setCustomerId('');
    setSellerId(null);
  };

  useEffect(() => {
    if (!open) {
      setCustomerId('');
      setSellerId(null);
    }
  }, [open]);

  return (
    <Modal className="modal-gray legal-modal" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <div className="modal-gray__body">
            <Form.Item label="Название" name="name">
              <Input placeholder="Введите название" />
            </Form.Item>

            <Form.Item
              label="ИНН"
              name="inn"
              rules={[
                rules.min(LEGAL_INN_LENGTH, EFormFieldMessage.LegalInnLength),
                rules.max(LEGAL_INN_LENGTH, EFormFieldMessage.LegalInnLength),
              ]}
            >
              <Input placeholder="Введите ИНН" />
            </Form.Item>

            <Form.Item
              label="КПП"
              name="kpp"
              rules={[
                rules.min(LEGAL_KPP_LENGTH, EFormFieldMessage.LegalKppLength),
                rules.max(LEGAL_KPP_LENGTH, EFormFieldMessage.LegalKppLength),
              ]}
            >
              <Input placeholder="Введите КПП" />
            </Form.Item>

            <div className="legal-modal__customers">
              <div className="legal-modal__customers-label">Id покупателя для поставщиков</div>

              <div className="legal-modal__customers-form">
                <Input placeholder="ID" value={customerId} onChange={(e) => setCustomerId(e.target.value)} />

                <Select
                  options={sellerOptions}
                  value={sellerId}
                  onChange={setSellerId}
                  placeholder="Поставщик"
                  suffixIcon={<ChevronIcon />}
                />

                <Button className="btn btn-primary" disabled={!customerId || !sellerId} onClick={onAddCustomerClick}>
                  +
                </Button>
              </div>

              <Table
                dataSource={dataSource}
                columns={columns}
                showHeader={false}
                pagination={false}
                size="small"
                locale={{ emptyText: EMessage.ListIsEmpty }}
              />
            </div>

            {!!error && <div className="modal-gray__error">{error}</div>}
          </div>

          <div className="modal-gray__footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, getFieldError }) => {
                const name = getFieldValue('name');
                const inn = getFieldValue('inn');
                const kpp = getFieldValue('kpp');
                const innError = getFieldError('inn');
                const kppError = getFieldError('kpp');

                return (
                  <Button
                    className="btn btn-primary"
                    htmlType="submit"
                    disabled={
                      !name?.trim().length ||
                      !inn?.trim().length ||
                      !kpp?.trim().length ||
                      !!innError?.length ||
                      !!kppError?.length
                    }
                  >
                    {confirmBtnLabel}
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
