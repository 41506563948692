import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import { ReactComponent as ToolIcon } from 'app/assets/images/redesign/tool.svg';
import { ReactComponent as ArrowLeftShortIcon } from 'app/assets/images/redesign/arrow-left-short.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { IWorkspaceCatalogCategory } from 'entities/Workspace/Workspace.models';
import { sortWorkspaceLowerLevelCategories } from 'entities/Workspace/Workspace.helper';

interface IComponentProps {
  catalog?: IWorkspaceCatalogCategory[];
  selectedCategory: IWorkspaceCatalogCategory | null;
  setSelectedCategory: (value: IWorkspaceCatalogCategory | null) => void;
  onCopyWorkspaceClick: () => void;
  onEditWorkspaceClick: () => void;
  onDeleteWorkspaceClick: () => void;
}

export const WorkspaceTools: FC<IComponentProps> = (props) => {
  const { catalog, selectedCategory, setSelectedCategory, onCopyWorkspaceClick, onEditWorkspaceClick, onDeleteWorkspaceClick } =
    props;

  const [upperLevelCategories, setUpperLevelCategories] = useState<IWorkspaceCatalogCategory[]>([]);
  const [lowerLevelCategories, setLowerLevelCategories] = useState<IWorkspaceCatalogCategory[]>([]);

  const sortedLowerLevelCategories = useMemo(() => {
    return sortWorkspaceLowerLevelCategories(lowerLevelCategories);
  }, [lowerLevelCategories]);

  const onCategoryClick = (value: IWorkspaceCatalogCategory) => {
    if (catalog) {
      if (value.categoryId === DEFAULT_EMPTY_VALUE) {
        setSelectedCategory(null);
        setUpperLevelCategories([]);
        setLowerLevelCategories(catalog);
      } else {
        setSelectedCategory(value);

        if (value.children.length) {
          setLowerLevelCategories(value.children);

          if (!upperLevelCategories.length) {
            setUpperLevelCategories([
              { categoryId: DEFAULT_EMPTY_VALUE, categoryName: 'Все инструменты' } as IWorkspaceCatalogCategory,
              value,
            ]);
          } else {
            const index = upperLevelCategories.findIndex((category) => category.categoryId === value.categoryId);

            if (index === DEFAULT_EMPTY_VALUE) {
              setUpperLevelCategories((prev) => [...prev, value]);
            } else {
              setUpperLevelCategories((prev) => [...prev.slice(0, index), value]);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!!catalog?.length && !upperLevelCategories.length) {
      setLowerLevelCategories(catalog);
    }
  }, [catalog, upperLevelCategories]);

  return (
    <div className="workspace-tools">
      <div className="workspace-tools__header">
        <Dropdown
          overlayClassName="redesign"
          menu={{
            items: [
              { key: 'copy', label: 'Дублировать карту', onClick: onCopyWorkspaceClick },
              { type: 'divider' },
              { key: 'edit', label: 'Редактировать карту', onClick: onEditWorkspaceClick },
              { type: 'divider' },
              { key: 'delete', label: 'Удалить карту', onClick: onDeleteWorkspaceClick, className: 'red' },
            ],
          }}
          placement="bottomRight"
        >
          <Button className="button-circle secondary" icon={<OptionsIcon />} />
        </Dropdown>
      </div>

      <div className="workspace-tools__body">
        <InfiniteScrollContainer>
          <div className="text-h1-drawers mb-52">Инструменты</div>

          {!sortedLowerLevelCategories.length ? (
            <div className="text-body color-dark-grey">В карте ещё нет разделов.</div>
          ) : (
            <div className="mb-52">
              {upperLevelCategories.map((category, _, array) => {
                const isActive = selectedCategory?.categoryId === category.categoryId;
                const isLastItem = category.categoryId === array[array.length - 1].categoryId;

                return (
                  <div
                    key={category.categoryId}
                    className={`workspace-tools__item ${isActive ? 'active' : ''}`}
                    onClick={() => onCategoryClick(category)}
                  >
                    <div className="workspace-tools__item-container">
                      {!!selectedCategory && isLastItem ? (
                        <ToolIcon className="icon-tool-dark-grey" />
                      ) : (
                        <ArrowLeftShortIcon className="icon-arrow-left-short-dark-grey" />
                      )}

                      <div className="workspace-tools__item-label">{category.categoryName}</div>
                    </div>
                  </div>
                );
              })}

              {sortedLowerLevelCategories.map((category) => {
                const isActive = selectedCategory?.categoryId === category.categoryId;

                return (
                  <div
                    key={category.categoryId}
                    className={`workspace-tools__item ${isActive ? 'active' : ''}`}
                    onClick={() => onCategoryClick(category)}
                  >
                    <div className="workspace-tools__item-container">
                      <div className="workspace-tools__item-label">{category.categoryName}</div>
                    </div>

                    {!category.children.length && (
                      <div className="text-tag color-dark-grey" style={{ marginTop: 4 }}>
                        {`${category.positionsCount} позиций`}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </InfiniteScrollContainer>
      </div>
    </div>
  );
};
