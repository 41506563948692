import React, { FC } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Image } from 'common/components/Image';
import { ERoute } from 'common/const/route.enum';
import emptyState from 'app/assets/images/redesign/empty-state.svg';

interface IComponentProps {
  show: boolean;
}

export const BasketEmpty: FC<IComponentProps> = ({ show }) => {
  const navigate = useNavigate();

  const onOpenCatalogClick = () => {
    navigate(ERoute.Catalog);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="redesign basket-empty">
      <Image size={160} src={emptyState} />

      <div className="basket-empty__container">
        <span className="text-h2 basket-empty__title">Корзина пуста</span>

        <span className="text-body color-dark-grey basket-empty__description">
          Воспользуйтесь каталогом, чтобы найти нужные вам товары.
        </span>

        <Button className="button-l primary" onClick={onOpenCatalogClick}>
          Открыть каталог
        </Button>
      </div>
    </div>
  );
};
