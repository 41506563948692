import React, { ReactNode } from 'react';
import { EPresetType } from 'common/const/preset.enum';
import { EPropertyType } from 'common/const/property.enum';
import { IPresetItem, IPresetLabelParams } from 'entities/Presets/Preset.models';
import { IPropertyItem } from 'entities/Property/Property.models';

export const propertyListToPresetList = (propertyList: IPropertyItem[]): IPresetItem[] => {
  return propertyList.map((property) => {
    return {
      presetType: property.presetType,
      propertyId: property.propertyId,
      presetName: property.propertyName,
      propertyType: property.propertyType,
      unitOfMeasurement: property.unitOfMeasurement,
      values: property.values,
      range: property.range,
    };
  });
};

export const getPresetRangeValue = (range?: { from?: number; to?: number }) => {
  const from = range?.from;
  const to = range?.to;

  if (from && to) {
    return `${from}-${to}`;
  } else if (from) {
    return `от ${from}`;
  } else if (to) {
    return `до ${to}`;
  } else {
    return '';
  }
};

export const getPresetLabel = ({ type, name, values, range, unitOfMeasurement }: IPresetLabelParams) => {
  switch (type) {
    case EPresetType.SingleValue: {
      return `${name}: ${values?.[0]} ${unitOfMeasurement ? unitOfMeasurement : ''}`;
    }
    case EPresetType.MultipleValue: {
      return `${name}: ${values?.join(', ')} ${unitOfMeasurement ? unitOfMeasurement : ''}`;
    }
    case EPresetType.Range: {
      return `${name}: ${getPresetRangeValue(range)} ${unitOfMeasurement ? unitOfMeasurement : ''}`;
    }
    case EPresetType.Checkbox: {
      return `${name}: ${values?.join(', ')}`;
    }
    default: {
      return null;
    }
  }
};

const filterPresetList = (presetList: IPresetItem[]) => {
  return {
    numberType: presetList.filter(({ propertyType }) => propertyType === EPropertyType.Number),
    stringType: presetList.filter(({ propertyType }) => propertyType !== EPropertyType.Number),
  };
};

const renderPresetItem = (presetItem: IPresetItem, className: string) => {
  const { propertyId, presetType, presetName, values, range, unitOfMeasurement } = presetItem;

  return (
    <span key={propertyId} className={`preset__item ${className}`}>
      {getPresetLabel({ type: presetType, name: presetName, values, range, unitOfMeasurement })}
    </span>
  );
};

export const renderPresetList = (presetList: IPresetItem[]) => {
  const list: ReactNode[] = [];

  Object.values(EPresetType).forEach((value) => {
    const sortedPresetList = presetList
      .filter((presetItem) => presetItem.presetType === value)
      .sort((a, b) => a.propertyId - b.propertyId);

    switch (value) {
      case EPresetType.SingleValue: {
        const { numberType, stringType } = filterPresetList(sortedPresetList);

        list.push(...stringType.map((presetItem) => renderPresetItem(presetItem, 'single-value')));
        list.push(...numberType.map((presetItem) => renderPresetItem(presetItem, 'single-value-number')));
        break;
      }
      case EPresetType.MultipleValue: {
        const { numberType, stringType } = filterPresetList(sortedPresetList);

        list.push(...numberType.map((presetItem) => renderPresetItem(presetItem, 'multiple-value-number')));
        list.push(...stringType.map((presetItem) => renderPresetItem(presetItem, 'multiple-value')));
        break;
      }
      case EPresetType.Range: {
        list.push(...sortedPresetList.map((presetItem) => renderPresetItem(presetItem, 'range')));
        break;
      }
      case EPresetType.Checkbox: {
        list.push(...sortedPresetList.map((presetItem) => renderPresetItem(presetItem, 'checkbox')));
        break;
      }
      default: {
        break;
      }
    }
  });

  return list;
};

export const mapPresetItem = (preset: IPresetItem) => {
  const { presetType, presetName, values, range, unitOfMeasurement } = preset;

  return getPresetLabel({ type: presetType, name: presetName, values, range, unitOfMeasurement }) as string;
};

export const getPresetName = (presetList: IPresetItem[]) => {
  const list: string[] = [];

  Object.values(EPresetType).forEach((value) => {
    const sortedPresetList = presetList
      .filter((presetItem) => presetItem.presetType === value)
      .sort((a, b) => a.propertyId - b.propertyId);

    switch (value) {
      case EPresetType.SingleValue: {
        const { numberType, stringType } = filterPresetList(sortedPresetList);

        list.push(...stringType.map(mapPresetItem));
        list.push(...numberType.map(mapPresetItem));
        break;
      }
      case EPresetType.MultipleValue: {
        const { numberType, stringType } = filterPresetList(sortedPresetList);

        list.push(...numberType.map(mapPresetItem));
        list.push(...stringType.map(mapPresetItem));
        break;
      }
      case EPresetType.Range:
      case EPresetType.Checkbox: {
        list.push(...sortedPresetList.map(mapPresetItem));
        break;
      }
      default: {
        break;
      }
    }
  });

  return list.join(', ');
};
