import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'common/models';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { userTransport } from 'entities/User/User.transport';
import {
  IUserListState,
  IUserListParams,
  IUser,
  IUserState,
  IUserChangePasswordPayload,
  IUserDeletePayload,
  IUserRemoveSubdivisionPayload,
  IUserUpdateByAdminPayload,
  IUserUpdatePayload,
  IUserStatisticsState,
  IUserStatistics,
  IUserAutoSupplyApproveChangePayload,
} from 'entities/User/User.models';
import { updateUserListState } from 'entities/User/User.helper';

export const userListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IUserListState,
  reducers: {
    setUserList: updateUserListState.setUserList,
    setUserListLoading: updateUserListState.setUserListLoading,
    addUser: updateUserListState.addUser,
    updateUser: updateUserListState.updateUser,
    deleteUser: updateUserListState.deleteUser,
    updateUserWorkspace: updateUserListState.updateUserWorkspace,
    deleteUserWorkspace: updateUserListState.deleteUserWorkspace,
  },
  effects: (dispatch) => ({
    async getUserList(params: IUserListParams) {
      dispatch.userListState.setUserListLoading(true);

      try {
        const response = await userTransport.getUserList(params);
        dispatch.userListState.setUserList(response.data);
        return response.data;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.userListState.setUserListLoading(false);
      }
    },
  }),
});

export const userState = createModel<IRootModel>()({
  state: {
    data: null,
    currentUser: null,
    loading: false,
    error: null,
  } as IUserState,
  reducers: {
    setUser: (state, payload: IUser) => {
      return { ...state, data: payload, currentUser: payload.id === state.currentUser?.id ? payload : state.currentUser };
    },
    setCurrentUser: (state, payload: IUser | null) => ({ ...state, currentUser: payload }),
    setUserLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setUserError: (state, payload: string | null) => ({ ...state, error: payload }),
    setUserAutoSupplyApprove: (state, payload: boolean) => ({
      ...state,
      currentUser: state.currentUser ? { ...state.currentUser, autoSupplyApprove: payload } : null,
    }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getCurrentUser(id: number) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await userTransport.getUserById(id);
        dispatch.userState.setCurrentUser(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async getUserById(id: number) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await userTransport.getUserById(id);
        dispatch.userState.setUser(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async updateUser(payload: IUserUpdatePayload) {
      try {
        const response = await userTransport.updateUser(payload);
        dispatch.userState.setUser(response);
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    async updateUserByAdmin(payload: IUserUpdateByAdminPayload) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await userTransport.updateUserByAdmin(payload);
        dispatch.userListState.updateUser(response);
        dispatch.userState.setUser(response);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async deleteUser(payload: IUserDeletePayload) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await userTransport.deleteUser(payload);
        dispatch.userListState.deleteUser(response.id);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async removeUserSubdivision(payload: IUserRemoveSubdivisionPayload) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await userTransport.removeUserSubdivision(payload);
        dispatch.userState.setUser(response);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async changeUserPassword(payload: IUserChangePasswordPayload) {
      dispatch.userState.setUserLoading(true);

      try {
        const response = await userTransport.changeUserPassword(payload);
        dispatch.userState.setUser(response);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.userState.setUserError);
      } finally {
        dispatch.userState.setUserLoading(false);
      }
    },
    async updateUserAutoSupplyApprove(payload: IUserAutoSupplyApproveChangePayload) {
      try {
        await userTransport.updateUserAutoSupplyApprove(payload);
        dispatch.userState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
  }),
});

export const accountUserListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IUserListState,
  reducers: {
    setAccountUserList: (state, payload: IUser[]) => ({ ...state, data: payload }),
    setAccountUserListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getAccountUserList(params: IUserListParams) {
      dispatch.accountUserListState.setAccountUserListLoading(true);

      try {
        const response = await userTransport.getUserList(params);
        dispatch.accountUserListState.setAccountUserList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.accountUserListState.setAccountUserListLoading(false);
      }
    },
  }),
});

export const statisticsState = createModel<IRootModel>()({
  state: {
    data: {
      requestsCount: 0,
      basketGoodsCount: 0,
      sellerRequestsCount: 0,
    },
    loading: true,
  } as IUserStatisticsState,
  reducers: {
    setStatistics: (state, payload: IUserStatistics) => ({ ...state, data: payload }),
    setStatisticsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    updateStatisticsBasketGoodsCount: (state, payload: number) => {
      return { ...state, data: { ...state.data, basketGoodsCount: payload } };
    },
    updateStatisticsRequestsCount: (state, payload: number) => {
      return { ...state, data: { ...state.data, requestsCount: payload } };
    },
    updateStatisticsSellerRequestsCount: (state, payload: number) => {
      return { ...state, data: { ...state.data, sellerRequestsCount: payload } };
    },
  },
  effects: (dispatch) => ({
    async getUserStatistics() {
      dispatch.statisticsState.setStatisticsLoading(true);

      try {
        const response = await userTransport.getUserStatistics();
        dispatch.statisticsState.setStatistics(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.statisticsState.setStatisticsLoading(false);
      }
    },
  }),
});
