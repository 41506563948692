export enum EDeliveryStatus {
  InStock = 'in_stock',
  AvailableOnRequest = 'available_on_request',
  OutOfStock = 'out_of_stock',
}

export enum EDeliveryStatusLabel {
  in_stock = 'В наличии',
  available_on_request = 'Доступно под заказ',
  out_of_stock = 'Нет на складе',
}
