export enum EPresetTypeLabel {
  label = 'boolean',
  number = 'число',
  list = 'текст',
}

export enum EPresetType {
  NotApplicable = 'not_applicable',
  SingleValue = 'single_value',
  Range = 'range',
  MultipleValue = 'multiple_value',
  Checkbox = 'checkbox',
}

export enum EPresetTypeLabel {
  NotApplicable = 'Неприменимо',
  SingleValue = 'Единичное значение',
  Range = 'Диапазон',
  MultipleValue = 'Множественное значение',
  Checkbox = 'Флажок',
}
