import React, { FC } from 'react';
import { Image } from 'common/components/Image';
import emptyPlaceholder from 'app/assets/images/redesign/empty-state.svg';

interface IComponentProps {
  open: boolean;
  forUser?: boolean;
  forManager?: boolean;
  forSeller?: boolean;
  isCompleted?: boolean;
}

export const SupplyListEmpty: FC<IComponentProps> = ({ open, forUser, forManager, forSeller, isCompleted }) => {
  if (!open) {
    return null;
  }

  return (
    <div className="need-list-empty">
      <Image size={160} src={emptyPlaceholder} />

      <div className="need-list-empty-container">
        <span className="text-h2 color-black need-list-empty-title">В этом разделе нет поставок.</span>

        <span className="text-h4 color-dark-grey need-list-empty-description">
          {forUser &&
            (isCompleted
              ? 'Они появятся здесь, если ваш заказ будет отменён.'
              : 'Они появятся здесь после того, как менеджер возьмёт заявки в работу.')}

          {forManager &&
            (isCompleted
              ? 'Они появятся здесь, если заказ будет отменён поставщиком.'
              : 'Они появятся здесь после того, как поставщик подтвердит заказ.')}

          {forSeller &&
            (isCompleted
              ? 'Они появятся здесь, если заказ будет отменён менеджером по закупкам.'
              : 'Они появятся здесь после того, как вы подтвердите новые поставки по заявкам клиентов.')}
        </span>
      </div>
    </div>
  );
};
