export enum EMessage {
  AccountIsNotLinkedToSubdivision = 'Ваш аккаунт не привязан ни к одному подразделению, обратитесь к администратору.',
  SubdivisionDoesNotHaveDeliveryAddresses = 'Подразделение не имеет адресов доставки, обратитесь к администратору.',
  MustSelectDeliveryAddress = 'Необходимо выбрать адрес доставки.',
  UnexpectedError = 'Произошла непредвиденная ошибка.',
  InvalidRefreshToken = 'Неверный рефреш токен.',
  NotAuthorized = 'Не авторизован',
  ClientsAccountWillBeBlocked = 'Аккаунт клиента будет заблокирован. При блокировке все пользователи, привязанные к Аккаунту клиента, потеряют доступ к данным в системе.',
  ClientsAccountWillBeDeleted = 'Аккаунт клиента будет удалён. Все данные будут потеряны. Вы уверены, что хотите удалить Аккаунт?',
  ManagerListIsEmpty = 'Список пуст. Создайте нового менеджера.',
  SelectedGoodsWillBeRemovedFromActiveBasket = 'Выбранные товары будут удалены из активной корзины.',
  YouCanIndicateDesiredDateForReceivingTheGoods = 'Вы можете указать желаемую дату получения товара. Система отметит товары, доставка которых невозможна в указанный срок.',
  NothingFound = 'Ничего не найдено.',
  WorkspaceListIsEmpty = 'Список пуст. Добавьте новую Карту рабочего места.',
  ThereAreAlreadyGoodsInActiveBasket = 'В Активной корзине уже есть товары. При подтверждении, текущие товары будут потеряны.',
  LetterWasSentSuccessfully = 'Письмо успешно отправлено',
  GoodsWillBeRemovedFromSpecification = 'Товар будет удален из спецификации.',
  SelectedGoodsWillBeRemovedFromSpecification = 'Выбранные товары будут удалены из спецификации.',
  SpecificationWillBeRemoved = 'При удалении последнего товара/ов спуцификация будет удалена.',
  InviteNewUser = 'Список пуст. Пригласите нового пользователя.',
  SubdivisionWillBeRemoved = 'Подразделение будет удалено. Все данные будут утеряны.',
  AddressListIsEmpty = 'Список пуст. Добавьте новый Адрес доставки.',
  AddressWillBeRemoved = 'Адрес будет удален из аккаунта.',
  AddRemoveUsersToSubdivision = 'Привязать/убрать пользователей в подразделение. Установить лимиты трат на покупки.',
  DeliveryAddressManagement = 'Управление адресами доставки, доступными для пользователей подразделения.',
  SetUpNeedForGoods = 'Настроить потребность в товарах на рабочих местах.',
  AddNewUser = 'Список пуст. Добавьте нового Пользователя.',
  UserWillBeRemovedFromSubdivision = 'Пользователь будет исключен из подразделения.',
  WorkspaceWillBeRemovedFromAccount = 'Карта рабочего места будет удалена из Аккаунта.',
  UserWillBeRemovedFromAccount = 'Пользователь будет удален из аккаунта.',
  ChangesWereNotSaved = 'Изменения не были сохранены.',
  ImageUploadError = 'Произошла ошибка при загрузке изображения.',
  ListIsEmpty = 'Список пуст.',
  QRFPListIsEmpty = 'Список пуст. Создайте новый запрос.',
  ContractListForSuperAdminIsEmpty = 'Список пуст. Создайте новый контракт.',
  LegalEntityListIsEmpty = 'Список пуст. Создайте новое юридическое лицо.',
  DiscountListIsEmpty = 'Список пуст. Создайте новый вид скидок.',
  DiscountsOnBrandsListIsEmpty = 'Список пуст. Создайте новую скидку для бренда.',
  StockListIsEmpty = 'Список пуст. Создайте новый склад отгрузки.',
  CatalogListIsEmpty = 'Список пуст. Создайте новый каталог.',
  SubdivisionDoesNotHaveLegalEntities = 'Подразделение не имеет юридических лиц, обратитесь к администратору.',
  MustSelectLegal = 'Необходимо выбрать юридическое лицо.',
  BasketIsEmpty = 'Корзина пуста. Добавьте товары из Магазина',
  IrreversibleEffect = 'Это действие необратимо, вы уверены?',
  RequestListIsEmpty = 'Список заявок пуст.',
  SupplyDeliveryDateIsRequired = 'Для перевода поставки в статус Потдверждено, необходимо указать дату поставки.',
  NoSupplySelected = 'Ни одна заявка не выбрана.',
  SupplyListIsEmpty = 'Список поставок пуст.',
  NeedHistoryIsEmpty = 'Список событий пуст.',
  DeliveryBadgeTooltip = 'Товар отсутствует на складе. Информация о поступлении товара будет предоставлена поставщиком позднее.',
  // Redesing
  GoodsHasBeenRemoved = 'Товар удален из корзины.',
  AllGoodsHaveBeenRemoved = 'Все товары удалены из корзины.',
  DeliveryAddressSuccessfullyChanged = 'Адрес доставки успешно изменен.',
  LegalSuccessfullyChanged = 'Юридическое лицо успешно изменено.',
  GoodsAddedToCart = 'Товар добавлен в корзину.',
  ContractListForAccountAdminIsEmpty = 'Список контрактов пуст.',
}
