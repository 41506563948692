import React, { FC } from 'react';
import { Button } from 'antd';
import { Image } from 'common/components/Image';
import emptyPlaceholder from 'app/assets/images/redesign/empty-state.svg';

interface IComponentProps {
  open: boolean;
  width: number;
  title: string;
  description: string;
  btnTitle?: string;
  onClick?: () => void;
}

export const EmptyList: FC<IComponentProps> = ({ open, width, title, description, btnTitle, onClick }) => {
  if (!open) {
    return null;
  }

  return (
    <div className="empty-list">
      <div className="empty-list__wrapper" style={{ maxWidth: width }}>
        <Image size={160} src={emptyPlaceholder} />

        <div className="empty-list__container">
          <span className="text-h2 color-black empty-list__title">{title}</span>

          <span className="text-h4 color-dark-grey empty-list__description">{description}</span>

          {btnTitle && onClick && (
            <Button className="button-l secondary" onClick={onClick}>
              {btnTitle}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
