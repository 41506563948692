import React, { ChangeEvent } from 'react';
import { Button, Input, Modal, Spin, Table } from 'antd';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { ReactComponent as ProfileIcon } from 'app/assets/images/profile.svg';
import { IUser } from 'entities/User/User.models';
import { renderSelectUserModalRecords } from 'entities/Modal/Modal.helper';

interface IComponentProps {
  title: string;
  open: boolean;
  users?: IUser[];
  updateLoading: boolean;
  searchLoading: boolean;
  searchValue: string;
  selectedUser: IUser | null;
  emptyText: string;
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (value: IUser) => void;
  onCancelClick: () => void;
  onAddUserClick?: () => void;
  onInviteClick?: () => void;
  onSelectUserClick?: () => void;
}

export const SelectUserModal: React.FC<IComponentProps> = (props) => {
  const {
    title,
    open,
    users,
    updateLoading,
    searchLoading,
    searchValue,
    selectedUser,
    emptyText,
    onSearch,
    onSelect,
    onCancelClick,
    onAddUserClick,
    onInviteClick,
    onSelectUserClick,
  } = props;

  const dataSource = toDataSourceMapper<IUser>(users);
  const columns = renderSelectUserModalRecords();

  return (
    <Modal className="modal select-user-modal" open={open} footer={false} closeIcon={false} width={376}>
      <Spin spinning={updateLoading}>
        <div className="modal-content">
          <div>
            <div className="select-user-modal__title">{title}</div>

            <Input
              value={searchValue}
              onChange={onSearch}
              placeholder="Введите имя, фамилию или e-mail"
              prefix={<ProfileIcon />}
            />
          </div>

          <Table
            size="small"
            className="select-user-modal__table"
            rowClassName={(record) => {
              return `select-user-modal__table_item ${record.id === selectedUser?.id ? 'selected' : ''}`;
            }}
            dataSource={dataSource}
            columns={columns}
            showHeader={false}
            scroll={{ y: 232 }}
            loading={searchLoading}
            pagination={false}
            locale={{ emptyText }}
            onRow={(record) => {
              return {
                onClick: () => onSelect(record),
              };
            }}
          />

          <div className="modal-footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            {!!dataSource?.length && onAddUserClick && (
              <Button className="btn btn-default" onClick={onAddUserClick} disabled={!selectedUser}>
                Добавить
              </Button>
            )}

            {!dataSource?.length && onInviteClick && (
              <Button className="btn btn-default" onClick={onInviteClick}>
                Пригласить
              </Button>
            )}

            {onSelectUserClick && (
              <Button className="btn btn-default" onClick={onSelectUserClick} disabled={!selectedUser}>
                Выбрать
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
