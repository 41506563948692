export enum ESettingsMenuTab {
  Account = 'account',
  Subdivisions = 'subdivisions',
  Users = 'users',
  LegalEntities = 'legal-entities',
  Discounts = 'discounts',
  Stocks = 'stocks',
  Integration = 'integration',
  AccountList = 'account-list',
}

export enum EAccountTariffPlanTitle {
  free = 'Бесплатный',
}
