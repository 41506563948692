import { ISubdivision } from 'entities/Subdivisions/Subdivisions.models';
import { IWorkspace, IWorkspaceCatalogCategory, IWorkspaceListState } from 'entities/Workspace/Workspace.models';

export const groupWorkspaceList = (list: IWorkspace[]) => {
  return list.reduce((acc: { subdivisionId: number; subdivisionName: string; dataSource: IWorkspace[] }[], item) => {
    const { subdivisionId, subdivision } = item;
    const existingGroup = acc.find((group) => group.subdivisionId === subdivisionId);

    if (existingGroup) {
      existingGroup.dataSource.push(item);
    } else {
      acc.push({
        subdivisionId,
        subdivisionName: subdivision?.name,
        dataSource: [item],
      });
    }

    return acc;
  }, []);
};

export const sortWorkspaceLowerLevelSubdivisions = (
  subdivisions: ISubdivision[],
  lowerLevelSubdivisions: ISubdivision[],
): ISubdivision[] => {
  const withChildren = lowerLevelSubdivisions.filter((subdivision) => {
    return !!subdivisions.filter((subdivisionItem) => subdivisionItem.parentId === subdivision.id).length;
  });
  const withoutChildren = lowerLevelSubdivisions.filter((subdivision) => {
    return !subdivisions.filter((subdivisionItem) => subdivisionItem.parentId === subdivision.id).length;
  });

  withChildren.sort((a, b) => a.name.localeCompare(b.name));
  withoutChildren.sort((a, b) => a.name.localeCompare(b.name));

  return [...withChildren, ...withoutChildren];
};

export const sortWorkspaceLowerLevelCategories = (
  lowerLevelCategories: IWorkspaceCatalogCategory[],
): IWorkspaceCatalogCategory[] => {
  const withChildren = lowerLevelCategories.filter((category) => !!category.children.length);
  const withoutChildren = lowerLevelCategories.filter((category) => !category.children.length);

  withChildren.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
  withoutChildren.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

  return [...withChildren, ...withoutChildren];
};

export const updateWorkspaceListState = {
  setWorkspaceList: (state: IWorkspaceListState, payload: IWorkspace[]) => ({ ...state, data: payload, count: payload.length }),
  setLoading: (state: IWorkspaceListState, payload: boolean) => ({ ...state, loading: payload }),
  addWorkspace: (state: IWorkspaceListState, payload: IWorkspace) => ({ ...state, data: [...state.data, payload] }),
  updateWorkspace: (state: IWorkspaceListState, payload: IWorkspace) => ({
    ...state,
    data: state.data.map((workspace) => (workspace.id === payload.id ? payload : workspace)),
  }),
  deleteWorkspace: (state: IWorkspaceListState, payload: IWorkspace) => ({
    ...state,
    data: state.data.filter((workspace) => workspace.id !== payload.id),
  }),
};
