import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootDispatch, RootState } from 'app/store';
import { SelectWorkspaceModal } from 'entities/Modal/components/SelectWorkspaceModal';
import { CreateWorkspaceModal } from 'entities/Modal/components/CreateWorkspaceModal';
import { IWorkspaceCreatePayload } from 'entities/Workspaces/Workspaces.models';
import { IUser } from 'entities/User/User.models';

interface IComponentProps {
  user: IUser;
  loading: boolean;
  disabled?: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const UserWorkspaceComponent: React.FC<AllType> = (props) => {
  const {
    user,
    loading,
    disabled,
    workspaceListState,
    workspaceState,
    selectWorkspaceModalParams,
    createSubdivisionWorkspaceModalParams,
    updateUserByAdmin,
    getWorkspaceList,
    createWorkspace,
    setError,
    showModal,
    hideModal,
  } = props;
  const { data: workspaceList, loading: workspaceListLoading } = workspaceListState;
  const { loading: workspaceLoading, error: workspaceError } = workspaceState;
  const { open: openSelectWorkspaceModal } = selectWorkspaceModalParams;
  const { open: openCreateSubdivisionWorkspaceModal } = createSubdivisionWorkspaceModalParams;

  const [searchValue, setSearchValue] = useState<string>('');
  const [workspaceId, setWorkspaceId] = useState<number | undefined>();
  const [form] = useForm();

  const onChangeUserWorkspaceClick = () => {
    showModal(EModalCollectionKey.SelectWorkspace);
  };

  const onSelectWorkspaceModalSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setWorkspaceId(undefined);
    setSearchValue(e.target.value);
  };

  const onSelectWorkspaceModalCancelClick = () => {
    setSearchValue('');
    setWorkspaceId(user.workspaceId);
    hideModal(EModalCollectionKey.SelectWorkspace);
  };

  const onSelectWorkspaceModalConfirmClick = () => {
    updateUserByAdmin({
      id: user.id,
      workspaceId,
      onSuccess: () => {
        setSearchValue('');
        hideModal(EModalCollectionKey.SelectWorkspace);
      },
    });
  };

  const onSelectWorkspaceModalAddClick = () => {
    setSearchValue('');
    hideModal(EModalCollectionKey.SelectWorkspace);
    showModal(EModalCollectionKey.CreateSubdivisionWorkspace);
  };

  const onCreateWorkspaceModalCancelClick = () => {
    setError(null);
    form.resetFields();
    hideModal(EModalCollectionKey.CreateSubdivisionWorkspace);
  };

  const onCreateWorkspaceModalSubmit = (values: IWorkspaceCreatePayload) => {
    setError(null);

    createWorkspace({
      name: values.name,
      subdivisionId: user.subdivisionId as number,
      onSuccess: () => {
        form.resetFields();
        hideModal(EModalCollectionKey.CreateSubdivisionWorkspace);
      },
    });
  };

  useEffect(() => {
    setWorkspaceId(user.workspaceId);
  }, [user]);

  useDebounce(() => {
    if (user && openSelectWorkspaceModal) {
      getWorkspaceList({ subdivisionId: user.subdivisionId, name: searchValue, limit: 0 });
    }
  }, [user, openSelectWorkspaceModal, searchValue]);

  return (
    <>
      <div>Карта рабочего места</div>

      <Input
        value={user.workspace?.name}
        placeholder="Не выбрано"
        readOnly
        onClick={onChangeUserWorkspaceClick}
        disabled={!user.subdivisionId || disabled}
      />

      <SelectWorkspaceModal
        open={openSelectWorkspaceModal}
        searchValue={searchValue}
        workspaces={workspaceList}
        workspaceId={workspaceId}
        updateLoading={loading}
        searchLoading={workspaceListLoading}
        onSearch={onSelectWorkspaceModalSearch}
        onSelect={(workspaceId) => setWorkspaceId(workspaceId)}
        onConfirmClick={onSelectWorkspaceModalConfirmClick}
        onCancelClick={onSelectWorkspaceModalCancelClick}
        onAddWorkspaceClick={onSelectWorkspaceModalAddClick}
      />

      <CreateWorkspaceModal
        open={openCreateSubdivisionWorkspaceModal}
        form={form}
        loading={workspaceLoading}
        error={workspaceError}
        onSubmit={onCreateWorkspaceModalSubmit}
        onCancelClick={onCreateWorkspaceModalCancelClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  workspaceListState: state.workspaceListState,
  workspaceState: state.workspaceState,
  selectWorkspaceModalParams: state.modalCollection.selectWorkspaceModalParams,
  createSubdivisionWorkspaceModalParams: state.modalCollection.createSubdivisionWorkspaceModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateUserByAdmin: dispatch.userState.updateUserByAdmin,
  getWorkspaceList: dispatch.workspaceListState.getWorkspaceList,
  createWorkspace: dispatch.workspaceState.createWorkspace,
  setError: dispatch.workspaceState.setError,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const UserWorkspace = connect(mapState, mapDispatch)(UserWorkspaceComponent);
