import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStorageItem } from 'common/helpers/axios.helper';
import { EStorageKey } from 'common/const/axios.enum';
import { ERoute } from 'common/const/route.enum';
import { EUserRole } from 'common/const/user.enum';
import { Sidebar } from 'common/components/Sidebar';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { useJivo } from 'common/hooks/useJivo';
import { RootDispatch, RootState } from 'app/store';
import { Profile } from 'entities/User/components/Profile';
import { Settings } from 'entities/Settings/components/Settings';
import { WorkspacePosition } from 'entities/Workspaces/components/WorkspacePosition';
import { PropertiesSettings } from 'entities/Property/components/PropertiesSettings';
import { CatalogListSettings } from 'entities/Settings/components/CatalogListSettings';
import { CatalogSettings } from 'entities/Settings/components/CatalogSettings';
import { ActiveBasketList } from 'entities/Basket/components/ActiveBasketList';
import { ActiveBasket } from 'entities/Basket/components/ActiveBasket';
import { Catalog } from 'entities/Catalog/components/Catalog';
import { AccountList } from 'entities/Account/components/AccountList';
import { ContractListForSuperAdmin } from 'entities/Contract/components/ContractListForSuperAdmin';
import { ContractListForAccountAdmin } from 'entities/Contract/components/ContractListForAccountAdmin';
import { Contract } from 'entities/Contract/components/Contract';
import { DeliveryForContract } from 'entities/Delivery/components/DeliveryForContract';
import { Basket } from 'entities/Basket/components/Basket';
import { Requests } from 'entities/Requests/components/Requests';
import { WorkspaceList } from 'entities/Workspace/components/WorkspaceList';
import { Workspace } from 'entities/Workspace/components/Workspace';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PrivateLayoutComponent: React.FC<AllType> = (props) => {
  const { auth, authLoading, getCurrentUser, getUserStatistics, getCurrentBasket } = props;

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);

  const isSuperAdmin = auth ? auth.access.roles.includes(EUserRole.SuperAdmin) : false;

  useJivo(auth);

  useEffect(() => {
    const fetch = async () => {
      if (auth) {
        setFetchLoading(true);

        if (!auth.access.roles.includes(EUserRole.SuperAdmin)) {
          await getCurrentUser(auth.access.id);
          await getUserStatistics();

          if (auth.access.roles.includes(EUserRole.SellerManager)) {
            const xBasketId = getStorageItem<number>(EStorageKey.XBasketId);

            if (xBasketId) {
              await getCurrentBasket(xBasketId);
            }
          } else if (auth.access.basketId) {
            await getCurrentBasket(auth.access.basketId);
          }
        }

        setFetchLoading(false);
      }
    };

    fetch();
  }, [auth]);

  if (!auth && !authLoading) {
    return <Navigate to={ERoute.Login} />;
  }

  if ((!auth && authLoading) || fetchLoading) {
    return <Spin spinning indicator={<SpinIndicator />} />;
  }

  return (
    <div className="private-layout">
      <Sidebar />

      <div className="private-layout__container">
        <Routes>
          {/* Super Admin */}
          <Route path={ERoute.CatalogList} element={<CatalogListSettings />} />
          <Route path={ERoute.CatalogListItem} element={<CatalogSettings />} />
          <Route path={ERoute.PropertiesSettings} element={<PropertiesSettings />} />
          <Route path={ERoute.AccountList} element={<AccountList />} />
          <Route path={ERoute.ContractList} element={<ContractListForSuperAdmin />} />

          {/* Admin */}
          <Route path={ERoute.Settings} element={<Settings />}>
            <Route path=":tab" element={<Settings />}>
              <Route path=":id" element={<Settings />} />
            </Route>
          </Route>
          <Route path={ERoute.AdminContractList} element={<ContractListForAccountAdmin />} />
          <Route path={ERoute.Contract} element={<Contract />} />
          <Route path={ERoute.DeliveryForContract} element={<DeliveryForContract />} />
          <Route path={ERoute.WorkspacePosition} element={<WorkspacePosition />} />
          <Route path={ERoute.WorkspaceList} element={<WorkspaceList />} />

          {/* Seller Manager */}
          <Route path={ERoute.ActiveBasketList} element={<ActiveBasketList />} />
          <Route path={ERoute.ActiveBasket} element={<ActiveBasket />} />

          {/* Common */}
          <Route path={ERoute.Catalog} element={<Catalog />}>
            <Route path=":catalogId" element={<Catalog />}>
              <Route path=":categoryId" element={<Catalog />} />
            </Route>
          </Route>
          <Route path={ERoute.Basket} element={<Basket />} />
          <Route path={ERoute.Requests} element={<Requests />}>
            <Route path=":role" element={<Requests />}>
              <Route path=":tab" element={<Requests />} />
            </Route>
            <Route path="*" element={<Requests />} />
          </Route>
          <Route path={ERoute.Workspace} element={<Workspace />} />
          <Route path={ERoute.Profile} element={<Profile />} />

          {isSuperAdmin ? (
            <Route path="*" element={<Navigate to={ERoute.AccountList} />} />
          ) : (
            <Route path="*" element={<Navigate to={ERoute.Profile} />} />
          )}
        </Routes>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  authLoading: state.authState.loading,
  xBasketId: state.authState.xBasketId,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCurrentUser: dispatch.userState.getCurrentUser,
  getUserStatistics: dispatch.statisticsState.getUserStatistics,
  getCurrentBasket: dispatch.basketState.getCurrentBasket,
});

export const PrivateLayout = connect(mapState, mapDispatch)(PrivateLayoutComponent);
