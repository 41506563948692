import React, { FC, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { mapToMenuWithDivider } from 'common/helpers/common.helper';
import { ITEMS_FOR_VIEW_LIMIT_4 } from 'common/config';
import { ReactComponent as MenuIcon } from 'app/assets/images/redesign/menu.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ICatalog } from 'entities/Catalog/Catalog.models';
import { getCatalogMenuItems } from 'entities/Catalog/Catalog.helper';

interface IComponentProps {
  catalogId?: string;
  catalogList: ICatalog[];
  loading: boolean;
}

export const CatalogSelector: FC<IComponentProps> = ({ catalogId, catalogList, loading }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { onCatalogChange } = useCatalogContext();

  const menuItems = getCatalogMenuItems(catalogList);

  const handleSelect = (id: number) => {
    onCatalogChange(id);
    setOpen(false);
  };

  return (
    <Dropdown
      overlayClassName="redesign"
      open={open}
      onOpenChange={setOpen}
      trigger={['click']}
      menu={{
        items: mapToMenuWithDivider(menuItems),
        selectable: true,
        selectedKeys: catalogId ? [catalogId] : [],
        onSelect: ({ key }) => handleSelect(Number(key)),
      }}
      dropdownRender={(menus) => {
        return (
          <div className="custom-dropdown" style={{ minWidth: 300 }}>
            {!menuItems.length ? (
              <span className="text-lists color-dark-grey">В списке нет вариантов.</span>
            ) : (
              <div>
                <span className="text-tag-accent color-bright-green custom-dropdown__title">Каталоги поставщиков</span>

                <div className={menuItems.length > ITEMS_FOR_VIEW_LIMIT_4 ? 'custom-dropdown__scrollable scrollbar' : ''}>
                  {menus}
                </div>
              </div>
            )}
          </div>
        );
      }}
      disabled={loading}
    >
      <Button
        className={`button-s ${open ? 'success' : 'primary'}`}
        icon={open ? <CloseIcon className="icon-close" /> : <MenuIcon className="icon-menu" />}
      >
        Каталог
      </Button>
    </Dropdown>
  );
};
