export enum ESearchParams {
  SubdivisionId = 'subdivisionId',
  UserId = 'userId',
  Frame = 'frame',
  WorkspaceId = 'workspaceId',
  PositionId = 'positionId',
  CategoryId = 'categoryId',
  BasketId = 'basketId',
  RequestId = 'requestId',
  QrfpId = 'qrfpId',
  IsAvailableForPurchase = 'isAvailableForPurchase',
  SellerId = 'sellerId',
  BuyerId = 'buyerId',
  ProductId = 'productId',
  Search = 'search',
  CatalogId = 'catalogId',
  Id = 'id',
  Tab = 'tab',
}
