import React, { FC, useEffect, useState } from 'react';
import { Button, DatePicker, Drawer, InputNumber } from 'antd';
import { connect } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { Image } from 'common/components/Image';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { disabledDate } from 'common/helpers/date.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { EEditSupplyCardMode } from 'common/const/supply.enum';
import { EDateFormat } from 'common/const/date.enum';
import { DEFAULT_GOODS_COUNT, DRAWER_Z_INDEX_3 } from 'common/config';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as CalendarIcon } from 'app/assets/images/redesign/calendar.svg';
import { ReactComponent as ArrowLeftShortIcon } from 'app/assets/images/redesign/arrow-left-short.svg';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ supply, supplyLoading, createSupply, updateSupply }) => {
  const [price, setPrice] = useState<number | null>(null);
  const [count, setCount] = useState<number>(0);
  const [maxCount, setMaxCount] = useState<number>(0);
  const [priceEdited, setPriceEdited] = useState<boolean>(false);
  const [countEdited, setCountEdited] = useState<boolean>(false);
  const [deliveryDateEdited, setDeliveryDateEdited] = useState<boolean>(false);
  const [deliveryDate, setDeliveryDate] = useState<Dayjs>();
  const { editSupplyCardMode, openEditSupplyCard, setOpenSupplyCard, setOpenEditSupplyCard } = useSupplyContext();

  const fullEditMode = editSupplyCardMode === EEditSupplyCardMode.Full;

  const onClose = () => {
    setOpenEditSupplyCard(false);
    setOpenSupplyCard(true);
  };

  const onPriceChange = (value: number | null) => {
    setPriceEdited(true);
    setPrice(value);
  };

  const onCountChange = (value: number | null) => {
    setCountEdited(true);

    if (!value) {
      setCount(DEFAULT_GOODS_COUNT);
    } else {
      setCount(value);
    }
  };

  const onDeliveryDateChange = (value: Dayjs | null) => {
    if (value) {
      setDeliveryDateEdited(true);
      setDeliveryDate(value);
    }
  };

  const onSaveClick = () => {
    if (supply && deliveryDate) {
      updateSupply({
        id: supply.id,
        count: countEdited ? count : undefined,
        price: priceEdited && price ? price : undefined,
        deliveryDate: deliveryDateEdited ? deliveryDate.format(EDateFormat.FullDateReverse) : undefined,
        onSuccess: () => {
          setPriceEdited(false);
          setCountEdited(false);
          setDeliveryDateEdited(false);

          if (count < supply.count) {
            createSupply({
              supplyNeedId: supply.supplyNeedId,
              supplies: [
                {
                  count: supply.count - count,
                  goodId: supply.good.id,
                  deliveryDate: deliveryDate.format(EDateFormat.FullDateReverse),
                  price: supply.price,
                },
              ],
              onSuccess: () => {
                onClose();
                showSuccessMessage(`Поставка #${supply.id} была откорректирована.`);
              },
            });
          } else {
            onClose();
            showSuccessMessage(`Поставка #${supply.id} была откорректирована.`);
          }
        },
      });
    }
  };

  useEffect(() => {
    if (supply && openEditSupplyCard) {
      setPrice(supply.price);
      setCount(supply.count);
      setMaxCount(supply.count);

      if (supply.deliveryDate) {
        setDeliveryDate(dayjs(supply.deliveryDate));
      } else {
        setDeliveryDate(dayjs());
        setDeliveryDateEdited(true);
      }
    }
  }, [openEditSupplyCard, supply]);

  if (!supply) {
    return null;
  }

  return (
    <Drawer
      rootClassName="redesign drawer supply-card nested"
      open={openEditSupplyCard}
      onClose={supplyLoading ? undefined : onClose}
      width={800}
      closeIcon={<ArrowLeftLongIcon />}
      destroyOnClose
      zIndex={DRAWER_Z_INDEX_3}
    >
      <div className="drawer__body">
        <div className="drawer__title">{`Корректировать поставку #${supply.id}`}</div>

        <div className="supply-card__goods">
          <Image size={80} src={supply.good.image} />

          <div style={{ flex: 1 }}>
            <div className="text-h4 supply-card__goods-name" title={supply.good.name}>
              {supply.good.name}
            </div>

            <div className="supply-card__goods-container">
              <span className="text-accent">{priceFormatter(supply.price)}</span>

              {supply.good.sellerCode && <span className="text-tag color-dark-grey">{supply.good.sellerCode}</span>}
            </div>
          </div>
        </div>

        <div className="mb-32">
          <span className="text-tag supply-card__block-edit-label">Цена за ед. товара</span>

          <InputNumber
            value={price}
            onChange={onPriceChange}
            onBlur={() => {
              if (price === null && supply) {
                setPrice(supply.price);
              }
            }}
            controls={false}
            suffix={<div className="text-controls color-dark-grey supply-card__block-edit-icon">₽</div>}
            disabled={!fullEditMode}
          />
        </div>

        <div className="mb-32">
          <span className="text-tag supply-card__block-edit-label">Количество</span>

          <InputNumber
            value={count}
            onChange={onCountChange}
            controls={false}
            max={maxCount}
            suffix={<div className="text-controls color-dark-grey supply-card__block-edit-icon">шт</div>}
            disabled={!fullEditMode}
          />
        </div>

        <div className="mb-132">
          <span className="text-tag supply-card__block-edit-label">Срок доставки</span>

          <DatePicker
            value={deliveryDate}
            onChange={onDeliveryDateChange}
            placeholder="Выбрать дату"
            suffixIcon={<CalendarIcon className="icon-calendar-dark-grey" />}
            allowClear={false}
            showToday={false}
            inputReadOnly
            locale={locale}
            placement="bottomLeft"
            format={EDateFormat.FullDateWithShortDay}
            disabledDate={disabledDate}
            prevIcon={<ArrowLeftShortIcon />}
            nextIcon={<ArrowRightShortIcon />}
          />
        </div>
      </div>

      <div className="drawer__footer">
        <Button className="button-l primary" onClick={onSaveClick} loading={supplyLoading}>
          Сохранить
        </Button>

        <Button className="button-l secondary" onClick={onClose} loading={supplyLoading}>
          Отмена
        </Button>
      </div>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  supply: state.supplyState.data,
  supplyLoading: state.supplyState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  createSupply: dispatch.supplyState.createSupply,
  updateSupply: dispatch.supplyState.updateSupply,
});

export const EditSupplyCard = connect(mapState, mapDispatch)(Component);
