import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EAddressModalTabKey } from 'common/const/address.enum';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { IStock, IStockListState } from 'entities/Stock/Stock.models';
import { IAddressSuggestion } from 'entities/Addresses/Addresses.models';
import { getAddressSuggestionCity } from 'entities/Addresses/Addresses.helper';

export const renderStockListRecords = (onEditClick: (record: IStock) => void, onDeleteClick: (id: number) => void) => {
  return [
    {
      key: 'name',
      render: (_, record) => {
        return (
          <div>
            <div>{record.displayName}</div>

            <div className="stock-list__table-item-description">{record.address}</div>
          </div>
        );
      },
    },
    {
      key: 'menu',
      render: (_, record) => {
        return (
          <ThreeDotMenu
            items={[
              {
                key: 'edit',
                label: <Button onClick={() => onEditClick(record)}>Редактировать</Button>,
                className: 'edit',
              },
              {
                key: 'delete',
                label: <Button onClick={() => onDeleteClick(record.id)}>Удалить</Button>,
                className: 'delete',
              },
            ]}
          />
        );
      },
      width: 64,
    },
  ] as ColumnsType<IStock>;
};

export const stockFormSubmitDisabled = (
  getFieldValue: (name: any) => any,
  tab: string,
  addressSuggestions?: IAddressSuggestion[],
) => {
  const name = getFieldValue('name');
  const address = getFieldValue('address');
  const manualAddress = getFieldValue('manualAddress');
  const manualCity = getFieldValue('manualCity');

  const city = getAddressSuggestionCity(address, addressSuggestions);

  if (tab === EAddressModalTabKey.AutomaticSearch) {
    return !name || !city;
  } else {
    return !name || !manualAddress || !manualCity;
  }
};

export const updateStockListState = {
  addStock: (state: IStockListState, payload: IStock) => ({ ...state, data: [...state.data, payload] }),
  updateStock: (state: IStockListState, payload: IStock) => ({
    ...state,
    data: state.data.map((stock) => (stock.id === payload.id ? payload : stock)),
  }),
  deleteStock: (state: IStockListState, payload: number) => ({
    ...state,
    data: state.data.filter((stock) => stock.id !== payload),
  }),
};
