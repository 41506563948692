import { EPositionFormMode } from 'common/const/position.enum';
import { ESettingsMenuTab } from 'common/const/settings.enum';

export const getSettingsPath = (tab: ESettingsMenuTab, id?: number, restParams?: string) => {
  let path = `/settings/${tab}`;

  if (id) {
    path += `/${id}`;
  }

  if (restParams) {
    path += `/${restParams}`;
  }

  return path;
};

export const getWorkspacePositionPath = (
  mode: EPositionFormMode,
  workspaceId: number,
  categoryId: number,
  positionId?: number,
) => {
  let path = `/workspace-position/${mode}?workspaceId=${workspaceId}&categoryId=${categoryId}`;

  if (positionId) {
    path += `&positionId=${positionId}`;
  }

  return path;
};
