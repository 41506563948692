import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import { EPropertyType } from 'common/const/property.enum';
import { CHARACTER_LIMIT_MIN, PROPERTY_CASCADER_CHARACTER_LIMIT_MIN } from 'common/config';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import {
  IPropertyCascaderItem,
  IProperty,
  IPropertyCascaderItemCreateOptionPayload,
  IPropertyCascaderItemDeleteOptionPayload,
  IPropertyCascaderItemSelectOptionPayload,
} from 'entities/Property/Property.models';
import { findCascaderItemOptions, getCascaderItemOptionsSearchOptions } from 'entities/Property/Property.helper';
import { PropertyCascaderItemSearch } from 'entities/Property/components/PropertyCascaderItemSearch';

interface IComponentProps {
  cascaderItem: IPropertyCascaderItem;
  propertyList?: IProperty[];
  scrollContainerLeft?: number;
  selectOption: (payload: IPropertyCascaderItemSelectOptionPayload) => void;
  createOption: (payload: IPropertyCascaderItemCreateOptionPayload) => Promise<void>;
  deleteOption: (payload: IPropertyCascaderItemDeleteOptionPayload) => Promise<void>;
  setError: (payload: { error: string | null; cascaderItemId: string }) => void;
}

export const PropertyCascaderItemOptions: FC<IComponentProps> = (props) => {
  const { cascaderItem, propertyList, scrollContainerLeft, selectOption, createOption, deleteOption, setError } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [openSearchDropdown, setOpenSearchDropdown] = useState<boolean>(false);

  const cascaderItemOptions = findCascaderItemOptions(cascaderItem, propertyList);
  const searchOptions = getCascaderItemOptionsSearchOptions(cascaderItemOptions);
  const error = cascaderItem.error;
  const readOnly = cascaderItem.propertyType === EPropertyType.Label;
  const isCategory = cascaderItem.isCategory;

  const onSearchValueChange = (value: string) => {
    if (error) {
      setError({ error: null, cascaderItemId: cascaderItem.id });
    }

    value.trim().length ? setOpenSearchDropdown(true) : setOpenSearchDropdown(false);

    setSearchValue(value);
  };

  const onOptionSelect = (value: string) => {
    const option = cascaderItemOptions.find((optionItem) => optionItem.valueId === Number(value));

    if (option) {
      selectOption({
        valueId: option.valueId,
        value: option.value,
        cascaderItemId: cascaderItem.id,
        cascaderItemPropertyId: cascaderItem.propertyId as number,
      });
      setSearchValue('');
      setOpenSearchDropdown(false);
    }
  };

  const onOptionCreate = () => {
    if (searchValue) {
      createOption({
        value: searchValue,
        propertyId: cascaderItem.propertyId as number,
        cascaderItemId: cascaderItem.id,
        onSuccess: () => {
          setSearchValue('');
          setOpenSearchDropdown(false);
        },
      });
    }
  };

  const handleDeleteOption = (valueId: number, value: string) => {
    deleteOption({
      valueId,
      value,
      propertyId: cascaderItem.propertyId as number,
      cascaderItemId: cascaderItem.id,
      selectedOptionId: cascaderItem.selectedOptionId,
    });
  };

  useEffect(() => {
    if (cascaderItem.error) {
      setOpenSearchDropdown(false);
    }
  }, [cascaderItem.error]);

  useEffect(() => {
    setOpenSearchDropdown(false);
  }, [scrollContainerLeft]);

  return (
    <div className="property-cascader-item">
      <div className="property-cascader-item__title">{cascaderItem.value}</div>

      <PropertyCascaderItemSearch
        open={openSearchDropdown}
        options={searchOptions}
        searchValue={searchValue}
        disabled={readOnly}
        error={error}
        limit={isCategory ? CHARACTER_LIMIT_MIN : PROPERTY_CASCADER_CHARACTER_LIMIT_MIN}
        onSearch={onSearchValueChange}
        onSelect={onOptionSelect}
        onCreate={onOptionCreate}
        onBlur={() => setOpenSearchDropdown(false)}
        onFocus={() => searchValue.length && setOpenSearchDropdown(true)}
      />

      <div className="property-cascader-item__container">
        {cascaderItemOptions.map(({ valueId, value }) => {
          const containerClassNames = [
            'property-cascader-item__option',
            cascaderItem.selectedOptionId === valueId ? 'selected' : '',
          ].join(' ');

          return (
            <div
              key={valueId}
              className={containerClassNames}
              onClick={() => {
                selectOption({
                  valueId,
                  value,
                  cascaderItemId: cascaderItem.id,
                  cascaderItemPropertyId: cascaderItem.propertyId as number,
                });
              }}
            >
              <div title={value} className="property-cascader-item__option-name">
                {value}
              </div>

              {!readOnly && (
                <Button
                  className="btn btn-icon delete-btn"
                  icon={<CloseIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteOption(valueId, value);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
