import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'common/models';
import { getStorageItem, axiosErrorHandler, saveStorageItem, removeStorageItem } from 'common/helpers/axios.helper';
import { EStorageKey } from 'common/const/axios.enum';
import { IRootModel } from 'app/store';
import { authTransport } from 'entities/Auth/Auth.transport';
import {
  IAuthState,
  IAuthLoginPayload,
  IRestorePasswordEmailPayload,
  IRestorePasswordPayload,
  IToken,
  ILoginAsUserPayload,
} from 'entities/Auth/Auth.models';
import { clearCreds, saveCreds } from 'entities/Auth/Auth.helper';

export const authState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: true,
    error: null,
    superAdminAsUser: false,
    userCatalogId: null,
    xBasketId: null,
  } as IAuthState,
  reducers: {
    setAuth: (state, payload: IToken | null) => ({ ...state, data: payload }),
    setAuthLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setAuthError: (state, payload: string | null) => ({ ...state, error: payload }),
    setAuthSuperAdminAsUser: (state, payload: boolean) => ({ ...state, superAdminAsUser: payload }),
    setAuthUserCatalogId: (state, payload: number | null) => ({ ...state, userCatalogId: payload }),
    setAuthXBasketId: (state, payload: number | null) => ({ ...state, xBasketId: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async initAuth() {
      dispatch.authState.setAuthLoading(true);

      const creds = getStorageItem<IToken>(EStorageKey.Creds);
      const xUserId = getStorageItem<string>(EStorageKey.XUserId);
      const userCatalogId = getStorageItem<number>(EStorageKey.UserCatalogId);
      const xBasketId = getStorageItem<number>(EStorageKey.XBasketId);

      if (creds) {
        const now = Math.round(Date.now() / 1000);

        if (creds.refresh.expiredAt && creds.refresh.expiredAt < now) {
          clearCreds(dispatch);
          return;
        }

        if (creds.access.expiredAt && creds.access.expiredAt < now) {
          if (creds.refresh.token) {
            try {
              const refreshResponse = await authTransport.refreshToken(creds.refresh.token);
              await saveCreds(dispatch, refreshResponse);
            } catch (refreshError) {
              axiosErrorHandler(refreshError);
              clearCreds(dispatch);
            }
          } else {
            clearCreds(dispatch);
          }
        }

        dispatch.authState.setAuth(creds);

        if (xUserId) {
          dispatch.authState.setAuthSuperAdminAsUser(true);
        }

        dispatch.authState.setAuthUserCatalogId(userCatalogId);
        dispatch.authState.setAuthXBasketId(xBasketId);
        dispatch.authState.setAuthLoading(false);
      } else {
        clearCreds(dispatch);
      }
    },
    async addAuth(payload: IAuthLoginPayload) {
      dispatch.authState.setAuthLoading(true);

      try {
        const response = await authTransport.addAuth(payload);
        await saveCreds(dispatch, response);
      } catch (error) {
        axiosErrorHandler(error, dispatch.authState.setAuthError);
      } finally {
        dispatch.authState.setAuthLoading(false);
      }
    },
    async deleteAuth() {
      clearCreds(dispatch);
    },
    async sendRestorePasswordEmail(payload: IRestorePasswordEmailPayload) {
      dispatch.authState.setAuthLoading(true);

      try {
        await authTransport.sendRestorePasswordEmail(payload);
        dispatch.authState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.authState.setAuthError);
      } finally {
        dispatch.authState.setAuthLoading(false);
      }
    },
    async restorePassword(payload: IRestorePasswordPayload) {
      dispatch.authState.setAuthLoading(true);

      try {
        const response = await authTransport.restorePassword(payload);
        await saveCreds(dispatch, response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.authState.setAuthLoading(false);
      }
    },
    loginAsUser(payload: ILoginAsUserPayload, models) {
      const { user, isSeller } = payload;

      const auth = models.authState.data;

      if (auth) {
        /*
        Access/Refresh token, issuedAt, expiredAt - from super admin creds
        */
        const creds = {
          access: {
            ...auth.access,
            id: user.id,
            userId: user.id,
            accountId: user.accountId,
            accountName: user.account.name,
            firstName: user.firstName,
            lastName: user.lastName,
            roles: user.roles,
            isSeller,
            subdivisionName: user.subdivision?.name,
            basketId: user.basketId,
          },
          refresh: {
            ...auth.refresh,
            id: user.id,
            userId: user.id,
          },
        };

        dispatch.authState.setAuth(creds);
        dispatch.authState.setAuthSuperAdminAsUser(true);
        saveStorageItem(EStorageKey.Creds, creds);
        saveStorageItem(EStorageKey.XUserId, user.id);
        saveStorageItem(EStorageKey.SuperAdminCreds, auth);
      }
    },
    async logoutAsUser() {
      const superAdminCreds = getStorageItem<IToken>(EStorageKey.SuperAdminCreds);

      if (superAdminCreds) {
        dispatch.authState.setAuth(superAdminCreds);
        dispatch.authState.setAuthSuperAdminAsUser(false);
        dispatch.authState.setAuthUserCatalogId(null);
        dispatch.authState.setAuthXBasketId(null);
        dispatch.userState.setCurrentUser(null);
        saveStorageItem(EStorageKey.Creds, superAdminCreds);
        removeStorageItem(EStorageKey.XUserId);
        removeStorageItem(EStorageKey.XBasketId);
        removeStorageItem(EStorageKey.SuperAdminCreds);
        removeStorageItem(EStorageKey.UserCatalogId);
      } else {
        clearCreds(dispatch);
      }
    },
  }),
});
