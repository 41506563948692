export enum EErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export enum ECommonErrorMessage {
  UnexpectedError = 'Unexpected Error',
}

export enum EStorageKey {
  Creds = 'creds',
  SuperAdminCreds = 'super-admin-creds',
  XUserId = 'x-user-id',
  XBasketId = 'x-basket-id',
  UserCatalogId = 'user-сatalog-id',
}
