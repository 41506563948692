import React from 'react';
import { Button, Input, Modal, Table } from 'antd';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { EMessage } from 'common/const/message.enum';
import { ReactComponent as ProfileIcon } from 'app/assets/images/profile.svg';
import { IUser } from 'entities/User/User.models';
import { renderSelectUserModalRecords } from 'entities/Modal/Modal.helper';

interface IComponentProps {
  open: boolean;
  searchValue: string;
  users: IUser[];
  selectedUser: IUser | null;
  onSearch: (value: string) => void;
  onSelect: (value: IUser) => void;
  onCancel: () => void;
  onLogin: () => void;
}

export const SelectAccountUserModal: React.FC<IComponentProps> = (props) => {
  const { open, searchValue, users, selectedUser, onSearch, onSelect, onCancel, onLogin } = props;

  const dataSource = toDataSourceMapper<IUser>(users);
  const columns = renderSelectUserModalRecords();

  return (
    <Modal className="modal" open={open} footer={false} closeIcon={false} width={376}>
      <div className="modal-content">
        <div>
          <div className="modal__title">Выберите пользователя</div>

          <Input
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            placeholder="Введите имя, фамилию или e-mail"
            prefix={<ProfileIcon />}
          />
        </div>

        <Table
          size="small"
          className="select-user-modal__table"
          rowClassName={(record) => {
            return `select-user-modal__table_item ${record.id === selectedUser?.id ? 'selected' : ''}`;
          }}
          dataSource={dataSource}
          columns={columns}
          showHeader={false}
          scroll={{ y: 232 }}
          pagination={false}
          locale={{ emptyText: EMessage.ListIsEmpty }}
          onRow={(record) => {
            return {
              onClick: () => onSelect(record),
            };
          }}
        />

        <div className="modal-footer">
          <Button className="btn btn-default" onClick={onCancel}>
            Отмена
          </Button>

          <Button className="btn btn-default" onClick={onLogin} disabled={!selectedUser}>
            Войти
          </Button>
        </div>
      </div>
    </Modal>
  );
};
