import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { EMessage } from 'common/const/message.enum';
import { showErrorMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import { IFilesState } from 'entities/Files/Files.models';
import { filesTransport } from 'entities/Files/Files.transport';

export const filesState = createModel<IRootModel>()({
  state: {
    loading: false,
  } as IFilesState,
  reducers: {
    setFilesLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async uploadFiles(file: File) {
      dispatch.filesState.setFilesLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      showLoadingMessage(0, 'Загрузка изображения...');

      try {
        const response = await filesTransport.uploadFiles(formData);
        showSuccessMessage();
        return response;
      } catch (error) {
        showErrorMessage(EMessage.ImageUploadError);
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.filesState.setFilesLoading(false);
      }
    },
  }),
});
