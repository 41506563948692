import React, { useState } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { EAddressModalTabKey } from 'common/const/address.enum';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootDispatch, RootState } from 'app/store';
import { AddAddressModal } from 'entities/Modal/components/AddAddressModal';
import { addressDataToOptions } from 'entities/Addresses/Addresses.helper';

interface IComponentProps {
  subdivisionId?: number;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CreateSubdivisionAddressComponent: React.FC<AllType> = (props) => {
  const {
    subdivisionId,
    addressSuggestionListState,
    addressState,
    createSubdivisionAddressModalParams,
    getAddressSuggestionList,
    createAddress,
    showModal,
    hideModal,
  } = props;
  const { data, loading: addressSuggestionListLoading } = addressSuggestionListState;
  const { loading: addressLoading } = addressState;
  const { open } = createSubdivisionAddressModalParams;

  const [searchValue, setSearchValue] = useState<string>('');
  const [addressName, setAddressName] = useState<string>('');
  const [postalCode, setPostalCode] = useState<number | null>(null);
  const [city, setCity] = useState<string>('');
  const [manualPostalCode, setManualPostalCode] = useState<number | null>(null);
  const [manualCity, setManualCity] = useState<string>('');
  const [tabKey, setTabKey] = useState<string>(EAddressModalTabKey.AutomaticSearch);

  const options = addressDataToOptions(data);
  const addAddressDisabled =
    (tabKey === EAddressModalTabKey.AutomaticSearch && (!postalCode || !city.length)) ||
    (tabKey === EAddressModalTabKey.ManualInput && (!searchValue.length || !manualPostalCode || !manualCity.length));

  const onSearchValueChange = (value: string) => {
    if (!value.length) {
      setPostalCode(null);
      setCity('');
    }

    setSearchValue(value);
  };

  const onAddressSelect = (value: string) => {
    const option = data.find((item) => item.name === value);

    if (option) {
      if (option.postalCode && option.city) {
        setPostalCode(option.postalCode);
        setCity(option.city);
      } else {
        setPostalCode(null);
        setCity('');
      }

      setAddressName(option.name);
    }
  };

  const onAddAddressModalCancelClick = () => {
    setSearchValue('');
    setAddressName('');
    setPostalCode(null);
    setManualPostalCode(null);
    setManualCity('');
    setTabKey(EAddressModalTabKey.AutomaticSearch);
    hideModal(EModalCollectionKey.CreateSubdivisionAddress);
  };

  const onAddAddressModalAddClick = () => {
    if (subdivisionId) {
      const payload = {
        subdivisionId,
        name: tabKey === EAddressModalTabKey.AutomaticSearch ? addressName : searchValue,
        postalCode: tabKey === EAddressModalTabKey.AutomaticSearch ? (postalCode as number) : (manualPostalCode as number),
        city,
      };

      createAddress({
        ...payload,
        onSuccess: () => {
          setSearchValue('');
          setAddressName('');
          setPostalCode(null);
          setCity('');
          setManualPostalCode(null);
          setManualCity('');
          setTabKey(EAddressModalTabKey.AutomaticSearch);
          hideModal(EModalCollectionKey.CreateSubdivisionAddress);
        },
      });
    }
  };

  const onTabChange = (key: string) => {
    if (key === EAddressModalTabKey.ManualInput) {
      setManualPostalCode(postalCode);
      setManualCity(city);
    } else {
      setManualPostalCode(null);
      setManualCity('');
    }

    setTabKey(key);
  };

  useDebounce(() => {
    if (tabKey === EAddressModalTabKey.AutomaticSearch) {
      getAddressSuggestionList({ name: searchValue });
    }
  }, [searchValue, tabKey]);

  return (
    <>
      <div>
        <Button className="btn btn-primary" onClick={() => showModal(EModalCollectionKey.CreateSubdivisionAddress)}>
          Добавить адрес доставки
        </Button>
      </div>

      <AddAddressModal
        open={open}
        options={options}
        dataLoading={addressSuggestionListLoading}
        createLoading={addressLoading}
        value={searchValue}
        disabled={addAddressDisabled}
        tabKey={tabKey}
        manualPostalCode={manualPostalCode}
        manualCity={manualCity}
        onChange={onSearchValueChange}
        onSelect={onAddressSelect}
        onCancelClick={onAddAddressModalCancelClick}
        onAddClick={onAddAddressModalAddClick}
        onTabChange={onTabChange}
        onManualPostalCodeChange={setManualPostalCode}
        onManualCityChange={setManualCity}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  addressSuggestionListState: state.addressSuggestionListState,
  addressState: state.addressState,
  createSubdivisionAddressModalParams: state.modalCollection.createSubdivisionAddressModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAddressSuggestionList: dispatch.addressSuggestionListState.getAddressSuggestionList,
  createAddress: dispatch.addressState.createAddress,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const CreateSubdivisionAddress = connect(mapState, mapDispatch)(CreateSubdivisionAddressComponent);
