import React, { FC, useEffect, useMemo, useState } from 'react';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import { ReactComponent as ArrowLeftShortIcon } from 'app/assets/images/redesign/arrow-left-short.svg';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { IWorkspace } from 'entities/Workspace/Workspace.models';
import { sortWorkspaceLowerLevelSubdivisions } from 'entities/Workspace/Workspace.helper';
import { ISubdivision } from 'entities/Subdivisions/Subdivisions.models';

interface IComponentProps {
  workspaceList: IWorkspace[];
  subdivisionList: ISubdivision[];
  selectedSubdivision: ISubdivision | null;
  setSelectedSubdivision: (value: ISubdivision | null) => void;
}

export const WorkspaceListSubdivisions: FC<IComponentProps> = (props) => {
  const { workspaceList, subdivisionList, selectedSubdivision, setSelectedSubdivision } = props;

  const [upperLevelSubdivisions, setUpperLevelSubdivisions] = useState<ISubdivision[]>([]);
  const [lowerLevelSubdivisions, setLowerLevelSubdivisions] = useState<ISubdivision[]>([]);

  const sortedLowerLevelSubdivisions = useMemo(() => {
    return sortWorkspaceLowerLevelSubdivisions(subdivisionList, lowerLevelSubdivisions);
  }, [subdivisionList, lowerLevelSubdivisions]);

  const onSubdivisionClick = (value: ISubdivision) => {
    if (value.id === DEFAULT_EMPTY_VALUE) {
      setSelectedSubdivision(null);
      setUpperLevelSubdivisions([]);
      setLowerLevelSubdivisions(subdivisionList.filter((subdivision) => !subdivision.parentId));
    } else {
      setSelectedSubdivision(value);
      const lowerLevelSubdivisions = subdivisionList.filter((subdivision) => subdivision.parentId === value.id);

      if (lowerLevelSubdivisions.length) {
        setLowerLevelSubdivisions(lowerLevelSubdivisions);

        if (!value.parentId) {
          setUpperLevelSubdivisions([{ id: DEFAULT_EMPTY_VALUE, name: 'Все подразделения' } as ISubdivision, value]);
        } else {
          const index = upperLevelSubdivisions.findIndex((subdivision) => subdivision.id === value.id);

          if (index === DEFAULT_EMPTY_VALUE) {
            setUpperLevelSubdivisions((prev) => [...prev, value]);
          } else {
            setUpperLevelSubdivisions((prev) => [...prev.slice(0, index), value]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!!subdivisionList.length && !upperLevelSubdivisions.length) {
      setLowerLevelSubdivisions(subdivisionList.filter((subdivision) => !subdivision.parentId));
    }
  }, [subdivisionList, upperLevelSubdivisions]);

  if (!subdivisionList.length) {
    return null;
  }

  return (
    <div className="workspace-list-subdivisions">
      <div className="workspace-list-subdivisions__header" />

      <div className="workspace-list-subdivisions__body">
        <InfiniteScrollContainer>
          <div className="text-h1-drawers mb-52">Подразделения</div>

          <div className="mb-52">
            {upperLevelSubdivisions.map((subdivision, _, array) => {
              const isActive = selectedSubdivision?.id === subdivision.id;
              const isLastItem = subdivision.id === array[array.length - 1].id;

              return (
                <div
                  key={subdivision.id}
                  className={`workspace-list-subdivisions__item ${isActive ? 'active' : ''}`}
                  onClick={() => onSubdivisionClick(subdivision)}
                >
                  <div className="workspace-list-subdivisions__item-container">
                    {!!selectedSubdivision && isLastItem ? (
                      <AffiliateIcon className="icon-affiliate-dark-grey" />
                    ) : (
                      <ArrowLeftShortIcon className="icon-arrow-left-short-dark-grey" />
                    )}

                    <div className="workspace-list-subdivisions__item-label">{subdivision.name}</div>
                  </div>
                </div>
              );
            })}

            {sortedLowerLevelSubdivisions.map((subdivision) => {
              const isActive = selectedSubdivision?.id === subdivision.id;
              const lowerLevelSubdivisions = subdivisionList.filter((subdivisionItem) => {
                return subdivisionItem.parentId === subdivision.id;
              });
              const subdivisionWorkspaces = workspaceList.filter((workspace) => workspace.subdivisionId === subdivision.id);

              return (
                <div
                  key={subdivision.id}
                  className={`workspace-list-subdivisions__item ${isActive ? 'active' : ''}`}
                  onClick={() => onSubdivisionClick(subdivision)}
                >
                  <div className="workspace-list-subdivisions__item-container">
                    <div className="workspace-list-subdivisions__item-label">{subdivision.name}</div>
                  </div>

                  {!lowerLevelSubdivisions.length && (
                    <div className="text-tag color-dark-grey" style={{ marginTop: 4 }}>
                      {`${subdivisionWorkspaces.length} карт`}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </InfiniteScrollContainer>
      </div>
    </div>
  );
};
