import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { EPropertyCascaderItemType } from 'common/const/property.enum';
import { useHorizontalScroll } from 'common/hooks/useHorizontalScroll';
import { IFormValues } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { PropertyTree } from 'entities/Property/components/PropertyTree';
import { CreateSubcategoryModal } from 'entities/Modal/components/CreateSubcategoryModal';
import { PropertyCascaderItemProperties } from 'entities/Property/components/PropertyCascaderItemProperties';
import { PropertyCascaderItemOptions } from 'entities/Property/components/PropertyCascaderItemOptions';
import { PropertyCascaderItemAllProperties } from 'entities/Property/components/PropertyCascaderItemAllProperties';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PropertyCascaderComponent: FC<AllType> = (props) => {
  const {
    propertyListState,
    propertyCascaderState,
    propertyTreeState,
    selectSubcategory,
    createSubcategory,
    deleteSubcategory,
    selectCascaderItemProperty,
    subscribeCascaderItemProperty,
    unsubscribeCascaderItemProperty,
    createCascaderItemProperty,
    deleteCascaderItemProperty,
    selectCascaderItemOption,
    createCascaderItemOption,
    deleteCascaderItemOption,
    createCascaderItemWithAllProperties,
    setCascaderItemError,
  } = props;

  const { data: propertyList } = propertyListState;
  const { data: cascaderItems } = propertyCascaderState;
  const { loading: propertyTreeLoading, error: propertyTreeError } = propertyTreeState;

  const [openCreateSubcategoryModal, setOpenCreateSubcategoryModal] = useState<boolean>(false);
  const [parentCategoryId, setParentCategoryId] = useState<number | undefined>();
  const [scrollContainerLeft, setScrollContainerLeft] = useState<number | undefined>();
  const [form] = useForm();
  const { containerRef } = useHorizontalScroll([cascaderItems]);

  const handleCreateSubcategory = (id?: number) => {
    if (id) {
      setParentCategoryId(id);
    }

    setOpenCreateSubcategoryModal(true);
  };

  const onCreateSubcategoryModalCancelClick = () => {
    setOpenCreateSubcategoryModal(false);
    setParentCategoryId(undefined);
    form.resetFields();
  };

  const onCreateSubcategoryModalSubmit = ({ name }: IFormValues) => {
    createSubcategory({ name, parentId: parentCategoryId, onSuccess: onCreateSubcategoryModalCancelClick });
  };

  useEffect(() => {
    form.setFields([{ name: 'name', errors: propertyTreeError ? [propertyTreeError] : undefined }]);
  }, [propertyTreeError]);

  return (
    <div className="property-cascader">
      <PropertyTree
        propertyList={propertyList}
        selectSubcategory={selectSubcategory}
        createSubcategory={handleCreateSubcategory}
        deleteSubcategory={deleteSubcategory}
        showAllProperties={createCascaderItemWithAllProperties}
      />

      <div
        ref={containerRef}
        className="property-cascader__scroll-container"
        onScroll={() => setScrollContainerLeft(containerRef.current?.scrollLeft)}
      >
        <div className="property-cascader__container">
          {cascaderItems.map((cascaderItem) => {
            if (cascaderItem.type === EPropertyCascaderItemType.All) {
              return (
                <PropertyCascaderItemAllProperties
                  key={cascaderItem.id}
                  cascaderItem={cascaderItem}
                  propertyList={propertyList}
                  selectProperty={selectCascaderItemProperty}
                  deleteProperty={deleteCascaderItemProperty}
                />
              );
            }

            return cascaderItem.type === EPropertyCascaderItemType.Properties ? (
              <PropertyCascaderItemProperties
                key={cascaderItem.id}
                cascaderItem={cascaderItem}
                propertyList={propertyList}
                scrollContainerLeft={scrollContainerLeft}
                selectProperty={selectCascaderItemProperty}
                subscribeProperty={subscribeCascaderItemProperty}
                unsubscribeProperty={unsubscribeCascaderItemProperty}
                createProperty={createCascaderItemProperty}
                setError={setCascaderItemError}
              />
            ) : (
              <PropertyCascaderItemOptions
                key={cascaderItem.id}
                cascaderItem={cascaderItem}
                propertyList={propertyList}
                scrollContainerLeft={scrollContainerLeft}
                selectOption={selectCascaderItemOption}
                createOption={createCascaderItemOption}
                deleteOption={deleteCascaderItemOption}
                setError={setCascaderItemError}
              />
            );
          })}
        </div>
      </div>

      <CreateSubcategoryModal
        open={openCreateSubcategoryModal}
        form={form}
        loading={propertyTreeLoading}
        onCancelClick={onCreateSubcategoryModalCancelClick}
        onCreateSubmit={onCreateSubcategoryModalSubmit}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  propertyListState: state.propertyListState,
  propertyCascaderState: state.propertyCascaderState,
  propertyTreeState: state.propertyTreeState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  selectSubcategory: dispatch.propertyTreeState.selectSubcategory,
  createSubcategory: dispatch.propertyTreeState.createSubcategory,
  deleteSubcategory: dispatch.propertyTreeState.deleteSubcategory,
  selectCascaderItemProperty: dispatch.propertyCascaderState.selectCascaderItemProperty,
  subscribeCascaderItemProperty: dispatch.propertyCascaderState.subscribeCascaderItemProperty,
  unsubscribeCascaderItemProperty: dispatch.propertyCascaderState.unsubscribeCascaderItemProperty,
  createCascaderItemProperty: dispatch.propertyCascaderState.createCascaderItemProperty,
  deleteCascaderItemProperty: dispatch.propertyCascaderState.deleteCascaderItemProperty,
  selectCascaderItemOption: dispatch.propertyCascaderState.selectCascaderItemOption,
  createCascaderItemOption: dispatch.propertyCascaderState.createCascaderItemOption,
  deleteCascaderItemOption: dispatch.propertyCascaderState.deleteCascaderItemOption,
  createCascaderItemWithAllProperties: dispatch.propertyCascaderState.createCascaderItemWithAllProperties,
  setCascaderItemError: dispatch.propertyCascaderState.setCascaderItemError,
});

export const PropertyCascader = connect(mapState, mapDispatch)(PropertyCascaderComponent);
