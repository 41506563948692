import React, { FC } from 'react';
import { Button, Form, Input, Modal, Spin, FormInstance } from 'antd';
import { IFormValues } from 'common/models';
import { rules } from 'common/helpers/form.helper';
import { CHARACTER_LIMIT_MIN } from 'common/config';
import { EFormFieldMessage } from 'common/const/form.enum';

interface IComponentProps {
  open: boolean;
  form: FormInstance;
  loading: boolean;
  onCancelClick: () => void;
  onCreateSubmit: (value: IFormValues) => void;
}

export const CreateSubcategoryModal: FC<IComponentProps> = (props) => {
  const { open, form, loading, onCancelClick, onCreateSubmit } = props;

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onCreateSubmit}>
          <div className="modal-gray__body">
            <Form.Item label="Название" name="name" rules={[rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}>
              <Input placeholder="Введите название" />
            </Form.Item>
          </div>

          <div className="modal-gray__footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, getFieldError }) => {
                const name = getFieldValue('name');
                const errors = getFieldError('name');

                return (
                  <Button className="btn btn-primary" htmlType="submit" disabled={!name?.length || !!errors.length}>
                    Создать
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
