import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ERequestsNavigationTab } from 'common/const/request.enum';
import { ERoute } from 'common/const/route.enum';
import { NeedListNavigation } from 'entities/Need/components/NeedListNavigation';
import { CreatedNeedListForUser } from 'entities/Need/components/CreatedNeedListForUser';
import { CancelledNeedListForUser } from 'entities/Need/components/CancelledNeedListForUser';
import { SupplyListForUser } from 'entities/Supply/components/SupplyListForUser';

export const RequestsForUser: FC = () => {
  const { tab } = useParams();

  const isCancelledNeedList = tab === ERequestsNavigationTab.Cancelled;
  const isSupplyList = tab === ERequestsNavigationTab.SupplyList;
  const header = <div className="text-body color-dark-grey need-list__subtitle">Мои заявки</div>;
  const navigation = (
    <NeedListNavigation
      items={[
        { label: 'Все заявки', path: ERoute.RequestsForUser },
        { label: 'Отмененные заявки', path: `${ERoute.RequestsForUser}/${ERequestsNavigationTab.Cancelled}` },
        { label: 'Поставки', path: `${ERoute.RequestsForUser}/${ERequestsNavigationTab.SupplyList}` },
      ]}
    />
  );

  return isCancelledNeedList ? (
    <CancelledNeedListForUser header={header} navigation={navigation} />
  ) : isSupplyList ? (
    <SupplyListForUser header={header} navigation={navigation} />
  ) : (
    <CreatedNeedListForUser header={header} navigation={navigation} />
  );
};
