import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { ITEMS_FOR_VIEW_LIMIT_11 } from 'common/config';
import { ReactComponent as ArrowRightLongIcon } from 'app/assets/images/redesign/arrow-right-long.svg';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ICategory } from 'entities/Categories/Categories.models';
import { CatalogCategoryCard } from 'entities/Catalog/components/CatalogCategoryCard';
import { CatalogCategoryListSkeleton } from 'entities/Catalog/components/CatalogCategoryListSkeleton';

interface IComponentProps {
  loading: boolean;
  collapsible: boolean;
  categoryList: ICategory[];
}

export const CatalogCategoryList: FC<IComponentProps> = ({ loading, collapsible, categoryList }) => {
  const [listIsCollapsed, setListIsCollapsed] = useState<boolean>(true);
  const { onCategoryChange } = useCatalogContext();

  const visibleCategoryList = collapsible && listIsCollapsed ? categoryList.slice(0, ITEMS_FOR_VIEW_LIMIT_11) : categoryList;
  const hiddenCategoriesCount = categoryList.length - visibleCategoryList.length;
  const showToggle = categoryList.length > ITEMS_FOR_VIEW_LIMIT_11 && collapsible;

  const toggleListIsCollapsed = () => {
    setListIsCollapsed((prev) => !prev);
  };

  useEffect(() => {
    setListIsCollapsed(true);
  }, [categoryList]);

  if (loading) {
    return <CatalogCategoryListSkeleton />;
  }

  if (!categoryList.length) {
    return null;
  }

  return (
    <div className="redesign catalog-category-list">
      {visibleCategoryList.map((category) => {
        return <CatalogCategoryCard key={category.id} category={category} onCategoryClick={onCategoryChange} />;
      })}

      {showToggle &&
        (hiddenCategoriesCount > 0 ? (
          <Button className="catalog-category-list-toggle" onClick={toggleListIsCollapsed}>
            <span className="text-h4 color-bright-green" style={{ zIndex: 1 }}>{`Еще ${hiddenCategoriesCount} категорий`}</span>

            <div className="catalog-category-list-toggle-arrow">
              <ArrowRightLongIcon className="icon-arrow-right-long-bright-green" />
            </div>
          </Button>
        ) : (
          <Button className="catalog-category-list-toggle" onClick={toggleListIsCollapsed}>
            <div className="catalog-category-list-toggle-arrow">
              <ArrowLeftLongIcon className="icon-arrow-left-long-bright-green" />
            </div>

            <span className="text-h4 color-bright-green" style={{ zIndex: 1 }}>
              Показать меньше
            </span>
          </Button>
        ))}
    </div>
  );
};
