import React, { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { NeedProvider } from 'common/hooks/useNeedContext';
import { SupplyProvider } from 'common/hooks/useSupplyContext';
import { ERoute } from 'common/const/route.enum';
import { EUserRole } from 'common/const/user.enum';
import { GoodsProvider } from 'common/hooks/useGoodsContext';
import { RequestsForManager } from 'entities/Requests/components/RequestsForManager';
import { RequestsForUser } from 'entities/Requests/components/RequestsForUser';
import { RequestsForSeller } from 'entities/Requests/components/RequestsForSeller';

export const Requests: FC = () => {
  const { role } = useParams();

  const renderContent = () => {
    switch (role) {
      case EUserRole.SellerManager: {
        return <RequestsForSeller />;
      }
      case EUserRole.Manager: {
        return <RequestsForManager />;
      }
      case EUserRole.User: {
        return <RequestsForUser />;
      }
      default: {
        return <Navigate to={ERoute.Profile} />;
      }
    }
  };

  return (
    <NeedProvider>
      <SupplyProvider>
        <GoodsProvider>
          <div className="redesign need-list">{renderContent()}</div>
        </GoodsProvider>
      </SupplyProvider>
    </NeedProvider>
  );
};
