import axios from 'axios';
import {
  IGoodCollectionDto,
  IGoodsListPayload,
  IGoods,
  IGoodsCreatePayload,
  IGoodsParams,
  IGoodsUpdatePayload,
} from 'entities/Goods/Goods.models';

const basePath = 'good';

export const goodsTransport = {
  getGoodsList: (payload: IGoodsListPayload): Promise<IGoodCollectionDto> => axios.post(`${basePath}/list`, payload),
  getGoods: ({ id }: IGoodsParams): Promise<IGoods> => axios.get(`${basePath}/${id}`),
  createGoods: (payload: IGoodsCreatePayload): Promise<IGoods> => axios.post(`${basePath}`, payload),
  updateGoods: (payload: IGoodsUpdatePayload): Promise<IGoods> => axios.patch(`${basePath}`, payload),
};
