export enum ERequestsNavigationTab {
  Cancelled = 'cancelled',
  SupplyList = 'supply-list',
}

export enum ERequestsMenuTab {
  Created = 'created',
  Processing = 'processing',
  Pending = 'pending',
}
