import React from 'react';
import type { DataNode } from 'antd/es/tree';
import { EPropertyType } from 'common/const/property.enum';
import { EPresetType } from 'common/const/preset.enum';
import { formValuesToPropertiesPayload } from 'common/helpers/properties.helper';
import { IFormValues } from 'common/models';
import { getPresetLabel } from 'common/helpers/preset.helper';
import { IProperty } from 'entities/Property/Property.models';
import {
  IWorkspace,
  IWorkspaceCatalogCategory,
  IWorkspacePosition,
  IWorkspacePositionProperty,
} from 'entities/Workspaces/Workspaces.models';

export const workspaceCatalogToTree = (categories?: IWorkspaceCatalogCategory[]): DataNode[] => {
  if (!categories) {
    return [];
  }

  return categories.map((category) => ({
    title: category.categoryName,
    key: category.categoryId,
    children: workspaceCatalogToTree(category.children),
    icon: <div>{category.positionsCount}</div>,
  }));
};

export const workspaceCatalogToList = (categories?: IWorkspaceCatalogCategory[]) => {
  const results: DataNode[] = [];

  const getWorkspaceCategoryWithEmptyChild = (category: IWorkspaceCatalogCategory) => {
    if (category.children && !category.children.length) {
      results.push({
        title: category.categoryName,
        key: category.categoryId,
        icon: <div>{category.positionsCount}</div>,
      });
    }

    if (category.children && category.children.length > 0) {
      category.children.forEach((child: IWorkspaceCatalogCategory) => {
        getWorkspaceCategoryWithEmptyChild(child);
      });
    }
  };

  categories?.forEach((category: IWorkspaceCatalogCategory) => {
    getWorkspaceCategoryWithEmptyChild(category);
  });

  return results;
};

const filterWorkspacePositionPropertyList = (propertyList: IWorkspacePositionProperty[]) => {
  return {
    numberType: propertyList.filter(({ propertyType }) => propertyType === EPropertyType.Number),
    stringType: propertyList.filter(({ propertyType }) => propertyType !== EPropertyType.Number),
  };
};

const workspacePositionPropertyToPreset = (property: IWorkspacePositionProperty) => {
  const { presetType, propertyName, values, range, unitOfMeasurement } = property;

  return getPresetLabel({ type: presetType, name: propertyName, values, range, unitOfMeasurement }) as string;
};

export const getPositionName = (position: IWorkspacePosition | null) => {
  const list: string[] = [];

  if (position) {
    Object.values(EPresetType).forEach((value) => {
      const sortedPropertyList = position.properties
        .filter((property) => property.presetType === value)
        .sort((a, b) => a.propertyId - b.propertyId);

      switch (value) {
        case EPresetType.SingleValue: {
          const { numberType, stringType } = filterWorkspacePositionPropertyList(sortedPropertyList);

          list.push(...stringType.map(workspacePositionPropertyToPreset));
          list.push(...numberType.map(workspacePositionPropertyToPreset));
          break;
        }
        case EPresetType.MultipleValue: {
          const { numberType, stringType } = filterWorkspacePositionPropertyList(sortedPropertyList);

          list.push(...numberType.map(workspacePositionPropertyToPreset));
          list.push(...stringType.map(workspacePositionPropertyToPreset));
          break;
        }
        case EPresetType.Range:
        case EPresetType.Checkbox: {
          list.push(...sortedPropertyList.map(workspacePositionPropertyToPreset));
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  return list.join(', ');
};

export const getWorkspacePayload = {
  createPosition: (workspace: IWorkspace, categoryId: number, formValues: IFormValues, propertyList: IProperty[]) => {
    return {
      id: workspace.id,
      positions: [
        ...workspace.positions.map((position) => {
          return {
            categoryId: position.categoryId,
            properties: position.properties.map((property) => {
              return {
                propertyId: property.propertyId,
                values: property.values,
                range: property.range,
              };
            }),
          };
        }),
        {
          categoryId,
          properties: formValuesToPropertiesPayload(formValues, propertyList),
        },
      ],
    };
  },
  updatePosition: (workspace: IWorkspace, positionId: number, formValues: IFormValues, propertyList: IProperty[]) => {
    return {
      id: workspace.id,
      positions: workspace.positions.map((position) => {
        if (position.positionId === positionId) {
          return {
            categoryId: position.categoryId,
            properties: formValuesToPropertiesPayload(formValues, propertyList),
          };
        }

        return { categoryId: position.categoryId, properties: position.properties };
      }),
    };
  },
  deletePosition: (workspace: IWorkspace, positionId: number) => {
    return {
      id: workspace.id,
      positions: workspace.positions
        .filter((position) => position.positionId !== positionId)
        .map((position) => ({ categoryId: position.categoryId, properties: position.properties })),
    };
  },
};
