import React, { FC, useEffect, useRef } from 'react';
import { EScrollDirection } from 'common/const/common.enum';

interface IComponentProps {
  canLoad?: boolean;
  children: React.ReactNode;
  scrollToTopTrigger?: any[];
  onLoad?: () => void;
  setScrollDirection?: (value: EScrollDirection) => void;
}

export const InfiniteScrollContainer: FC<IComponentProps> = (props) => {
  const { canLoad, children, scrollToTopTrigger = [], onLoad, setScrollDirection } = props;

  const scrollRef = useRef<HTMLDivElement>(null);

  let previousScrollTop = 0;

  const handleScroll = () => {
    if (!scrollRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    if (scrollTop > previousScrollTop) {
      setScrollDirection?.(EScrollDirection.DOWN);
    } else if (scrollTop < previousScrollTop) {
      setScrollDirection?.(EScrollDirection.UP);
    }

    previousScrollTop = scrollTop;

    if (scrollHeight - scrollTop <= clientHeight + 100 && canLoad) {
      onLoad?.();
    }
  };

  useEffect(() => {
    const currentRef = scrollRef.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [canLoad]);

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: 0 });
    previousScrollTop = 0;
  }, [...scrollToTopTrigger]);

  return (
    <div ref={scrollRef} className="infinite-scroll-container">
      {children}
    </div>
  );
};
