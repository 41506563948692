import { DataNode } from 'antd/es/tree';
import { dataToTree } from 'common/helpers/tree.helper';
import { IFormValues } from 'common/models';
import { EPropertyType } from 'common/const/property.enum';
import { ICategory, ICategoryCascaderOption, ICategoryPosition } from 'entities/Categories/Categories.models';
import { IProperty } from 'entities/Property/Property.models';
import { ICategoryDetailed } from 'entities/Categories/Categories.models';
import { IPresetRange } from 'entities/Presets/Preset.models';
import { findPropertyParentIds } from 'entities/Property/Property.helper';

export const sortCategoryListByDisplayName = (categoryList?: ICategory[]) => {
  return categoryList?.sort((a, b) => a.displayName.localeCompare(b.displayName));
};

export const propertyListToCategoryTemplateTreeData = (propertyList?: IProperty[]) => {
  const propertyCategoryList = propertyList?.filter((property) => property.isCategory);

  return dataToTree<IProperty>(propertyCategoryList).map((parentItem) => ({ ...parentItem, disabled: true }));
};

export const getCategoryTemplate = (category: ICategoryDetailed | null, propertyList?: IProperty[]) => {
  return propertyList?.find((property) => {
    return (
      property.isCategory &&
      category?.positions.some((position) => {
        return position.propertyId === property.parentId && position.values?.includes(property.displayName);
      })
    );
  });
};

export const searchPropertyCategories = (treeData: DataNode[], searchString: string): DataNode[] => {
  const result: DataNode[] = [];

  function search(node: DataNode) {
    if (node.children?.length === 0) {
      return;
    }

    node.children?.forEach((child) => {
      if ((child.title as string).toLowerCase().includes(searchString)) {
        result.push(child);
      }

      search(child);
    });
  }

  treeData.forEach((node) => {
    if ((node.title as string).toString().includes(searchString)) {
      result.push(node);
    }

    search(node);
  });

  return result.map(({ key, title }) => ({ key, title }));
};

export const getGroupingPropertyOptions = (conditionProperties: IProperty[], propertiesToDisplayIds: number[]) => {
  return conditionProperties
    .filter((property) => propertiesToDisplayIds.includes(property.id))
    .map((property) => ({ label: property.displayName, value: property.id }));
};

export const getPropertyListForCategoryFilter = (
  conditionPropertyList: IProperty[],
  propertyList: IProperty[],
  template?: IProperty,
) => {
  if (!template) {
    return [];
  }

  const propertyParentIds = findPropertyParentIds(template.id, propertyList);
  const parents = propertyList.filter((property) => propertyParentIds.includes(property.id));

  return [...parents.reverse(), ...conditionPropertyList];
};

export const categoryPositionsToCategoryFilterFormValues = (positions?: ICategoryPosition[]) => {
  if (!positions) {
    return {};
  }

  const formValues: IFormValues = {};

  positions.forEach((position) => {
    formValues[position.propertyId] = position.range ? [position.range] : position.values;
  });

  return formValues;
};

export const getCategoryFilterDescription = (positions?: ICategoryPosition[], propertyList?: IProperty[]) => {
  if (!positions || !propertyList) {
    return '';
  }

  const descriptionItems: string[] = [];

  positions.forEach((position) => {
    const property = propertyList.find((propertyItem) => propertyItem.id === position.propertyId);

    if (property) {
      const { type, displayName, unitOfMeasurement } = property;
      const unitOfMeasurementString = unitOfMeasurement ? `, ${unitOfMeasurement}` : '';

      if (type === EPropertyType.Label || type === EPropertyType.List) {
        descriptionItems.push(`${displayName}${unitOfMeasurementString}: ${position.values?.join(',')}`);
      } else {
        const { from, to } = position.range as IPresetRange;
        // eslint-disable-next-line
        const rangeString = `${from ? `от ${from}` : ''}${to ? ` до ${to}` : ''}`;

        descriptionItems.push(`${displayName}${unitOfMeasurementString}: ${rangeString}`);
      }
    }
  });

  return descriptionItems.join(', ');
};

export const categoryListToCascaderOptions = <T extends { id: number; name: string; parentId?: number }>(data: T[]) => {
  const itemMap = new Map<number, ICategoryCascaderOption>();

  // Create a map of elements by element id
  data.forEach((item) => {
    const transformedItem: ICategoryCascaderOption = {
      value: item.id,
      label: item.name,
      children: [],
    };

    itemMap.set(item.id, transformedItem);
  });

  const options: ICategoryCascaderOption[] = [];

  // Building a options using parentId
  data.forEach((item) => {
    const transformedItem = itemMap.get(item.id);

    if (item.parentId === null && transformedItem) {
      // Top level element, add it to the result
      options.push({ ...transformedItem });
    } else {
      // Find the parent and add the current element to its children
      const parent = itemMap.get(item.parentId as number);

      if (parent && transformedItem) {
        parent.children?.push(transformedItem);
      }
    }
  });

  return options;
};

export const findCategoryTemplateConditionProperties = (propertyList: IProperty[], templateId?: number) => {
  if (!templateId) {
    return [];
  }

  const templateParentIds = findPropertyParentIds(templateId, propertyList);

  const conditionProperties = [templateId, ...templateParentIds]
    .map((propertyId) => {
      const property = propertyList.find((propertyItem) => propertyItem.id === propertyId) as IProperty;

      return propertyList.filter((propertyItem) => {
        return (
          propertyItem.showCondition &&
          propertyItem.showCondition.some((condition) => {
            return condition.propertyId === property.parentId && condition.values.includes(property.displayName);
          })
        );
      });
    })
    .flat();

  return [...new Set(conditionProperties)];
};

export const generateCategoryPositions = (propertyList: IProperty[], template?: IProperty) => {
  if (!template) {
    return [];
  }

  const positions: ICategoryPosition[] = [];

  if (template.parentId) {
    const parent = propertyList.find((property) => property.id === template.parentId);

    positions.push({ propertyId: parent?.id as number, values: [template.displayName] });
    positions.push(...generateCategoryPositions(propertyList, parent));
  }

  return positions;
};
