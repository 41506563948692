import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EFormFieldMessage } from 'common/const/form.enum';
import { rules } from 'common/helpers/form.helper';
import { PASSWORD_PATTERN } from 'common/config';
import { ReactComponent as EyeOffIcon } from 'app/assets/images/redesign/eyeOff.svg';
import { ReactComponent as EyeOnIcon } from 'app/assets/images/redesign/eyeOn.svg';
import { ReactComponent as SuccessIcon } from 'app/assets/images/redesign/success.svg';
import { passwordRequirements } from 'entities/User/User.const';
import { IUserChangePasswordPayload } from 'entities/User/User.models';

interface IComponentProps {
  open: boolean;
  error: string | null;
  loading: boolean;
  onClose: () => void;
  onConfirm: (value: IUserChangePasswordPayload) => void;
}

export const ChangePasswordDrawer: FC<IComponentProps> = ({ open, error, loading, onClose, onConfirm }) => {
  const [validations, setValidations] = useState<{ [key: string]: boolean }>({});
  const [newPasswordIsFocused, setNewPasswordIsFocused] = useState<boolean>(false);
  const [form] = useForm();

  useEffect(() => {
    if (error) {
      form.setFields([{ name: 'currentPassword', errors: [error] }]);
    }
  }, [error]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setValidations({});
    }
  }, [open]);

  return (
    <Drawer rootClassName="redesign drawer" open={open} onClose={loading ? undefined : onClose} width={720} destroyOnClose>
      <div className="drawer__title">Изменение пароля</div>

      <div className="drawer__body">
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          onFinish={({ currentPassword, newPassword }) => onConfirm({ currentPassword, newPassword })}
        >
          <Form.Item className="mb-32" label="Cтарый пароль">
            <Form.Item className="mb-0" name="currentPassword" rules={[rules.required()]}>
              <Input.Password
                placeholder={EPlaceholder.EnterOldPassword}
                iconRender={(visible) => (visible ? <EyeOnIcon className="icon-eye" /> : <EyeOffIcon className="icon-eye" />)}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item className="mb-32" label="Новый пароль">
            <Form.Item
              className="mb-0"
              name="newPassword"
              dependencies={['currentPassword']}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                rules.required(),
                rules.pattern(PASSWORD_PATTERN, EFormFieldMessage.InvalidPasswordFormat, 'onBlur'),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('currentPassword') !== value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(EFormFieldMessage.PasswordsMatch));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={EPlaceholder.EnterNewPassword}
                iconRender={(visible) => (visible ? <EyeOnIcon /> : <EyeOffIcon />)}
                onChange={(e) => {
                  const newValidations: { [key: string]: boolean } = {};

                  passwordRequirements.forEach((requirement) => {
                    newValidations[requirement.id] = requirement.regex.test(e.target.value);
                  });

                  setValidations(newValidations);
                }}
                onFocus={() => setNewPasswordIsFocused(true)}
                onBlur={() => setNewPasswordIsFocused(false)}
              />
            </Form.Item>

            {newPasswordIsFocused && (
              <div className="password-requirements">
                {passwordRequirements.map((requirement) => {
                  return (
                    <div
                      key={requirement.id}
                      className={`password-requirements__item ${validations[requirement.id] ? 'checked' : ''}`}
                    >
                      <SuccessIcon />

                      <span className="password-requirements__item-label">{requirement.label}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </Form.Item>

          <Form.Item
            label="Подтвердите пароль"
            name="passwordConfirm"
            dependencies={['newPassword']}
            rules={[
              rules.required(),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(EFormFieldMessage.PasswordMismatch));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={EPlaceholder.RepeatNewPassword}
              iconRender={(visible) => (visible ? <EyeOnIcon /> : <EyeOffIcon />)}
            />
          </Form.Item>
        </Form>
      </div>

      <div className="drawer__footer">
        <Button className="button-l primary" onClick={() => form.submit()} loading={loading}>
          Изменить пароль
        </Button>

        <Button className="button-l secondary" onClick={onClose} disabled={loading}>
          Отмена
        </Button>
      </div>
    </Drawer>
  );
};
