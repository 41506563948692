import React, { FC } from 'react';
import { Preset } from 'common/components/Preset';
import { ReactComponent as DocumentIcon } from 'app/assets/images/redesign/document.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { ReactComponent as PackageIcon } from 'app/assets/images/redesign/package.svg';
import { IWorkspaceCategory } from 'entities/Workspace/Workspace.models';

interface IComponentProps {
  group: IWorkspaceCategory;
}

export const WorkspacePositionGroup: FC<IComponentProps> = ({ group }) => {
  const sortedPositions = group.positions.sort((a, b) => a.properties.length - b.properties.length);

  if (!sortedPositions.length) {
    return null;
  }

  return (
    <div key={group.categoryId} className="workspace-group">
      <div className="workspace-group__name-container">
        <div className="text-h4-item-name workspace-group__name">{group.categoryName}</div>
      </div>

      {sortedPositions.map((position) => {
        return (
          <div key={position.positionId} className="workspace-group__position">
            <div className="workspace-group__position-header">
              <DocumentIcon className="icon-document-dark-grey" />

              <span className="text-controls-accent" style={{ flex: 1 }}>
                {position.categoryName}
              </span>

              <div className="workspace-group__position-availability">
                <PackageIcon className="icon-package-bright-green" />

                <span className="text-tag-accent">В наличии: 12 из 12</span>
              </div>

              <OptionsIcon className="icon-options-dark-grey" />
            </div>

            <div className="workspace-group__position-storage-standard">
              <PackageIcon className="icon-package-dark-grey" />

              <span className="text-tag-accent color-dark-grey">Норма хранения: 12 шт</span>
            </div>

            <div className="workspace-group__position-presets">
              {position.properties.map((property) => {
                return <Preset key={property.propertyId} item={property} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
