import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { EFormFieldMessage } from 'common/const/form.enum';
import { ERoute } from 'common/const/route.enum';
import { EUserRole } from 'common/const/user.enum';
import { FormInputPassword } from 'common/components/Form/FormInputPassword';
import { PASSWORD_PATTERN } from 'common/config';
import { rules } from 'common/helpers/form.helper';
import { RootDispatch, RootState } from 'app/store';
import { AuthCard } from 'entities/Auth/components/AuthCard';
import { IRestorePasswordPayload } from 'entities/Auth/Auth.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const RestorePasswordComponent: React.FC<AllType> = ({ authState, restorePassword, setAuthError }) => {
  const { data, loading, error } = authState;
  const [form] = useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const hash = searchParams.get('hash');
  const roles = data?.access.roles;

  const onCancelClick = () => {
    setAuthError(null);
    navigate(ERoute.Login, { replace: true });
  };

  const onSubmit = (values: IRestorePasswordPayload) => {
    setAuthError(null);

    restorePassword({ hash: hash as string, password: values.password });
  };

  if (data) {
    return roles?.includes(EUserRole.SuperAdmin) ? (
      <Navigate to={ERoute.AccountList} replace />
    ) : (
      <Navigate to={ERoute.Profile} replace />
    );
  }

  return (
    <div className="auth">
      <AuthCard
        form={form}
        loading={loading}
        body={
          <>
            <FormInputPassword
              label="Новый пароль"
              name="password"
              placeholder="Придумайте пароль"
              rules={[rules.required(), rules.pattern(PASSWORD_PATTERN, EFormFieldMessage.InvalidPasswordFormat)]}
            />

            <FormInputPassword
              name="passwordConfirm"
              placeholder="Введите пароль еще раз"
              dependencies={['password']}
              rules={[
                rules.required(),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(EFormFieldMessage.PasswordMismatch));
                  },
                }),
              ]}
            />

            {!!error && (
              <div className="auth-card__error-block">
                <span className="auth-card__error-block-item">{error}</span>
              </div>
            )}
          </>
        }
        footer={
          <>
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" htmlType="submit">
              Изменить пароль
            </Button>
          </>
        }
        onSubmit={onSubmit}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  restorePassword: dispatch.authState.restorePassword,
  setAuthError: dispatch.authState.setAuthError,
});

export const RestorePassword = connect(mapState, mapDispatch)(RestorePasswordComponent);
