import React, { ChangeEvent, useState } from 'react';
import { Button } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { EMessage } from 'common/const/message.enum';
import { ENotificationType } from 'common/const/notification.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { useDebounce } from 'common/hooks/useDebounce';
import { LIST_LIMIT_10 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { SelectUserModal } from 'entities/Modal/components/SelectUserModal';
import { IUser } from 'entities/User/User.models';
import { InviteUserModal } from 'entities/Modal/components/InviteUserModal';
import { IInvitationCreatePayload } from 'entities/Invitation/Invitation.models';
import { Notification } from 'entities/Modal/components/Notification';

interface IComponentProps {
  subdivisionId?: number;
  updateSubdivisionUserCollection: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CreateSubdivisionUserComponents: React.FC<AllType> = (props) => {
  const {
    subdivisionId,
    accountUserListState,
    userState,
    invitationState,
    selectUserModalParams,
    inviteUserModalParams,
    changeSubdivisionUserModalParams,
    updateSubdivisionUserCollection,
    getAccountUserList,
    updateUserByAdmin,
    inviteUser,
    setInvitationError,
    showModal,
    hideModal,
  } = props;
  const { data: accountUserList, loading: accountUserListLoading } = accountUserListState;
  const { currentUser, loading: userLoading } = userState;
  const { loading: invitationLoading, error: invitationError } = invitationState;
  const { open: openSelectUserModal } = selectUserModalParams;
  const { open: openInviteUserModal } = inviteUserModalParams;
  const { open: openChangeSubdivisionUserModal } = changeSubdivisionUserModalParams;

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [form] = useForm();

  const accountId = currentUser?.accountId;

  const onSelectUserModalSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedUser(null);
    setSearchValue(e.target.value);
  };

  const onSelectUserModalSelect = (value: IUser) => {
    setSelectedUser(value);
  };

  const onSelectUserModalCancelClick = () => {
    setSelectedUser(null);
    setSearchValue('');
    hideModal(EModalCollectionKey.SelectUser);
  };

  const onSelectUserModalInviteClick = () => {
    setSearchValue('');
    hideModal(EModalCollectionKey.SelectUser);
    showModal(EModalCollectionKey.InviteUser);
  };

  const updateUser = () => {
    updateUserByAdmin({
      id: selectedUser?.id as number,
      subdivisionId,
      onSuccess: () => {
        setSelectedUser(null);
        setSearchValue('');
        hideModal(EModalCollectionKey.SelectUser);
        updateSubdivisionUserCollection();
      },
    });
  };

  // If the user is assigned to a subdivision (has an subdivisionId), show the message, otherwise, update it
  const onSelectUserAddClick = () => {
    if (selectedUser?.subdivisionId) {
      showModal(EModalCollectionKey.ChangeSubdivisionUser);
    } else {
      updateUser();
    }
  };

  const onInviteUserModalCancelClick = () => {
    setInvitationError(null);
    form.resetFields();
    hideModal(EModalCollectionKey.InviteUser);
  };

  const onInviteUserSubmit = (values: IInvitationCreatePayload) => {
    setInvitationError(null);

    inviteUser({
      ...values,
      subdivisionId,
      onSuccess: () => {
        form.resetFields();
        hideModal(EModalCollectionKey.InviteUser);
        updateSubdivisionUserCollection();
      },
    });
  };

  const onChangeUserSubdivisionModalCancelClick = () => {
    hideModal(EModalCollectionKey.ChangeSubdivisionUser);
  };

  const onChangeUserSubdivisionModalConfirmClick = () => {
    hideModal(EModalCollectionKey.ChangeSubdivisionUser);
    updateUser();
  };

  useDebounce(() => {
    if (accountId && openSelectUserModal) {
      getAccountUserList({
        accountId,
        excludeSubdivisionId: subdivisionId,
        search: searchValue,
        limit: LIST_LIMIT_10,
        orderField: 'lastName',
        orderDirection: 'ASC',
      });
    }
  }, [accountId, openSelectUserModal, searchValue]);

  return (
    <>
      <Button className="btn btn-primary" onClick={() => showModal(EModalCollectionKey.SelectUser)}>
        Добавить пользователя
      </Button>

      <SelectUserModal
        title="Добавить пользователя"
        open={openSelectUserModal}
        users={accountUserList}
        updateLoading={userLoading}
        searchLoading={accountUserListLoading}
        searchValue={searchValue}
        selectedUser={selectedUser}
        emptyText={EMessage.InviteNewUser}
        onSearch={onSelectUserModalSearch}
        onSelect={onSelectUserModalSelect}
        onCancelClick={onSelectUserModalCancelClick}
        onAddUserClick={onSelectUserAddClick}
        onInviteClick={onSelectUserModalInviteClick}
      />

      <InviteUserModal
        open={openInviteUserModal}
        form={form}
        loading={invitationLoading}
        error={invitationError}
        onSubmit={onInviteUserSubmit}
        onCancelClick={onInviteUserModalCancelClick}
      />

      <Notification
        open={openChangeSubdivisionUserModal}
        type={ENotificationType.Notification}
        description={`Пользователь уже привязан к “${selectedUser?.subdivision?.name}” подразделению. Вы уверены, что хотите изменить назначение?`}
        loading={false}
        onConfirmClick={onChangeUserSubdivisionModalConfirmClick}
        onCancelClick={onChangeUserSubdivisionModalCancelClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  accountUserListState: state.accountUserListState,
  userState: state.userState,
  invitationState: state.invitationState,
  selectUserModalParams: state.modalCollection.selectUserModalParams,
  inviteUserModalParams: state.modalCollection.inviteUserModalParams,
  changeSubdivisionUserModalParams: state.modalCollection.changeSubdivisionUserModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAccountUserList: dispatch.accountUserListState.getAccountUserList,
  updateUserByAdmin: dispatch.userState.updateUserByAdmin,
  inviteUser: dispatch.invitationState.inviteUser,
  setInvitationError: dispatch.invitationState.setInvitationError,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const CreateSubdivisionUser = connect(mapState, mapDispatch)(CreateSubdivisionUserComponents);
