import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Modal, Spin } from 'antd';
import { NUMBER_PATTERN } from 'common/config';

interface IComponentProps {
  title: string;
  suffix?: string;
  limit?: number;
  loading: boolean;
  onSet: (value: number | null, onSuccess: () => void) => void;
}

export const SubdivisionLimit: React.FC<IComponentProps> = ({ title, suffix, limit, loading, onSet }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number | null>(null);

  const onCancelClick = () => {
    setValue(null);
    setOpen(false);
  };

  const onSetClick = () => {
    onSet(value, onCancelClick);
  };

  useEffect(() => {
    if (open && limit) {
      setValue(limit);
    }
  }, [limit, open]);

  return (
    <div className="subdivision-info__field">
      <div className="subdivision-info__label">{title}</div>

      <div className="subdivision-info__field-container">
        <InputNumber value={limit} placeholder="Установите лимит" suffix={suffix} disabled />

        <Button className="btn btn-primary" onClick={() => setOpen(true)}>
          Изменить
        </Button>
      </div>

      <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
        <Spin spinning={loading}>
          <div className="modal-gray__body">
            <div className="modal-gray__title">Сумма</div>

            <InputNumber
              value={value}
              onChange={(value) => setValue(value)}
              min={0}
              controls={false}
              placeholder="Установите лимит"
              onKeyDown={(e) => {
                const regexp = NUMBER_PATTERN;

                if (!regexp.test(e.key) && e.key !== 'Backspace') {
                  e.preventDefault();
                }
              }}
            />
          </div>

          <div className="modal-gray__footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" onClick={onSetClick}>
              Установить
            </Button>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
