import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { IBrandListState } from 'entities/Brand/Brand.models';
import { brandTransport } from 'entities/Brand/Brand.transport';

export const brandListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: true,
  } as IBrandListState,
  reducers: {
    setBrandList: (state, payload: string[]) => ({ ...state, data: payload }),
    setBrandListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getBrandList() {
      dispatch.brandListState.setBrandListLoading(true);

      try {
        const response = await brandTransport.getBrandList();
        dispatch.brandListState.setBrandList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.brandListState.setBrandListLoading(false);
      }
    },
  }),
});
