import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Spin, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { EmptyList } from 'common/components/EmptyList';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { showMessage } from 'common/helpers/message.helper';
import { ECreateWorkspaceDrawerMode } from 'common/const/workspace.enum';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { WorkspaceListGroup } from 'entities/Workspace/components/WorkspaceListGroup';
import { WorkspaceListSubdivisions } from 'entities/Workspace/components/WorkspaceListSubdivisions';
import { CreateWorkspaceDrawer } from 'entities/Workspace/components/CreateWorkspaceDrawer';
import { groupWorkspaceList } from 'entities/Workspace/Workspace.helper';
import { IWorkspace, IWorkspaceListParams } from 'entities/Workspace/Workspace.models';
import { ISubdivision } from 'entities/Subdivisions/Subdivisions.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    workspaceList,
    workspaceListLoading,
    subdivisionList,
    userList,
    auth,
    workspaceLoading,
    // dispatch
    getWorkspaceList,
    getSubdivisionList,
    getUserList,
    deleteWorkspace,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [openCreateWorkspaceDrawer, setOpenCreateWorkspaceDrawer] = useState<boolean>(false);
  const [openDeleteWorkspaceDrawer, setOpenDeleteWorkspaceDrawer] = useState<boolean>(false);
  const [createWorkspaceDrawerMode, setCreateWorkspaceDrawerMode] = useState<ECreateWorkspaceDrawerMode>(
    ECreateWorkspaceDrawerMode.Create,
  );
  const [params, setParams] = useState<IWorkspaceListParams>({ limit: LIST_LIMIT_0 });
  const [selectedSubdivision, setSelectedSubdivision] = useState<ISubdivision | null>(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace | null>(null);

  const accountId = auth?.access.accountId;
  const groupedWorkspaceList = groupWorkspaceList(workspaceList);
  const filteredGroupedWorkspaceList = groupedWorkspaceList.filter((group) => {
    return selectedSubdivision ? group.subdivisionId === selectedSubdivision.id : true;
  });

  const onSearchClick = () => {
    const newParams = { ...params, name: search };

    setParams(newParams);
    getWorkspaceList(newParams);
  };

  const onCreateWorkspaceClick = () => {
    setCreateWorkspaceDrawerMode(ECreateWorkspaceDrawerMode.Create);
    setOpenCreateWorkspaceDrawer(true);
  };

  const onCopyWorkspaceClick = (value: IWorkspace) => {
    setSelectedWorkspace(value);
    setCreateWorkspaceDrawerMode(ECreateWorkspaceDrawerMode.Copy);
    setOpenCreateWorkspaceDrawer(true);
  };

  const onEditWorkspaceClick = (value: IWorkspace) => {
    setSelectedWorkspace(value);
    setCreateWorkspaceDrawerMode(ECreateWorkspaceDrawerMode.Edit);
    setOpenCreateWorkspaceDrawer(true);
  };

  const onDeleteWorkspaceClick = (value: IWorkspace) => {
    setSelectedWorkspace(value);
    setOpenDeleteWorkspaceDrawer(true);
  };

  const onDeleteWorkspaceConfirm = () => {
    if (selectedWorkspace) {
      deleteWorkspace({
        id: selectedWorkspace.id,
        onSuccess: () => {
          setOpenDeleteWorkspaceDrawer(false);
          setSelectedWorkspace(null);
          showMessage({ content: 'Карта оснащения удалена из подразделения.', icon: <BinIcon className="icon-bin-dark-grey" /> });
        },
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await getWorkspaceList(params);
      await getSubdivisionList({ limit: LIST_LIMIT_0 });
      await getUserList({ accountId, limit: LIST_LIMIT_0 });
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <Spin wrapperClassName="workspace-list__spin" spinning={fetchLoading} indicator={<SpinIndicator />}>
      <div className="redesign workspace-list">
        <div className="workspace-list__content">
          <div className="workspace-list__search">
            <Input
              placeholder="Поиск по картам оснащения"
              allowClear={{ clearIcon: <CloseIcon className="icon-close-dark-grey" /> }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />

            <Tooltip title="Добавить новую карту" placement="bottom">
              <Button className="button-circle primary" icon={<PlusIcon />} onClick={onCreateWorkspaceClick} />
            </Tooltip>
          </div>

          <InfiniteScrollContainer>
            <Spin wrapperClassName="workspace-list__spin" spinning={workspaceListLoading} indicator={<SpinIndicator />}>
              <div className="workspace-list__content-container">
                <div className="text-h1 workspace-list__title">Карты оснащения</div>

                {filteredGroupedWorkspaceList.map((group) => (
                  <WorkspaceListGroup
                    key={group.subdivisionId}
                    group={group}
                    onCopyWorkspaceClick={onCopyWorkspaceClick}
                    onEditWorkspaceClick={onEditWorkspaceClick}
                    onDeleteWorkspaceClick={onDeleteWorkspaceClick}
                  />
                ))}

                <EmptyList
                  open={!workspaceListLoading && !filteredGroupedWorkspaceList.length}
                  width={730}
                  title="Карты оснащения отсутствуют"
                  description="Создайте карты для сотрудников, чтобы зафиксировать, какие ценности могут использоваться в работе"
                  btnTitle="Создать карту"
                  onClick={onCreateWorkspaceClick}
                />
              </div>
            </Spin>
          </InfiniteScrollContainer>
        </div>

        <WorkspaceListSubdivisions
          workspaceList={workspaceList}
          subdivisionList={subdivisionList}
          selectedSubdivision={selectedSubdivision}
          setSelectedSubdivision={setSelectedSubdivision}
        />
      </div>

      <CreateWorkspaceDrawer
        open={openCreateWorkspaceDrawer}
        mode={createWorkspaceDrawerMode}
        loading={workspaceLoading}
        workspace={selectedWorkspace}
        subdivisionList={subdivisionList}
        userList={userList}
        onOpenChange={setOpenCreateWorkspaceDrawer}
        onModeChange={setCreateWorkspaceDrawerMode}
      />

      <WarningDrawer
        open={openDeleteWorkspaceDrawer}
        content="Вы уверены, что хотите удалить карту оснащения?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={workspaceLoading}
        onClose={() => {
          setOpenDeleteWorkspaceDrawer(false);
          setSelectedWorkspace(null);
        }}
        onConfirm={onDeleteWorkspaceConfirm}
      />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  workspaceList: state.workspaceListState.data,
  workspaceListLoading: state.workspaceListState.loading,
  subdivisionList: state.subdivisionListState.data,
  userList: state.userListState.data,
  auth: state.authState.data,
  workspaceLoading: state.workspaceState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getWorkspaceList: dispatch.workspaceListState.getWorkspaceList,
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  getUserList: dispatch.userListState.getUserList,
  deleteWorkspace: dispatch.workspaceState.deleteWorkspace,
});

export const WorkspaceList = connect(mapState, mapDispatch)(Component);
