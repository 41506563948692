import React, { FC } from 'react';
import { Button, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ERoute } from 'common/const/route.enum';
import { ReactComponent as DocumentIcon } from 'app/assets/images/redesign/document.svg';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as InfoRedIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { IWorkspace } from 'entities/Workspace/Workspace.models';
import { getUserName } from 'entities/User/User.helper';

interface IComponentProps {
  group: { subdivisionId: number; subdivisionName: string; dataSource: IWorkspace[] };
  onCopyWorkspaceClick: (value: IWorkspace) => void;
  onEditWorkspaceClick: (value: IWorkspace) => void;
  onDeleteWorkspaceClick: (value: IWorkspace) => void;
}

export const WorkspaceListGroup: FC<IComponentProps> = (props) => {
  const { group, onCopyWorkspaceClick, onEditWorkspaceClick, onDeleteWorkspaceClick } = props;
  const navigate = useNavigate();

  const { subdivisionName, dataSource } = group;

  return (
    <div className="redesign workspace-list-group">
      <div className="workspace-list-group__name-container">
        <span className="text-h4-item-name workspace-list-group__name">{subdivisionName}</span>
      </div>

      {dataSource.map((workspace) => {
        const userName = getUserName(workspace.user?.firstName, workspace.user?.lastName);

        return (
          <div
            key={workspace.id}
            className="workspace-list-group__item"
            onClick={() => navigate(`${ERoute.WorkspaceList}/${workspace.id}`)}
          >
            <div className="workspace-list-group__item-container">
              <div className="workspace-list-group__item-icon">
                <DocumentIcon className="icon-document-bright-green" />
              </div>

              <span style={{ flex: 1 }} className="text-h4-item-name">
                {workspace.name}
              </span>

              <Dropdown
                overlayClassName="redesign"
                menu={{
                  items: [
                    {
                      key: 'copy',
                      label: 'Дублировать карту',
                      onClick: ({ domEvent }) => {
                        domEvent.stopPropagation();
                        onCopyWorkspaceClick(workspace);
                      },
                    },
                    { type: 'divider' },
                    {
                      key: 'edit',
                      label: 'Редактировать карту',
                      onClick: ({ domEvent }) => {
                        domEvent.stopPropagation();
                        onEditWorkspaceClick(workspace);
                      },
                    },
                    { type: 'divider' },
                    {
                      key: 'delete',
                      label: 'Удалить карту',
                      onClick: ({ domEvent }) => {
                        domEvent.stopPropagation();
                        onDeleteWorkspaceClick(workspace);
                      },
                      className: 'red',
                    },
                  ],
                }}
                placement="bottomRight"
              >
                <Button
                  className="button-icon"
                  icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
                />
              </Dropdown>
            </div>

            <div className="workspace-list-group__item-user">
              <UserIcon className="icon-user-dark-grey" />

              <span className="text-tag-accent color-dark-grey">{userName.length ? userName : 'Сотрудник не назначен'}</span>

              {!userName.length && <InfoRedIcon className="icon-info-red" />}
            </div>
          </div>
        );
      })}
    </div>
  );
};
