import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { EDeliveryStatus } from 'common/const/delivery.enum';
import { calculateDeliveryTime, getDeliveryBadgeTooltipTitle, getDeliveryStatus } from 'common/helpers/delivery.helper';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ReactComponent as InfoRedIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';
import { ReactComponent as InfoGreenIcon } from 'app/assets/images/redesign/info-green.svg';
import { IGoodsRemain } from 'entities/Goods/Goods.models';

interface IComponentProps {
  remains: IGoodsRemain[];
  count: number;
}

export const DeliveryBadge: FC<IComponentProps> = ({ remains, count }) => {
  const deliveryTime = calculateDeliveryTime(remains, count);
  const deliveryStatus = getDeliveryStatus(deliveryTime);

  return (
    <div className={`delivery-badge ${deliveryStatus}`}>
      <DeliveryIcon className={`icon-delivery-${deliveryStatus}`} />

      <div className="text-tag-accent">{deliveryTime}</div>

      <Tooltip overlayClassName="redesign delivery-badge__tooltip" title={getDeliveryBadgeTooltipTitle(remains)}>
        {deliveryStatus === EDeliveryStatus.InStock && <InfoGreenIcon className="icon-info-green" />}
        {deliveryStatus === EDeliveryStatus.AvailableOnRequest && <InfoYellowIcon className="icon-info-yellow" />}
        {deliveryStatus === EDeliveryStatus.OutOfStock && <InfoRedIcon className="icon-info-red" />}
      </Tooltip>
    </div>
  );
};
