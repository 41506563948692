import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { workspacesTransport } from 'entities/Workspaces/Workspaces.transport';
import { IWorkspace, IWorkspaceGrouped, IWorkspaceGroupedState } from 'entities/Workspaces/Workspaces.models';

export const workspaceGroupedState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IWorkspaceGroupedState,
  reducers: {
    setWorkspaceGrouped: (state, payload: IWorkspaceGrouped) => ({ ...state, data: payload }),
    setWorkspaceGroupedLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getWorkspaceGrouped(id: number) {
      dispatch.workspaceGroupedState.setWorkspaceGroupedLoading(true);

      try {
        const response = await workspacesTransport.getWorkspaceGrouped(id);
        dispatch.workspaceGroupedState.setWorkspaceGrouped(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.workspaceGroupedState.setWorkspaceGroupedLoading(false);
      }
    },
    updateWorkspaceGrouped(payload: IWorkspace, models) {
      const workspaceGroupedData = models.workspaceGroupedState.data;

      if (workspaceGroupedData) {
        workspaceGroupedData.workspace = payload;
        dispatch.workspaceGroupedState.setWorkspaceGrouped(workspaceGroupedData);
      }
    },
  }),
});
