import React, { FC, useState } from 'react';
import { Button, InputNumber } from 'antd';
import { DEFAULT_COUNTER_MAX_VALUE, DEFAULT_GOODS_COUNT } from 'common/config';
import { getCounterWidth } from 'common/helpers/counter.helper';
import { isInteger } from 'common/helpers/common.helper';
import { ReactComponent as MinusIcon } from 'app/assets/images/redesign/minus.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';

interface IComponentProps {
  count: number;
  responsive?: boolean;
  onChange: (value: number) => void;
}

export const Counter: FC<IComponentProps> = ({ count, responsive, onChange }) => {
  const [focused, setFocused] = useState<boolean>(false);

  const toggleFocused = () => setFocused((prev) => !prev);

  const onDecrement = () => {
    if (count > DEFAULT_GOODS_COUNT) {
      onChange(count - 1);
    }
  };

  const handleChange = (value: number | null) => {
    if (value && isInteger(value)) {
      onChange(value);
    }
  };

  const onIncrement = () => {
    if (count < DEFAULT_COUNTER_MAX_VALUE) {
      onChange(count + 1);
    }
  };

  return (
    <InputNumber
      className={`counter ${focused ? 'counter__focused' : ''}`}
      value={count}
      onChange={handleChange}
      controls={false}
      min={DEFAULT_GOODS_COUNT}
      max={DEFAULT_COUNTER_MAX_VALUE}
      addonBefore={
        <Button
          className="counter__btn"
          icon={<MinusIcon className="icon-minus" />}
          onClick={onDecrement}
          disabled={count <= DEFAULT_GOODS_COUNT}
        />
      }
      addonAfter={
        <Button
          className="counter__btn"
          icon={<PlusIcon className="icon-plus" />}
          onClick={onIncrement}
          disabled={count >= DEFAULT_COUNTER_MAX_VALUE}
        />
      }
      onFocus={toggleFocused}
      onBlur={toggleFocused}
      style={{ width: getCounterWidth(count, responsive) }}
    />
  );
};
