import { EPresetType } from 'common/const/preset.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { IFormValues } from 'common/models';
import { ICategoryDetailed } from 'entities/Categories/Categories.models';
import { IProperty } from 'entities/Property/Property.models';

export const searchParamsToFormValues = (searchParams: URLSearchParams) => {
  const formValues: IFormValues = {};
  const defaultActiveKeys: number[] = [];

  searchParams.forEach((value, key) => {
    switch (key) {
      case ESearchParams.CatalogId:
      case ESearchParams.CategoryId: {
        formValues[key] = Number(value);
        break;
      }
      case ESearchParams.Search:
      case ESearchParams.IsAvailableForPurchase: {
        formValues[key] = value;
        break;
      }
      default: {
        // Example key = 1[from], properyId = 1
        const propertyId = Number(key.split('[')[0]);
        // Example key = 1[from], propertySubindex = from
        const propertySubindex = key.match(/\[(.*?)\]/)?.[1];

        defaultActiveKeys.push(propertyId);

        if (!propertySubindex) {
          // Value is a string
          formValues[propertyId] = value;
        } else {
          const propertySubindexNumber = parseInt(propertySubindex);

          if (!isNaN(propertySubindexNumber)) {
            // Value is an array element
            const propertyFormValue = formValues[propertyId] || [];
            formValues[propertyId] = [...propertyFormValue, value];
          } else {
            // Value is the value of the object's property
            const propertyFormValue = formValues[propertyId] || [];
            formValues[propertyId] = [{ ...propertyFormValue[0], [propertySubindex]: Number(value) }];
          }
        }
        break;
      }
    }
  });

  return { formValues, defaultActiveKeys };
};

export const formValuesToSearchParams = (formValues: IFormValues) => {
  const params: string[] = [];
  let error = false;

  Object.entries(formValues).forEach(([key, value]) => {
    if (value) {
      switch (true) {
        case typeof value === 'string':
        case typeof value === 'number':
        case typeof value === 'boolean': {
          params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
          break;
        }
        case Array.isArray(value): {
          if (typeof value[0] === 'object') {
            const keyFrom = `${key}[from]`;
            const keyTo = `${key}[to]`;
            const valueFrom = value[0].from;
            const valueTo = value[0].to;

            if (valueFrom && valueTo && valueFrom > valueTo) {
              error = true;
              break;
            } else {
              error = false;
            }

            valueFrom && params.push(`${encodeURIComponent(keyFrom)}=${encodeURIComponent(valueFrom)}`);
            valueTo && params.push(`${encodeURIComponent(keyTo)}=${encodeURIComponent(valueTo)}`);
          } else {
            value.forEach((valueItem: string, index: number) => {
              const arrayKey = `${key}[${index}]`;

              params.push(`${encodeURIComponent(arrayKey)}=${encodeURIComponent(valueItem)}`);
            });
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  return { params: params.join('&'), error };
};

export const searchParamsToInitialFormValues = (
  searchParams: URLSearchParams,
  category: ICategoryDetailed | null,
  propertyList: IProperty[] | null,
) => {
  const formValues: IFormValues = {};
  const defaultActiveKeys: number[] = [];

  if (category) {
    category.positions.forEach(({ propertyId, values, range }) => {
      const property = propertyList?.find((propertyItem) => propertyItem.id === propertyId);

      if (property && property.presetType !== EPresetType.NotApplicable) {
        formValues[propertyId] = values ? values : range;
        defaultActiveKeys.push(propertyId);
      }
    });
  }

  const formValuesFromSearchParams = searchParamsToFormValues(searchParams);

  return {
    formValues: { ...formValues, ...formValuesFromSearchParams.formValues },
    defaultActiveKeys: [...defaultActiveKeys, ...formValuesFromSearchParams.defaultActiveKeys],
  };
};
