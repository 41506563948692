import { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { IFormValues, IPositionTag } from 'common/models';
import {
  getPositionFormPropertyList,
  getPositionFormValues,
  getPositionFormPropertyListForView,
  setNewPositionFormPropertyValue,
  positionFormPropertyListToTagList,
  clearAllPositionFormConditionalProperties,
} from 'common/helpers/position.helper';
import { EPresetType } from 'common/const/preset.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { ICategory } from 'entities/Categories/Categories.models';
import { IProperty } from 'entities/Property/Property.models';
import { IWorkspacePosition } from 'entities/Workspaces/Workspaces.models';

export const usePropertyForm = (propertyList: IProperty[], category?: ICategory | null, position?: IWorkspacePosition) => {
  const [positionFormPropertyList, setPositionFormPropertyList] = useState<IProperty[]>([]);
  const [propertyListForView, setPropertyListForView] = useState<IProperty[]>([]);
  const [tagList, setTagList] = useState<IPositionTag[]>([]);
  const { searchParams } = useSearchParamsHook();
  const [form] = useForm();

  const changeFormValues = (formValue: IFormValues) => {
    const positionFormPropertyListWithNewValues = setNewPositionFormPropertyValue(positionFormPropertyList, formValue);
    const propertyListForView = getPositionFormPropertyListForView(positionFormPropertyListWithNewValues);
    const tagList = positionFormPropertyListToTagList(propertyListForView);
    const formValues = getPositionFormValues(propertyListForView);

    setPositionFormPropertyList(positionFormPropertyListWithNewValues);
    setPropertyListForView(propertyListForView);
    setTagList(tagList);
    form.setFieldsValue(formValues);
  };

  const closeTag = (id: number) => {
    const property = propertyListForView.find((propertyItem) => propertyItem.id === id);

    if (property) {
      if (property.presetType === EPresetType.SingleValue) {
        changeFormValues({ [id]: '' });
        return;
      }

      if (property.presetType === EPresetType.Range) {
        changeFormValues({ [id]: [{ from: null, to: null }] });
        return;
      }

      changeFormValues({ [id]: [] });
    }
  };

  const closeAllTags = () => {
    const positionFormPropertyListWithNewValues = clearAllPositionFormConditionalProperties(positionFormPropertyList);
    const propertyListForView = getPositionFormPropertyListForView(positionFormPropertyListWithNewValues);
    const tagList = positionFormPropertyListToTagList(propertyListForView);
    const formValues = getPositionFormValues(propertyListForView);

    setPositionFormPropertyList(positionFormPropertyListWithNewValues);
    setPropertyListForView(propertyListForView);
    setTagList(tagList);
    form.setFieldsValue(formValues);
  };

  useEffect(() => {
    const positionFormPropertyList = getPositionFormPropertyList(searchParams, propertyList, category, position);
    const propertyListForView = getPositionFormPropertyListForView(positionFormPropertyList);
    const tagList = positionFormPropertyListToTagList(propertyListForView);
    const formValues = getPositionFormValues(propertyListForView);

    setPositionFormPropertyList(positionFormPropertyList);
    setPropertyListForView(propertyListForView);
    setTagList(tagList);
    form.setFieldsValue(formValues);
  }, [propertyList, category, position]);

  return { form, propertyListForView, tagList, changeFormValues, closeTag, closeAllTags };
};
