import React from 'react';
import { Table } from 'antd';
import { EMessage } from 'common/const/message.enum';
import { EDeliveryStatus, EDeliveryStatusLabel } from 'common/const/delivery.enum';
import { countFormatter, deliveryTimeFormatter } from 'common/helpers/formatter.helper';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { IGoodsRemain } from 'entities/Goods/Goods.models';

export const calculateDeliveryTime = (remains: IGoodsRemain[], count: number): string => {
  if (!remains || remains.length === 0) {
    return EDeliveryStatusLabel.out_of_stock;
  }

  let totalStockCount = 0;
  let maxDeliveryInDays = 0;

  const validRemains = remains.filter((stock) => stock.count !== undefined && stock.deliveryInDays !== undefined) as {
    count: number;
    deliveryInDays: number;
  }[];

  if (validRemains.length === 0) {
    return EDeliveryStatusLabel.available_on_request;
  }

  const sortedRemains = validRemains.sort((a, b) => a.deliveryInDays - b.deliveryInDays);

  for (let i = 0; i < sortedRemains.length; i++) {
    totalStockCount += sortedRemains[i].count;
    maxDeliveryInDays = Math.max(maxDeliveryInDays, sortedRemains[i].deliveryInDays);

    if (totalStockCount >= count) {
      return deliveryTimeFormatter.full(maxDeliveryInDays);
    }
  }

  return EDeliveryStatusLabel.available_on_request;
};

export const getDeliveryStatus = (deliveryTime: string) => {
  if (deliveryTime === EDeliveryStatusLabel.out_of_stock) {
    return EDeliveryStatus.OutOfStock;
  }

  if (deliveryTime === EDeliveryStatusLabel.available_on_request) {
    return EDeliveryStatus.AvailableOnRequest;
  }

  return EDeliveryStatus.InStock;
};

export const getDeliveryBadgeTooltipTitle = (remains: IGoodsRemain[]) => {
  return !remains.length ? (
    <div className="delivery-badge__table-empty text-tag">{EMessage.DeliveryBadgeTooltip}</div>
  ) : (
    <Table
      className="delivery-badge__table"
      dataSource={remains.map((item, index) => ({ key: index, ...item }))}
      columns={[
        {
          key: 'stockName',
          dataIndex: 'stockName',
          render: (_, { stockName }) => {
            return <div className="delivery-badge__table-item-stock-name">{stockName}</div>;
          },
        },
        {
          key: 'count',
          dataIndex: 'count',
          render: (_, { count }) => (count ? countFormatter(count) : null),
        },
        {
          key: 'deliveryInDays',
          dataIndex: 'deliveryInDays',
          render: (_, { deliveryInDays }) => {
            return (
              <div className="delivery-badge__table-item-container">
                <DeliveryIcon className="icon-delivery-dark-grey" />

                <span>{deliveryTimeFormatter.short(deliveryInDays)}</span>
              </div>
            );
          },
        },
      ]}
      size="small"
      pagination={false}
      showHeader={false}
    />
  );
};
