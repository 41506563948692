import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Input, Select } from 'antd';
import { connect } from 'react-redux';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { ESupplyCorrectionLabel, ESupplyCorrectionValue } from 'common/const/supply.enum';
import { DRAWER_Z_INDEX_3 } from 'common/config';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { RootDispatch, RootState } from 'app/store';

interface IComponentProps {
  onChangeStatusSuccess?: (ids: number[]) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SupplyCorrectionRequestDrawerComponent: FC<AllType> = (props) => {
  const {
    // props
    onChangeStatusSuccess,
    // state
    supply,
    supplyLoading,
    need,
    // dispatch
    sendRequestSupplyCorrection,
    updateNeedList,
    setNeed,
  } = props;

  const [reason, setReason] = useState<ESupplyCorrectionValue | undefined>();
  const [comment, setComment] = useState<string>('');
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState<boolean>(false);
  const { openSupplyCorrectionRequestDrawer, setOpenSupplyCard, setOpenSupplyCorrectionRequestDrawer } = useSupplyContext();

  const onClose = () => {
    setOpenSupplyCorrectionRequestDrawer(false);
    setOpenSupplyCard(true);
  };

  const onSendRequestClick = () => {
    if (supply && reason) {
      sendRequestSupplyCorrection({
        supplyId: supply.id,
        reason,
        comment,
        onSuccess: () => {
          setOpenSupplyCorrectionRequestDrawer(false);
          setOpenSupplyCard(true);
          onChangeStatusSuccess?.([supply.id]);

          if (need) {
            const newNeed = { ...need, pendingSuppliesCount: need.pendingSuppliesCount - supply.count };

            updateNeedList([newNeed]);
            setNeed(newNeed);
          }
        },
      });
    }
  };

  useEffect(() => {
    if (!openSupplyCorrectionRequestDrawer) {
      setReason(undefined);
      setComment('');
    }
  }, [openSupplyCorrectionRequestDrawer]);

  return (
    <>
      <Drawer
        rootClassName="redesign drawer supply-correction-request-drawer"
        open={openSupplyCorrectionRequestDrawer}
        onClose={supplyLoading ? undefined : onClose}
        width={800}
        closeIcon={<ArrowLeftLongIcon />}
        destroyOnClose
        zIndex={DRAWER_Z_INDEX_3}
      >
        <div className="drawer__body">
          <div className="drawer__title">{`Запрос корректировки для поставки #${supply?.id}`}</div>

          <div className="supply-correction-request-drawer__block">
            <span className="text-tag supply-correction-request-drawer__block-label">Причина</span>

            <Select
              popupClassName="redesign"
              options={[
                {
                  label: ESupplyCorrectionLabel.price,
                  value: ESupplyCorrectionValue.Price,
                },
                {
                  label: ESupplyCorrectionLabel.date,
                  value: ESupplyCorrectionValue.Date,
                },
              ]}
              value={reason}
              onChange={setReason}
              placeholder="Выберите причину корректировки"
              suffixIcon={<ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />}
            />
          </div>

          <div className="mb-132 supply-correction-request-drawer__block">
            <span className="text-tag supply-correction-request-drawer__block-label">Комментарий</span>

            <Input.TextArea
              rows={4}
              className="supply-correction-request-drawer__comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Укажите, что требуется скорректировать"
            />
          </div>
        </div>

        <div className="drawer__footer">
          <Button className="button-l primary" onClick={onSendRequestClick} loading={supplyLoading} disabled={!reason}>
            Отправить запрос
          </Button>

          <Button className="button-l secondary" onClick={onClose} loading={supplyLoading}>
            Отмена
          </Button>
        </div>
      </Drawer>

      <SuccessDrawer
        open={openSuccessDrawer}
        subtitle="Готово!"
        content="Запрос корректировки направлен поставщику"
        btnTitle="Назад к заявке"
        onClose={() => {
          setOpenSuccessDrawer(false);
          setOpenSupplyCard(true);
        }}
        onConfirm={() => {
          setOpenSuccessDrawer(false);
          setOpenSupplyCard(true);
        }}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  supply: state.supplyState.data,
  supplyLoading: state.supplyState.loading,
  need: state.needState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  sendRequestSupplyCorrection: dispatch.supplyState.sendRequestSupplyCorrection,
  updateNeedList: dispatch.needListState.updateNeedList,
  setNeed: dispatch.needState.setNeed,
});

export const SupplyCorrectionRequestDrawer = connect(mapState, mapDispatch)(SupplyCorrectionRequestDrawerComponent);
