import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Checkbox, Spin, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { groupList } from 'common/helpers/common.helper';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { EGroupedSupplyListKey, ESupplyStatus } from 'common/const/supply.enum';
import { LIST_LIMIT_5, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_20 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyListEmpty } from 'entities/Supply/components/SupplyListEmpty';
import { EditSupplyCard } from 'entities/Supply/components/EditSupplyCard';
import { IGroupedSupplyListPayload, ISupply } from 'entities/Supply/Supply.models';
import { renderGroupedSupplyListRecords, renderSupplyListRecords } from 'entities/Supply/Supply.helper';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  renderFilters: (value: IGroupedSupplyListPayload, setPayload: (value: IGroupedSupplyListPayload) => void) => ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    renderFilters,
    // state
    supplyListLoading,
    groupedSupplyList,
    groupedSupplyListCount,
    groupedSupplyListLoading,
    // dispatch
    getBuyerList,
    setGroupedSupplyList,
    filterGroupedSupplyList,
    getGroupedSupplyList,
    getGroupedSupplyListPart,
    changeGroupedSupplyListStatus,
    getSupplyById,
    getNeedById,
  } = props;

  const [payload, setPayload] = useState<IGroupedSupplyListPayload>({
    statuses: [ESupplyStatus.Created],
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
    key: EGroupedSupplyListKey.ByUpdatedAt,
  });
  const { page, setPage, setOpenSupplyCard, fetchLoading, setFetchLoading, selectedSupplyList, setSelectedSupplyList } =
    useSupplyContext();

  const selectedSupplyListIds = selectedSupplyList.map((need) => need.id);
  const supplyList = groupedSupplyList.flatMap((item) => item.supplies);
  const checkAll = supplyList.length === selectedSupplyList.length;
  const indeterminate = selectedSupplyList.length > 0 && selectedSupplyList.length < supplyList.length;
  const groupedList = groupList.byAddressLegalUser(groupedSupplyList);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (indeterminate) {
      setSelectedSupplyList([]);
    } else {
      setSelectedSupplyList(e.target.checked ? supplyList.filter(({ address }) => !!address) : []);
    }
  };

  const onSupplySelectionChange = (supply: ISupply, checked: boolean) => {
    const newSelectedSupplyList = checked
      ? [...selectedSupplyList, supply]
      : selectedSupplyList.filter((supplyItem) => supplyItem.id !== supply.id);

    setSelectedSupplyList(newSelectedSupplyList);
  };

  const onGroupSelectionChange = (checked: boolean, groupSupplyList: ISupply[]) => {
    const groupSupplyListIds = groupSupplyList.map((supply: ISupply) => supply.id);
    const selectedGroupSupplyList = groupSupplyList.filter((supply: ISupply) => selectedSupplyListIds.includes(supply.id));
    const indeterminate = selectedGroupSupplyList.length > 0 && selectedGroupSupplyList.length < groupSupplyList.length;

    if (checked) {
      setSelectedSupplyList([...selectedSupplyList, ...groupSupplyList]);
    }

    if (!checked) {
      setSelectedSupplyList(selectedSupplyList.filter((supply) => !groupSupplyListIds.includes(supply.id)));
    }

    if (indeterminate) {
      setSelectedSupplyList([
        ...selectedSupplyList,
        ...groupSupplyList.filter((supply: ISupply) => !selectedGroupSupplyList.includes(supply)),
      ]);
    }
  };

  const onChangeStatusSuccess = (ids: number[]) => {
    const filteredGroupedSupplyList = groupedSupplyList
      .map((group) => {
        return {
          ...group,
          supplies: group.supplies.filter((supply) => !ids.includes(supply.id)),
        };
      })
      .filter((group) => !!group.supplies.length);
    const filteredSelectedSupplyList = selectedSupplyList.filter((supply) => !ids.includes(supply.id));

    filterGroupedSupplyList(ids);
    setSelectedSupplyList(filteredSelectedSupplyList);

    if (filteredGroupedSupplyList.length <= LIST_LIMIT_5 && groupedSupplyListCount > filteredGroupedSupplyList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getGroupedSupplyList(newPayload);
    }
  };

  const onSupplyStatusChange = () => {
    changeGroupedSupplyListStatus({
      ids: selectedSupplyListIds,
      status: ESupplyStatus.Processing,
      onSuccess: () => onChangeStatusSuccess(selectedSupplyListIds),
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedSupplyList([]);
      setGroupedSupplyList([]);
      await getBuyerList();
      await getGroupedSupplyList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!groupedSupplyListLoading && groupedSupplyList.length < groupedSupplyListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getGroupedSupplyListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}
        {renderFilters(payload, setPayload)}

        {!!groupedSupplyList.length && (
          <div className="need-list__actions">
            <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
              Выбрать поставки
            </Checkbox>

            {!!selectedSupplyList.length && (
              <Button className="button-s primary" onClick={onSupplyStatusChange}>
                Передать в учётную систему
              </Button>
            )}
          </div>
        )}

        <Spin
          wrapperClassName="need-list__spin"
          spinning={groupedSupplyListLoading || supplyListLoading || fetchLoading}
          indicator={<SpinIndicator />}
        >
          {groupedList.map((group, index) => {
            return (
              <Table
                key={index}
                className="need-list__table need-list__table-expandable"
                rowClassName="need-list__table-expandable-row"
                columns={renderGroupedSupplyListRecords(selectedSupplyListIds, onGroupSelectionChange)}
                dataSource={group.dataSource.map((item, index) => ({ ...item, key: index }))}
                expandable={{
                  expandIconColumnIndex: 7,
                  columnWidth: 40,
                  expandIcon: ({ expanded, onExpand, record }) => {
                    return (
                      <Button
                        className="button-icon"
                        icon={
                          expanded ? (
                            <ArrowUpShortIcon className="icon-arrow-up-short-dark-grey" />
                          ) : (
                            <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
                          )
                        }
                        onClick={(e) => onExpand(record, e)}
                      />
                    );
                  },
                  expandedRowRender: ({ dataSource }) => {
                    return (
                      <Table
                        className="table-hover need-list__table"
                        columns={renderSupplyListRecords()}
                        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
                        pagination={false}
                        showHeader={false}
                        rowSelection={{
                          selectedRowKeys: selectedSupplyListIds,
                          onSelect: (record, checked) => onSupplySelectionChange(record, checked),
                          columnWidth: 40,
                          getCheckboxProps: ({ address }) => ({ disabled: !address }),
                        }}
                        onRow={({ id, supplyNeedId }) => ({
                          onClick: async () => {
                            await getSupplyById({ id, onSuccess: () => setOpenSupplyCard(true) });
                            await getNeedById({ id: supplyNeedId });
                          },
                        })}
                      />
                    );
                  },
                }}
                pagination={false}
                showHeader={false}
              />
            );
          })}

          <SupplyListEmpty
            open={!(groupedSupplyListLoading || supplyListLoading || fetchLoading) && !groupedSupplyList.length}
            forSeller
          />
        </Spin>
      </InfiniteScrollContainer>

      <SupplyCard onChangeStatusSuccess={onChangeStatusSuccess} />

      <NeedCard nested />

      <EditSupplyCard />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  supplyListLoading: state.supplyListState.loading,
  groupedSupplyList: state.groupedSupplyListState.data,
  groupedSupplyListCount: state.groupedSupplyListState.count,
  groupedSupplyListLoading: state.groupedSupplyListState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBuyerList: dispatch.buyerListState.getBuyerList,
  setGroupedSupplyList: dispatch.groupedSupplyListState.setGroupedSupplyList,
  filterGroupedSupplyList: dispatch.groupedSupplyListState.filterGroupedSupplyList,
  getGroupedSupplyList: dispatch.groupedSupplyListState.getGroupedSupplyList,
  getGroupedSupplyListPart: dispatch.groupedSupplyListState.getGroupedSupplyListPart,
  changeGroupedSupplyListStatus: dispatch.groupedSupplyListState.changeGroupedSupplyListStatus,
  getSupplyById: dispatch.supplyState.getSupplyById,
  getNeedById: dispatch.needState.getNeedById,
});

export const CreatedSupplyListForSeller = connect(mapState, mapDispatch)(Component);
