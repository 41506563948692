import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Image, Input, Select, Switch, Upload } from 'antd';
import { DataNode } from 'antd/es/tree';
import { useForm } from 'antd/es/form/Form';
import { debounce } from 'common/helpers/common.helper';
import { IFormValues } from 'common/models';
import { formValuesToPositions } from 'common/helpers/form.helper';
import { ALL_DOCS_EXTS, UPLOADING_DOCS_COUNT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ICatalog, ICatalogUpdatePayload } from 'entities/Catalog/Catalog.models';
import { ICategoryDetailed } from 'entities/Categories/Categories.models';
import { IProperty } from 'entities/Property/Property.models';
import { CategoryTemplateSearchModal } from 'entities/Modal/components/CategoryTemplateSearchModal';
import {
  getCategoryTemplate,
  getGroupingPropertyOptions,
  getPropertyListForCategoryFilter,
  categoryPositionsToCategoryFilterFormValues,
  getCategoryFilterDescription,
  findCategoryTemplateConditionProperties,
  generateCategoryPositions,
} from 'entities/Categories/Categories.helper';
import { CategoryFilterModal } from 'entities/Modal/components/CategoryFilterModal';

interface IComponentProps {
  catalog: ICatalog | null;
  category: ICategoryDetailed | null;
  loading: boolean;
  categoryTemplateTreeData: DataNode[];
  propertyList: IProperty[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CatalogSettingsFormComponent: FC<AllType> = (props) => {
  const {
    catalog,
    category,
    loading,
    categoryTemplateTreeData,
    propertyList,
    filesState,
    updateCatalog,
    updateCategory,
    uploadFiles,
  } = props;

  const { loading: uploadLoading } = filesState;

  const [currentCategoryId, setCurrentCategoryId] = useState<number | null>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | undefined>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [groupingPropertyId, setGroupingPropertyId] = useState<number | null>(null);
  const [propertiesToDisplayIds, setPropertiesToDisplayIds] = useState<number[]>([]);
  const [openCategoryTemplateSearchModal, setOpenCategoryTemplateSearchModal] = useState<boolean>(false);
  const [openCategoryFilterModal, setOpenCategoryFilterModal] = useState<boolean>(false);
  const [catalogForm] = useForm();
  const [categoryFilterForm] = useForm();

  const template = useMemo(() => getCategoryTemplate(category, propertyList), [category]);
  const conditionPropertyList = useMemo(() => {
    return findCategoryTemplateConditionProperties(propertyList, template?.id);
  }, [template?.id]);
  const propertyListForFilter = useMemo(() => {
    return getPropertyListForCategoryFilter(conditionPropertyList, propertyList, template);
  }, [conditionPropertyList, template]);
  const groupingPropertyOptions = getGroupingPropertyOptions(conditionPropertyList, propertiesToDisplayIds);
  const categoryFilterDescription = getCategoryFilterDescription(category?.positions, propertyList);
  const categoryPositionsIds = category?.positions.map((position) => position.propertyId);

  const debouncedUpdateCatalog = debounce((payload: ICatalogUpdatePayload) => {
    updateCatalog(payload);
  });

  const onCatalogFormValuesChange = (value: IFormValues) => {
    const { name } = value;

    if (currentCategoryId) {
      debouncedUpdateCatalog({ id: currentCategoryId, name });
    }
  };

  const toggleOpenCategoryTemplateSearchModal = () => setOpenCategoryTemplateSearchModal((prev) => !prev);

  const onCategoryTemplateSearchModalCancelClick = () => {
    setSearchValue('');
    setSelectedTemplateId(template?.id);
    toggleOpenCategoryTemplateSearchModal();
  };

  const onCategoryTemplateSearchModalSelectClick = () => {
    if (selectedTemplateId) {
      const selectedTemplate = propertyList.find((property) => property.id === selectedTemplateId);

      if (currentCategoryId && selectedTemplate) {
        const positions = generateCategoryPositions(propertyList, selectedTemplate);
        const conditionProperties = findCategoryTemplateConditionProperties(propertyList, selectedTemplateId);
        const propertiesToDisplayIds = conditionProperties.map((property) => property.id);

        updateCategory({
          id: currentCategoryId,
          positions,
          groupingPropertyId: null,
          propertiesToDisplay: propertiesToDisplayIds,
          onSuccess: () => {
            setPropertiesToDisplayIds(propertiesToDisplayIds);
            onCategoryTemplateSearchModalCancelClick();
          },
        });
      }
    }
  };

  const toggleOpenCategoryFilterModal = () => setOpenCategoryFilterModal((prev) => !prev);

  const onCategoryFilterModalCancelClick = () => {
    toggleOpenCategoryFilterModal();
  };

  const onCategoryFilterModalApplyClick = (values: IFormValues) => {
    const positions = formValuesToPositions(values);

    if (currentCategoryId) {
      updateCategory({ id: currentCategoryId, positions, onSuccess: onCategoryFilterModalCancelClick });
    }
  };

  const customRequest = async ({ file }: any) => {
    const response = await uploadFiles(file);

    if (category && response) {
      await updateCategory({ id: category.id, image: response.url });
    }
  };

  const onGroupingPropertyIdChange = (value: number) => {
    setGroupingPropertyId(value);

    if (currentCategoryId) {
      updateCategory({ id: currentCategoryId, groupingPropertyId: value });
    }
  };

  const changePropertyToDisplay = (id: number, checked: boolean) => {
    const newPropertiesToDisplayIds = checked
      ? [...propertiesToDisplayIds, id]
      : propertiesToDisplayIds.filter((propertyId) => propertyId !== id);
    const newGroupingPropertyId =
      groupingPropertyId && newPropertiesToDisplayIds.includes(groupingPropertyId) ? groupingPropertyId : null;

    setGroupingPropertyId(newGroupingPropertyId);
    setPropertiesToDisplayIds(newPropertiesToDisplayIds);

    if (currentCategoryId) {
      updateCategory({
        id: currentCategoryId,
        groupingPropertyId: newGroupingPropertyId,
        propertiesToDisplay: newPropertiesToDisplayIds,
      });
    }
  };

  useEffect(() => {
    catalogForm.setFieldsValue({ name: catalog?.name });
  }, []);

  useEffect(() => {
    if (category && category.id !== currentCategoryId) {
      const { id, groupingPropertyId, propertiesToDisplay } = category;
      const propertiesToDisplayIds = propertiesToDisplay?.map((item) => item.propertyId);
      const categoryFormInitialValue = categoryPositionsToCategoryFilterFormValues(category.positions);

      setCurrentCategoryId(id);
      setGroupingPropertyId(groupingPropertyId ? groupingPropertyId : null);
      setPropertiesToDisplayIds(propertiesToDisplayIds || []);
      categoryFilterForm.setFieldsValue(categoryFormInitialValue);
    }
  }, [category, currentCategoryId]);

  useEffect(() => {
    setSelectedTemplateId(template?.id);
  }, [template]);

  if (!category) {
    return null;
  }

  return (
    <div className="catalog-settings-form">
      <Form form={catalogForm} onValuesChange={onCatalogFormValuesChange}>
        <Form.Item name="name">
          <Input placeholder="Введите название" />
        </Form.Item>
      </Form>

      <div>
        <div className="catalog-settings-form__form-item">
          <div className="catalog-settings-form__form-item-label">
            <span className="catalog-settings-form__form-item-title">Категория товара</span>

            <span className="catalog-settings-form__form-item-description">{template?.displayName}</span>
          </div>

          <Button className="btn btn-default" onClick={toggleOpenCategoryTemplateSearchModal}>
            Изменить
          </Button>
        </div>

        {template && (
          <>
            <div className="catalog-settings-form__form-item">
              <div className="catalog-settings-form__form-item-label">
                <span className="catalog-settings-form__form-item-title">Фильтры</span>

                <div className="catalog-settings-form__form-item-description">{categoryFilterDescription}</div>
              </div>

              <Button className="btn btn-default" onClick={toggleOpenCategoryFilterModal}>
                Изменить
              </Button>
            </div>

            <div className="catalog-settings-form__form-item">
              <span className="catalog-settings-form__form-item-title">Изображение</span>

              <Upload accept={ALL_DOCS_EXTS} maxCount={UPLOADING_DOCS_COUNT} showUploadList={false} customRequest={customRequest}>
                <Button loading={uploadLoading}>Загрузить</Button>
              </Upload>
            </div>

            {category.image && (
              <div className="catalog-settings-form__form-image">
                <Image src={category.image} preview={false} alt="Category image" />
              </div>
            )}

            <div className="catalog-settings-form__form-subtitle">Листинг товаров в магазине</div>

            <div className="catalog-settings-form__form-item">
              <span className="catalog-settings-form__form-item-title">Группирующий параметр</span>

              <Select
                options={groupingPropertyOptions}
                value={groupingPropertyId}
                onChange={onGroupingPropertyIdChange}
                placeholder="Выбрать значение"
                suffixIcon={<ChevronIcon />}
                popupMatchSelectWidth={false}
                placement="bottomRight"
              />
            </div>

            {conditionPropertyList.map((property) => {
              const checked = propertiesToDisplayIds?.includes(property.id);

              return (
                <div key={property.id} className="catalog-settings-form__form-item">
                  <span className="catalog-settings-form__form-item-title">{property.displayName}</span>

                  <div className="catalog-settings-form__form-item-container">
                    <span>Виден в магазине</span>

                    <Switch checked={checked} onChange={(value) => changePropertyToDisplay(property.id, value)} />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      <CategoryTemplateSearchModal
        open={openCategoryTemplateSearchModal}
        loading={loading}
        treeData={categoryTemplateTreeData}
        searchValue={searchValue}
        templateId={selectedTemplateId}
        changeSearchValue={setSearchValue}
        changeTemplateId={setSelectedTemplateId}
        onCancel={onCategoryTemplateSearchModalCancelClick}
        onSelect={onCategoryTemplateSearchModalSelectClick}
      />

      <CategoryFilterModal
        open={openCategoryFilterModal}
        form={categoryFilterForm}
        loading={loading}
        propertyList={propertyListForFilter}
        defaultActiveKeys={categoryPositionsIds}
        onCancel={onCategoryFilterModalCancelClick}
        onApply={onCategoryFilterModalApplyClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  filesState: state.filesState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateCatalog: dispatch.catalogState.updateCatalog,
  updateCategory: dispatch.categoryState.updateCategory,
  uploadFiles: dispatch.filesState.uploadFiles,
});

export const CatalogSettingsForm = connect(mapState, mapDispatch)(CatalogSettingsFormComponent);
