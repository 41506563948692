import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import { workspaceTransport } from 'entities/Workspace/Workspace.transport';
import {
  IWorkspaceListState,
  IWorkspaceListParams,
  IWorkspaceState,
  IWorkspace,
  IWorkspaceCreatePayload,
  IWorkspaceUpdatePayload,
  IWorkspaceCopyPayload,
  IWorkspaceDeletePayload,
  IGroupedWorkspaceState,
  IGroupedWorkspace,
} from 'entities/Workspace/Workspace.models';
import { updateWorkspaceListState } from 'entities/Workspace/Workspace.helper';

export const workspaceListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as IWorkspaceListState,
  reducers: {
    setWorkspaceList: updateWorkspaceListState.setWorkspaceList,
    setLoading: updateWorkspaceListState.setLoading,
    addWorkspace: updateWorkspaceListState.addWorkspace,
    updateWorkspace: updateWorkspaceListState.updateWorkspace,
    deleteWorkspace: updateWorkspaceListState.deleteWorkspace,
  },
  effects: (dispatch) => ({
    async getWorkspaceList(params: IWorkspaceListParams) {
      dispatch.workspaceListState.setLoading(true);

      try {
        const response = await workspaceTransport.getWorkspaceList(params);
        dispatch.workspaceListState.setWorkspaceList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.workspaceListState.setLoading(false);
      }
    },
  }),
});

export const workspaceState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as IWorkspaceState,
  reducers: {
    setWorkspace: (state, payload: IWorkspace | null) => ({ ...state, data: payload }),
    setLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setError: (state, payload: string | null) => ({ ...state, error: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getWorkspaceById(id: number) {
      dispatch.workspaceState.setLoading(true);

      try {
        const response = await workspaceTransport.getWorkspaceById(id);
        dispatch.workspaceState.setWorkspace(response);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.workspaceState.setLoading(false);
      }
    },
    async createWorkspace(payload: IWorkspaceCreatePayload) {
      dispatch.workspaceState.setLoading(true);

      try {
        const response = await workspaceTransport.createWorkspace(payload);
        dispatch.workspaceListState.addWorkspace(response);
        dispatch.userListState.updateUserWorkspace(response);
        dispatch.workspaceState.setWorkspace(response);
        dispatch.workspaceState.onSuccess(payload);
        return response;
      } catch (error) {
        axiosErrorHandler(error, dispatch.workspaceState.setError);
        return null;
      } finally {
        dispatch.workspaceState.setLoading(false);
      }
    },
    async updateWorkspace(payload: IWorkspaceUpdatePayload) {
      dispatch.workspaceState.setLoading(true);

      try {
        const response = await workspaceTransport.updateWorkspace(payload);
        dispatch.workspaceListState.updateWorkspace(response);
        dispatch.groupedWorkspaceState.updateWorkspace(response);
        dispatch.userListState.updateUserWorkspace(response);
        dispatch.workspaceState.setWorkspace(response);
        dispatch.workspaceState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.workspaceState.setError);
      } finally {
        dispatch.workspaceState.setLoading(false);
      }
    },
    async copyWorkspace(payload: IWorkspaceCopyPayload) {
      dispatch.workspaceState.setLoading(true);

      try {
        const response = await workspaceTransport.copyWorkspace(payload);
        dispatch.workspaceListState.addWorkspace(response);
        dispatch.userListState.updateUserWorkspace(response);
        dispatch.workspaceState.setWorkspace(response);
        dispatch.workspaceState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.workspaceState.setError);
      } finally {
        dispatch.workspaceState.setLoading(false);
      }
    },
    async deleteWorkspace(payload: IWorkspaceDeletePayload) {
      dispatch.workspaceState.setLoading(true);

      try {
        const response = await workspaceTransport.deleteWorkspace(payload);
        dispatch.workspaceListState.deleteWorkspace(response);
        dispatch.userListState.deleteUserWorkspace(response);
        dispatch.workspaceState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.workspaceState.setLoading(false);
      }
    },
  }),
});

export const groupedWorkspaceState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IGroupedWorkspaceState,
  reducers: {
    setGroupedWorkspace: (state, payload: IGroupedWorkspace) => ({ ...state, data: payload }),
    setLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    updateWorkspace: (state, payload: IWorkspace) => {
      return { ...state, data: state.data ? { ...state.data, workspace: payload } : null };
    },
  },
  effects: (dispatch) => ({
    async getGroupedWorkspaceById(id: number) {
      dispatch.groupedWorkspaceState.setLoading(true);

      try {
        const response = await workspaceTransport.getGroupedWorkspaceById(id);
        dispatch.groupedWorkspaceState.setGroupedWorkspace(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.groupedWorkspaceState.setLoading(false);
      }
    },
  }),
});
